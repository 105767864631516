(function () {

    'use strict';
    const STYLE_OPERATORS = require('shared/json/style-operators.json');
    const colorPickerParams = require('parameters/json/color-picker-config.json');
    const handleCustomStyle = require('../utils/handle-custom-style');

    class TableStyleRulesCtrl {
        constructor($scope, tableService, $translate,dragulaService) {
            this.tableService = tableService;
            this.$translate = $translate;
            this.bag = "item-bag";

        }

        static get $inject() {
            return ["$scope", "tableService", "$translate","dragulaService"];
        }

        $onInit() {
            this.promise = null;
            this.operators = [];
            this.initRule();
        }

        initRule() {
            this.rule = {type: 'COLUMN', condition: null, target_value: null, target_column: null, style: {}};
            this.draftRule = _.cloneDeep(this.rule);
            this.draftStyle = _.cloneDeep(this.rule.style);
        }

        handleColumnChange(data, ev) {
            this.operators = _.chain(STYLE_OPERATORS).find({
                'type': _.chain(this.columns).find({'order_by': data}).get('type').value()
            }).get('operators').value();
        }

        handleConditionChange(data, ev) {
        }

        handleStyle(key, value) {
            switch (key) {
                case 'font-weight' :
                case 'font-style':
                    if (!_.get(this.rule.style, key)) {
                        this.rule.style[key] = value
                    } else {
                        this.rule.style = _.omit(this.rule.style, key);
                    }
                    break;
                case 'text-decoration':
                    if (!_.get(this.rule.style, key)) {
                        this.rule.style[key] = value
                    } else {
                        if (_.includes(_.split(_.get(this.rule.style, key), ' '), value)) {
                            this.rule.style[key] = _.trim(this.rule.style[key].replace(value, ''));
                            this.rule.style = this.rule.style[key] === "" ? _.omit(this.rule.style, key) : this.rule.style;

                        } else {
                            this.rule.style[key] += ` ${value}`;
                        }
                    }
                    break;
            }
        }

        handleStyleActivated(key, value) {
            switch (key) {
                case 'font-weight' :
                case 'font-style':
                    return !_.isNil(_.get(this.rule.style, key));
                    break;
                case 'text-decoration':
                    return _.includes(_.split(_.get(this.rule.style, key), ' '), value)
                    break;
            }
        }

        handleStylePreview(ev) {
            this.draftRule = _.cloneDeep(this.rule);
            this.draftStyle = _.cloneDeep(this.rule.style);
        }

        save() {
            let idx = _.findIndex(this.styleRules, {uid: _.get(this.rule, 'uid', null)});
            if (idx != -1) {
                this.styleRules[idx] = _.cloneDeep(this.rule);
            } else {
                this.styleRules.push(_.cloneDeep(this.rule));
            }
            this.tableService.updateRules(this.mnModel, this.styleRules).then(data => {
                this.styleRules = _.get(data, 'style_rules');
                this.initRule();
            });
        }

        handleEdit(item) {
            this.rule = _.cloneDeep(item);
            this.is_create = true;
            this.handleColumnChange(this.rule.target_column, null);
            this.draftStyle = _.cloneDeep(this.rule.style);
        }

        cancel(){
            this.is_create = false;
            this.initRule();
        }

        removeRule(id){
            let idx=_.findIndex(this.styleRules, {uid:id});
            this.styleRules.splice(idx,1);
            this.tableService.updateRules(this.mnModel, this.styleRules).then(data => {
                this.initRule();
            });
        }

        getTargetValueNbr(ev){
            return  _.chain(this.operators).find({key:this.rule.condition}).get('targetValues', 0).value();
        }

        initDrag() {
            this.dragulaService.options($scope, this.bag, {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.md-button.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            let drake = this.dragulaService.find($scope, this.bag).drake;
            drake.on("dragend", _.mnDelay(this.moveItem, 400));
        }
        moveItem() {
            this.tableService.updateRules(this.mnModel, this.styleRules).then(data => {
                this.styleRules = _.get(data, 'style_rules');
                this.initRule();
            });
        }
        getLabel(data){
            return  _.chain(this.columns).find({'order_by': data}).get('label').value();
        }
        getType(data){
            return  _.chain(this.columns).find({'order_by': data}).get('type').value();
        }


    }

    module.exports = {
        bindings: {
            columns: '=?',
            query: '=?',
            mnFilters: '=?',
            mnModel: '=?',
            reloadHandler: '=?',
            styleRules: '=?',
            draftRule: '=?',
        },
        template: require("shared/views/table-style-rules.html"), // or template
        controllerAs: "vm",
        controller: TableStyleRulesCtrl,
    };

})();