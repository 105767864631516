/**
 * Created by BETALOS on 13/02/2017.
 */
(function () {

    module.exports = subMenu;

    subMenu.$inject = ['$document', '$mdMenu', '$timeout'];

    function subMenu($document, $mdMenu, $timeout) {
        return {
            link: function (scope, element) {

                var menuContainer = $(
                    '<div class="md-open-menu-container md-submenu-container md-active md-clickable md-whiteframe-z2"></div>'
                );
                var menuContents = element.children()[1];
                var removeSubmenuTimeout = setTimeout(_.noop);

                menuContainer.append(menuContents);

                element.on('mouseenter', function (e) {

                    clearTimeout(removeSubmenuTimeout);
                    $(document.body).append(menuContainer);
                    var elementRect = element[0].getBoundingClientRect();
                    var menuRect = menuContainer[0].getBoundingClientRect();
                    var windowRect = document.documentElement.getBoundingClientRect();
                    var menuPos = {};

                    menuPos.left = elementRect.left + elementRect.width;
                    menuPos.top = elementRect.top;

                    var MENU_EDGE_MARGIN = 8;

                    var bounds = {
                        left: windowRect.left + MENU_EDGE_MARGIN,
                        top: windowRect.top + MENU_EDGE_MARGIN,
                        bottom: windowRect.bottom - MENU_EDGE_MARGIN,
                        right: windowRect.right - MENU_EDGE_MARGIN
                    };

                    if (menuPos.left + menuRect.width > bounds.right) {
                        menuPos.left = elementRect.left - menuRect.width;
                    }

                    if (menuPos.top + menuRect.height > bounds.bottom) {
                        menuPos.top = menuPos.top -
                            ((menuPos.top + menuRect.height) - bounds.bottom);
                        if (menuPos.top < bounds.top) {
                            menuPos.height = windowRect.height - (MENU_EDGE_MARGIN * 2);
                            menuPos.top = bounds.top;
                        }
                    }

                    menuContainer.css({
                        'left': menuPos.left + 'px',
                        'top': menuPos.top + 'px'
                    });

                    menuContainer.one('mouseenter', function () {
                        clearTimeout(removeSubmenuTimeout);
                    });

                    menuContainer.one('mouseleave', function () {
                        removeSubmenuTimeout = setTimeout(function () {
                            menuContainer.remove();
                        });
                    });

                    function closeOnClick() {
                        $timeout(function () {
                            $mdMenu.hide();
                            menuContainer.remove();
                            $document.off('click', closeOnClick);
                        }, 200);
                    }

                    $document.on('click', closeOnClick);
                });

                element.on('mouseleave', function (e) {
                    removeSubmenuTimeout = setTimeout(function () {
                        menuContainer.remove();
                    });
                });

            }
        };
    }
})()