/**
 * Edited by Amine on 12/10/2021.
 */
(function () {
    'use strict';

    class ProcedurePrescriptionFormCtrl {
        constructor($q, system, prescriptionService, editorManagerService, authService, $transition$, $mdDialog, moment) {
            this.q = $q;
            this.prescriptionService = prescriptionService;
            this.editorManagerService = editorManagerService;
            this.dialog = $mdDialog;
            this.moment = moment;

            this.dateFormat = system['date_format'].js;
            this.isRoot = authService.isRootProfile();

            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.currentVisit = _.get($transition$.params('to'), 'visitId');
            this.currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
            this.currentCopy = _.get($transition$.params('to'), 'copy');

            this.promise = null;
            this.prescription = {};
            this.editorOpts = {};
            this.editorConfigKey = "procedure_prescription";
            this.showFileManager = false;
            this.editorName = _.uniqueId("procedure-prescription-");
            this.editModel = false;
        }

        static get $inject() {
            return ["$q", "system", "prescriptionService", "editorManagerService", "authService", "$transition$", "$mdDialog", "moment"];
        }

        $onInit() {
            let promise = null;
            this.prescriptionService.prescriptionType.next({type: 'ProcedurePrescription'});

            this.prescriptionService.itemActive.next({id: -1, type: null});
            this.prescriptionService.itemDuplicated.next({id: -1, type: null});
            this.prescriptionService.itemEdited.next({id: this.currentPrescription, type: 'ProcedurePrescription'});

            if (!_.isNull(this.currentCopy)) {
                this.prescriptionService.itemDuplicated.next({id: this.currentCopy, type: 'ProcedurePrescription'});
                promise = this.prescriptionService.getProcedurePrescription(this.currentCopy);
            } else if (!_.isNull(this.currentPrescription)) {
                promise = this.prescriptionService.getProcedurePrescription(this.currentPrescription);
            } else {
                promise = this.emptyPrescription();
            }

            this.promise = promise.then(data => {
                this.prescription = _.isNull(this.currentCopy) ? data : _.chain(data)
                    .pick(data, 'details')
                    .assign({
                        visit: {id: this.currentVisit},
                        prescription_date: this.moment().format(this.dateFormat)
                    })
                    .value();

                if (_.isNull(this.currentCopy)) {
                    this.editModel = data['is_model'];

                    this.prescriptionService.prescriptionType.next({
                        type: 'ProcedurePrescription',
                        model: this.editModel
                    })
                }
            });

            this.itemDeletedSubscription = this.prescriptionService.itemDeleted.subscribe(item => this.itemDeleted(item));
            this.itemAddedSubscription = this.prescriptionService.addProcedurePrescriptionSubject.subscribe(id => this.itemAdded(id));
            this.fileManagerSubscription = this.prescriptionService.fileManager.subscribe(next => {
                this.showFileManager = !!next;
            });
        }

        $onDestroy() {
            this.itemDeletedSubscription.unsubscribe();
            this.itemAddedSubscription.unsubscribe();
            this.fileManagerSubscription.unsubscribe();
        }

        submit(quit) {
            return this.prescriptionService
                .saveProcedurePrescription(this.prescription, this.currentPatient)
                .then(data => {
                    let promise = this.q.when(data);

                    if (this.editModel) {
                        this.prescriptionService.modelAdded.next(data);
                    } else {
                        this.prescriptionService.itemAdded.next(data);

                        if (data.is_locked && !data.is_signed) {
                            promise = this.prescriptionService.sign("procedure-prescription", data);
                        } else if (!data.is_locked && data.is_signed) {
                            promise = this.prescriptionService.unsign("procedure-prescription", data);
                        }
                    }

                    promise.then(data => {
                        if (quit === "list") return this.prescriptionService.listState();
                        if (quit === "back") return this.goBack();

                        if (_.isNull(this.currentPrescription)) {
                            this.prescription = data;
                            this.currentPrescription = data.id;
                            this.prescriptionService.refreshState(data);
                        } else {
                            this.prescription.is_locked = data.is_locked;
                            this.prescription.is_signed = data.is_signed;
                        }
                    });

                    return data;
                });
        }

        saveModel($event) {
            let confirm = this.dialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.ProcedurePrescription.title_validation')
                .placeholder('title')
                .targetEvent($event)

            this.dialog.show(confirm)
                .then(modelData => {
                    let model = _.chain(this.prescription)
                        .pick('details')
                        .assign({title: modelData})
                        .value();

                    this.prescriptionService.saveProcedurePrescription(model)
                        .then(data => this.prescriptionService.modelAdded.next(data));
                });
        }

        callback(data) {
            _.forEach(data, item => {
                const newDetail = {
                    code: item.code,
                    name: item.name,
                    qte: 1,
                    comment: "",
                    procedure: item
                }
                let itemExist = _.find(this.prescription.details, {name: item.name, procedure: {id: item.id}});

                if (_.isUndefined(itemExist)) this.prescription.details.push(newDetail);
                else itemExist.qte += 1;
            });
        }

        addEmptyDetail() {
            this.prescription.details.push({
                name: null,
                qte: 1,
                code: "",
                comment: ""
            });
        }

        signPrescription() {
            this.prescription.is_locked = true;

            return this.submit();
        }

        unlockPrescription() {
            if ((this.prescription.is_locked && this.prescription.is_signed) || this.isRoot)
                return this.prescriptionService.unsign("procedure-prescription", this.prescription, this.isRoot)
                    .then(data => this.prescription.is_locked = data.is_locked);
            else {
                return this.q.when(this.prescription);
            }
        }

        changeTab(shown) {
            this.isTable = shown;
            if (this.isTable) {
                this.editorManagerService.destroyTinymce(this.editorName);
            }
        }

        goBack() {
            return window.history.back();
        }

        emptyPrescription() {
            return this.q.when({
                visit: {id: this.currentVisit},
                prescription_date: this.moment().format(this.dateFormat),
                details: []
            });
        }

        itemDeleted(item) {
            if (_.isEqual(item, {id: this.currentPrescription, prescription_type: 'ProcedurePrescription'})) {
                this.prescriptionService.listState();
            }
        }

        itemAdded(id) {
            this.promise = this.prescriptionService.getProcedurePrescription(id)
                .then(prescription => {
                        this.prescription.details = _.chain(this.prescription.details)
                            .concat(prescription.details)
                            .uniqWith((a, b) => {
                                if (_.isNil(a.procedure) || _.isNil(b.procedure)) return false;
                                else return a.procedure.id === b.procedure.id;
                            })
                            .value();
                    }
                )
        }

    }

    module.exports = ProcedurePrescriptionFormCtrl;

})();
