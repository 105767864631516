(function () {

    'use strict';

    module.exports = CurrentExamsCtrl;

    CurrentExamsCtrl.$inject = ["rehabilitationService", "patientService"];

    function CurrentExamsCtrl(rehabilitationService, patientService) {
        let vm = this;

        vm.$onInit = init;
        vm.removePatient = removePatient;
        vm.patientCallback = patientCallback;

        function init() {
            vm.tracks = [];
            vm.currentExam = null;
            vm.filter = {is_deleted: {"$ne": true}, is_draft: {"$ne": true}};
        }

        function patientCallback(patient) {
            vm.promise = patientService.getMinimalPatient(patient.id, false)
                .then(doneCallback);

            function doneCallback(data) {
                vm.currentExam.patient = data;
            }
        }

        function removePatient() {
            vm.currentExam.patient = null;
        }

    }

})();