/**
 * Created by amine on 14/11/2017.
 */
(function () {

    'use strict';

    let mnChipsList = {
        controller: MnChipsListController,
        controllerAs: "vm",
        require: {ngModelController: "ngModel"},
        bindings: {
            list: "=ngModel",
            change: "&ngChange",
            disabled: "<?ngDisabled",
            locked: "<?lockAdd"
        },
        template: tpl,
    };

    MnChipsListController.$inject = ["listService", "$mdDialog", "$attrs", "$timeout", "$element"];

    function MnChipsListController(listService, $mdDialog, attrs, $timeout, $element) {
        let vm = this;

        let model = attrs['mnModel'];
        let label = attrs['mnLabel'];
        let tpl = _.isString(attrs['mnTpl']) ? attrs['mnTpl'] : "default";

        vm.$onInit = init;

        vm.add = add;
        vm.cancel = cancel;
        vm.changeValue = changeValue;
        vm.querySearch = querySearch;
        vm.transformChip = transformChip;


        function init() {
            setInterval(function () {
                vm.list = _.isArray(vm.list) ? vm.list : [];
            }, 200);
            vm.disabled = _.isNil(vm.disabled) ? $element.is('[disabled]') : vm.disabled;
            vm.locked = _.isNil(vm.locked) ? false : vm.locked;
            vm.searchText = null;

            $element.on("click", "md-chips", function ($event) {
                if ($($event.target).is("md-chips-wrap")) {
                    $(".md-input", $element).trigger('focus');
                }
            });
        }

        function querySearch(query) {
            return listService['autocompleteListSearch'](model, query)
        }

        function changeValue() {
            $timeout(vm.change, 100, vm.selectedItem);
        }

        function cancel() {
            vm.list = null;
        }

        function add(ev) {
            $mdDialog.show(_.assign(require('../dialogs/list-dialog'), {
                template: require("shared/views/list." + tpl + ".tpl.html"),
                targetEvent: ev,
                locals: {
                    mnLabel: label,
                    mnModel: attrs['mnModel']
                },
            })).then(done);

            function done(data) {
                vm.list.push(data);
            }
        }

        function transformChip(chip) {
            if (_.isObject(chip)) return chip;
            else return null;
        }

    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl($elem, attrs, $translate) {
        const required = $elem.is("[required]") ? " required " : "";
        const disabled = !_.isUndefined(attrs['ngDisabled']) ? ' ng-readonly="vm.disabled" ' : "";
        //let is_disabled = $elem.is('[disabled]') ? " disabled " : "";

        //let change = $elem.is('[ng-change]') ? ' md-selected-item-change="vm.changeValue() "' : "";
        const label = $translate.instant(attrs['mnLabel']);
        const tooltip = _.isNil(attrs['tooltip']) ? label : $translate['instant'](attrs['tooltip']);
        const placeholder = $translate.instant('add_autocomplete_value');

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `<div class="mn-autocomplete-container layout-column flex">
                   <div class="control-block layout-row">
                       <label for="">${label}</label>
                       <span flex></span>
                   </div>
                   <div class="layout-row layout-align-end-end flex">
                   <md-chips ng-model="vm.list" flex md-autocomplete-snap
                       md-transform-chip="vm.transformChip($chip)" ${required} ${disabled}
                           md-require-match="true">
                       <md-autocomplete md-delay="400"
                               md-search-text="vm.searchText"
                               md-items="item in vm.querySearch(vm.searchText)"
                               md-item-text="item.value"
                               md-require-match="true"
                               md-menu-container-class="mn-chips-list-menu-container"
                               placeholder="${placeholder}">
                           <md-item-template>
                               <span md-highlight-text="vm.searchText">{{item.value}}</span>
                               <md-tooltip md-direction="bottom">
                                   {{item.value}}
                               </md-tooltip>
                           </md-item-template>
                       </md-autocomplete>
                       <md-chip-template>
                           <span>
                           <strong>{{$chip.value}}</strong>
                           </span>
                       </md-chip-template>
                   </md-chips>
                   <md-tooltip md-direction="bottom">
                        <span>${tooltip}</span>
                    </md-tooltip>
                   <div class="mn-option-buttons flex-nogrow layout-row">
                       <md-button class="md-icon-button" ng-if="!vm.disabled && !vm.locked" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                           <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                       </md-button>
                       <md-button class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                           <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                       </md-button>
                   </div>
                   </div>
                </div>`;
    }

    module.exports = mnChipsList;

})();