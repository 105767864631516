/**
 * Created by amine on 20/02/2017.
 */
(function () {
    'use strict';
    module.exports = ageFilter;

    ageFilter.$inject = ['system', '$translate'];

    function ageFilter(system, $translate) {
        var dateFormat = system['date_format'].js;

        return filter;

        function filter(input) {
            if (_.isNil(input)) return $translate['instant']('year_age', {age: 0});

            var now = moment();
            var birthDate = moment(input, dateFormat)

            var diffDuration = moment.duration(now.diff(birthDate));


            return $translate['instant']('exact_age', {months: diffDuration.months(), years: diffDuration.years()});
        }
    };

})();