/**
 * Created by BETALOS on 20/02/2017.
 */
(function () {

    'use strict';

    class AutoFocus {
        constructor($timeout) {
            this.priority = 1;
            this.restrict = "A";
            this.timeout = $timeout;
        }

        static create() {
            return new AutoFocus(...arguments);
        }

        link(scope, element, attrs) {
            scope.$watch(
                () => scope.$eval(attrs['mnAutoFocus']),
                (value, oldValue) => this.handleFocus(value, oldValue, element),
                true
            );
        }

        handleFocus(value, oldValue, element) {
            if (_.isUndefined(value) || value || value !== oldValue) this.timeout(() => this.focus(element), 200);
        }

        focus(element) {
            const $node = this.getFocusElement(element);

            if (!$node.is(':focus')) {
                if ($node.is('[type="number"]')) $node.focus();
                else $node.focusInputEnd();
            }
        }

        getFocusElement(element) {
            if (element.is("input")) return element;
            else if (element.find('input').length > 0) return $('input', element);
            else if (element.find('textarea').length) return $('textarea', element);
            else {
                throw `element ${element.get(0)} is not focusable`;
            }
        }

    }

    AutoFocus.create.$inject = ['$timeout'];

    module.exports = AutoFocus.create;

})();
