/**
 * edited by amine on 08/10/2021.
 */
(function () {

    'use strict';

    const {Subject, BehaviorSubject} = require("rxjs");
    const VERIFICATION_DIALOG = require("auth/dialogs/auth-verification-dialog");

    class PrescriptionService {
        constructor($q, mnWebSocket, $http, $state, configService, listService, $translate, $mdDialog) {
            this.q = $q;
            this.ws = mnWebSocket;
            this.http = $http;
            this.state = $state;
            this.configService = configService;
            this.listService = listService;
            this.translate = $translate;
            this.dialog = $mdDialog;

            this.configuration = {};

            this.itemDeleted = new Subject();
            this.itemEdited = new Subject();
            this.itemActive = new Subject();
            this.itemDuplicated = new Subject();
            this.itemAdded = new Subject();
            this.modelAdded = new Subject();
            this.prescriptionType = new Subject();
            this.useBiologyModel = new Subject();
            this.fileManager = new Subject();

            this.useCarePrescriptionSubject = new BehaviorSubject(null);

            this.addMedicalPrescriptionSubject = new Subject();
            this.addProcedurePrescriptionSubject = new Subject();
            this.addTextPrescriptionSubject = new Subject();
        }

        static get $inject() {
            return [
                "$q", "mnWebSocket", "$http", "$state", "configService", "listService", "$translate", "$mdDialog"
            ];
        }

        getResult(requestId) {
            return this.ws.call("prescription.BiologyPrescription.get_last_result", {
                request_id: requestId
            });
        }

        getConfig() {
            const deferred = this.q.defer();
            let config = {}

            this.configService.get("prescription_config")
                .then(firstData => {
                    config = firstData;

                    this.listService.get(config.default_values['dosage_form'])
                        .then(secondData => {
                            config.default_values['dosage_form'] = secondData;
                            deferred.resolve(config);
                        });
                }, _.noop);

            return deferred.promise;
        }

        get useCarePrescription() {
            const deferred = this.q.defer();

            const value = this.useCarePrescriptionSubject.getValue();
            if (value == null && this.configService.hasMedicalCare()) {
                this.configService.getByHttp("medical_care_config")
                    .then(data => deferred.resolve(_.get(data, "use_care_prescription", false)))
            } else {
                deferred.resolve(value);
            }

            return deferred.promise;
        }

        getVisitPrescriptionsList(visit) {
            return this.q.all(_.map([
                "MedicalPrescription",
                "ProcedurePrescription",
                "TextPrescription",
                "BiologyPrescriptionRequest",
                "BiologyPrescriptionResult",
                "CarePrescription"
            ], eventSet => {
                return this.ws.call(`prescription.${eventSet}.full_list`, {visit: _.isObject(visit) ? visit.id : visit})
            }));
        }

        getModelMedicalPrescriptionList() {
            return this.getList("medical-prescription-model");
        }

        getModelTextPrescriptionList() {
            return this.getList("text-prescription-model");
        }

        getModelBiologyPrescriptionList() {
            return this.getList("biology-prescription-model");
        }

        getModelProcedurePrescriptionList() {
            return this.getList("procedure-prescription-model");
        }

        getModelCarePrescriptionList() {
            return this.getList("care-prescription-model");
        }

        getList(url) {
            const deferred = this.q.defer();

            this.http.get(`/api/${url}/`)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getMedicalPrescriptionList(patient) {
            return this.getByPatient("medical-prescription-list", patient);
        }

        getMedicalPrescriptions(patient) {
            return this.getByPatient("medical-prescription", patient);
        }

        getTextPrescriptionList(patient) {
            return this.getByPatient("text-prescription-list", patient);
        }

        getTextPrescriptions(patient) {
            return this.getByPatient("text-prescription", patient);
        }

        getBiologyPrescriptions(patient) {
            return this.getByPatient("biology-prescription", patient);
        }

        getBiologyPrescriptionList(patient) {
            return this.getByPatient("biology-prescription-list", patient);
        }

        getProcedurePrescriptions(patient) {
            return this.getByPatient("procedure-prescription", patient);
        }

        getProcedurePrescriptionList(patient) {
            return this.getByPatient("procedure-prescription-list", patient);
        }

        getCarePrescriptions(patient) {
            return this.getByPatient("care-prescription", patient);
        }

        getCarePrescriptionList(patient) {
            return this.getByPatient("care-prescription-list", patient);
        }

        getByPatient(url, patient) {
            const deferred = this.q.defer();
            const urlHandled = `/api/${url}/?patient=${patient}`;

            this.http.get(urlHandled)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getTextPrescription(pk) {
            return this.getById("text-prescription", pk);
        }

        getMedicalPrescription(pk) {
            return this.getById("medical-prescription", pk);
        }

        getMedicalModelPrescription(pk) {
            return this.getById("medical-prescription-model", pk);
        }

        getBiologyPrescription(pk, isResult) {
            if (isResult) {
                return this.getById("biology-prescription-result", pk);
            } else {
                return this.getById("biology-prescription", pk);
            }
        }

        getLegacyBiologyPrescription(pk) {
            return this.getById("biology-prescription-legacy", pk);
        }

        getBiologyModelPrescription(pk) {
            return this.getById("biology-prescription-model", pk);
        }

        getProcedurePrescription(pk) {
            return this.getById("procedure-prescription", pk);
        }

        getProcedureModelPrescription(pk) {
            return this.getById("procedure-prescription-model", pk);
        }

        getCarePrescription(pk) {
            return this.getById("care-prescription", pk);
        }

        getCareModelPrescription(pk) {
            return this.getById("care-prescription-model", pk);
        }

        getById(url, pk) {
            const deferred = this.q.defer();
            const urlUsed = `/api/${url}/${pk}/`;

            this.http.get(urlUsed)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getPrescription(pk, prescriptionType) {
            return this[`get${prescriptionType}`](pk);
        }

        getPrescriptions(pk, prescriptionType) {
            const callable = this[`get${prescriptionType}s`];
            if (_.isFunction(callable)) {
                return callable(pk)
            } else {
                return this.q.reject(null);
            }
        }

        prescriptionLists(patient, useCarePrescription = false) {
            let pPromises = [
                this.getTextPrescriptionList(patient),
                this.getMedicalPrescriptionList(patient),
                this.getBiologyPrescriptionList(patient),
                this.getProcedurePrescriptionList(patient)
            ];

            if (useCarePrescription) {
                pPromises.push(this.getCarePrescriptionList(patient))
            }

            return pPromises;
        }

        prescriptionModelLists(useCarePrescription = false) {
            let mPromises = [
                this.getModelTextPrescriptionList(),
                this.getModelMedicalPrescriptionList(),
                this.getModelBiologyPrescriptionList(),
                this.getModelProcedurePrescriptionList(),
            ];

            if (useCarePrescription) {
                mPromises.push(this.getModelCarePrescriptionList())
            }

            return mPromises;
        }

        getPrescriptionLists(patient) {
            const deferred = this.q.defer();

            this.useCarePrescription.then(useCarePrescription => {
                this.q.all(this.prescriptionLists(patient, useCarePrescription))
                    .then(prescriptions => {
                        deferred.resolve({
                            prescriptions: _.flatten(prescriptions),
                            useCarePrescription
                        })
                    }, deferred.reject);
            });

            return deferred.promise;
        }

        getAllPrescriptionLists(patient) {
            const deferred = this.q.defer();

            this.useCarePrescription.then(useCarePrescription => {
                this.q.all({
                    prescriptions: this.q.all(this.prescriptionLists(patient, useCarePrescription)),
                    models: this.q.all(this.prescriptionModelLists(useCarePrescription))
                }).then(({prescriptions, models}) => {
                    deferred.resolve({
                        prescriptions: _.flatten(prescriptions),
                        models: _.flatten(models),
                        useCarePrescription
                    })
                }, deferred.reject);
            });

            return deferred.promise;
        }

        saveTextPrescription(prescription, patient) {
            return this.save("text-prescription", prescription, patient);
        }

        saveMedicalPrescription(prescription, patient) {
            return this.save("medical-prescription", prescription, patient);
        }

        saveBiologyPrescription(prescription, patient) {
            return this.save("biology-prescription", prescription, patient);
        }

        saveProcedurePrescription(prescription, patient) {
            return this.save("procedure-prescription", prescription, patient);
        }

        saveBiologyPrescriptionResult(prescription, patient) {
            return this.save("biology-prescription-result", prescription, patient);
        }

        saveBiologyPrescriptionRequest(prescription, patient) {
            return this.save("biology-prescription-request", prescription, patient);
        }

        saveCarePrescription(prescription, patient) {
            return this.save("care-prescription", prescription, patient);
        }

        save(url, prescription, patient) {
            const deferred = this.q.defer();
            const urlUsed = `/api/${url}/${prescription.id ? `${prescription.id}/` : ''}${patient ? "?patient=" + patient : ''}`;

            this.http.post(urlUsed, prescription)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        getLastMedicalPrescription(patient) {
            return this.ws.call("prescription.MedicalPrescription.get_last_prescription", {patient});
        }

        sign(url, prescription) {
            const deferred = this.q.defer();
            const urlUsed = `/api/${url}/${prescription.id}/sign/`;

            this.http.put(urlUsed, prescription)
                .then(result => deferred.resolve(result.data), deferred.reject);

            return deferred.promise;
        }

        unsign(url, prescription, force = false) {
            const deferred = this.q.defer();
            const urlUsed = `/api/${url}/${prescription.id}/unsign/`;
            const reset = () => {
                prescription.is_locked = true;
                deferred.resolve(prescription);
            }
            const resp = result => {
                if (result.data) deferred.resolve(result.data)
                else {
                    reset();
                    deferred.reject(null);
                }
            }

            if (force) {
                this.http.put(urlUsed, _.pick(prescription, "id"))
                    .then(result => resp(result), deferred.reject);
            } else {
                this.dialog
                    .show(_.assign({}, VERIFICATION_DIALOG))
                    .then(pass => {
                        if (pass) {
                            this.http.put(urlUsed, _.pick(prescription, "id"))
                                .then(result => resp(result), deferred.reject);
                        } else reset()
                    }, () => reset());
            }

            return deferred.promise;
        }

        constructedTitle(item) {
            if (_.isUndefined(item)) return false;

            let translationKey = _.snakeCase(item['prescription_type']) + '_title';

            if (_.isNull(item.title) || _.isEmpty(item.title)) {
                return this.translate.instant(translationKey, {
                    date: item.prescription_date
                });
            }

            return `${item.title} (${item.prescription_date})`;
        }

        listState() {
            this.fileManager.next(false);
            this.state.go("app.visit.prescription.list");
        }

        editState(item, reload = true) {
            let params = {
                prescriptionId: item['id'],
                copy: null,
                legacy: item['is_legacy'] ? 1 : null
            }

            if (item['is_result']) {
                params.requestId = item['request_id'];
            } else if (_.eq(item['prescription_type'], 'BiologyPrescription')) {
                params.requestId = null;
            }

            this.state.go(`app.visit.prescription.${_.kebabCase(item['prescription_type'])}`, params, {
                    inherit: true
                }
            ).then(reload ? this.state.reload : _.noop, _.noop);
        }

        refreshState(item, supQuery = {}) {
            this.itemEdited.next({id: -1, type: null});
            this.itemDuplicated.next({id: -1, type: null});
            this.itemEdited.next({id: item.id, type: item['prescription_type']});

            this.state.go(`app.visit.prescription.${_.kebabCase(item['prescription_type'])}`, _.assign({
                prescriptionId: item.id,
                copy: null
            }, supQuery), {location: 'replace'});
        }

        duplicateState(pk, prescriptionType, reload = true) {
            this.state.go(`app.visit.prescription.${_.kebabCase(prescriptionType)}`, {
                prescriptionId: null,
                requestId: null,
                copy: pk
            }).then(reload ? this.state.reload : _.noop);
        }

        deleteItem(item, ev) {
            const deferred = this.q.defer();
            const url = `/api/${_.kebabCase(item['prescription_type'])}/${item.id}/`
            const confirmKey = item.is_model ? "prescription_model_remove_confirm" : "prescription_remove_confirm";

            const confirm = this.dialog.confirm()
                .title(this.translate.instant(confirmKey, _.pick(item, 'title')))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.translate.instant('confirm_ok'))
                .cancel(this.translate.instant('confirm_cancel'));

            this.dialog.show(confirm)
                .then(() => {
                    this.http.delete(url)
                        .then(() => {
                            this.itemDeleted.next(_.pick(item, ['id', 'prescription_type']));
                            deferred.resolve(item.id);
                        }, deferred.reject)
                }, deferred.reject);

            return deferred.promise;
        }

        printGroup(item) {
            let printGroup = _.get(item, 'prescription_type');
            if (printGroup === "BiologyPrescription") {
                if (_.get(item, 'request_id', false) || item['is_result'])
                    printGroup = "BiologyPrescriptionResult";
                else
                    printGroup = "BiologyPrescriptionRequest";
            }

            return _.kebabCase(printGroup);
        }

        getBiologyResume(patient, exams) {
            let query = {
                patient: patient,
                exams: _.map(exams, 'id')
            }

            return this.ws.call("prescription.BiologyPrescriptionResult.biology_resume", query);
        }

        orderFromMedicalPrescription(prescription) {
            return this.ws.call("prescription.MedicalPrescription.generate_order",
                {"id": _.get(prescription, "id", null), order: _.get(prescription, "order.id", null)}
            );
        }
    }

    module.exports = PrescriptionService;

    /*
    * vidal @todo to check
    *
    * function getLeaflet(item) {
            return mnWebSocket.call("prescription.VidalConfig.get_product_leaflet", item);
        }
    * */

})();
