/**
 * Created by amine on 17/08/2017.
 */
(function () {
    'use strict';

    class SystemParameterCtrl {
        constructor(configService, $mdToast, $translate) {
            this.configService = configService;
            this.toast = $mdToast;

            this.value = null;
            this.model = null;
            this.key = this.key || undefined;
            this.attr = this.attr || undefined;
            this.fromAttr = this.fromAttr || undefined;
            this.pick = this.pick || undefined;
            this.reloadMnc = this.reloadMnc || false;
            this.readonly = this.readonly || false;

            this.handleAutoSave = () => this.save();

            this.simpleToast = this.toast.simple()
                .textContent($translate.instant('patient_edit_success'))
                .position("bottom left")
                .hideDelay(1500);
        }

        static get $inject() {
            return ["configService", "$mdToast", "$translate"];
        }

        $onInit() {
            this.configService.get(this.key)
                .then((data) => {
                    this.value = data;
                    this.model = _.isNil(this.attr) ? data : _.get(data, this.attr, null);
                });
        }

        save() {
            let config = {};
            let valueToSet = this.model;

            if (!_.isNil(this.fromAttr)) valueToSet = _.get(valueToSet, this.fromAttr, this.model);
            if (!_.isNil(this.pick)) valueToSet = _.pick(valueToSet, this.pick);

            if (_.isNil(this.attr)) {
                config = _.set(config, this.key, valueToSet);
            } else {
                this.value = _.set(this.value || {}, this.attr, valueToSet);
                config = _.set(config, this.key, this.value);
            }
            this.configService.set(config, null, null, null, this.reloadMnc)
                .then(() => {
                    this.toast.show(this.simpleToast);
                }, _.noop)
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($element, $attrs) {
        const label = $attrs['label'];
        const html = $element.html();

        if (_.isEmpty(html))
            return `
                <md-input-container flex="nogrow">
                    <label translate-once="${_.isNil(label) ? "label_{{vm.key}}" : label}"></label>
                    <input ng-model="vm.model" ng-change="vm.handleAutoSave()" aria-label="{{vm.key}}"
                    ng-disabled="vm.readonly" ng-model-options="{debounce : 300}">
                </md-input-container>
            `;
        else
            return html;
    }

    module.exports = {
        controllerAs: "vm",
        controller: SystemParameterCtrl,
        bindings: {
            key: "@",
            attr: "@?",
            fromAttr: "@?",
            pick: "@?",
            reloadMnc: "<?",
            readonly: "<?"
        },
        template: tpl, // or template
    };

})();
