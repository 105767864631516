/**
 * Created by amine on 22/02/2017.
 */

(function () {
    'use strict';

    class AccountDetailsCtrl {
        constructor(configService, $q, paymentService, $translate, $mdDialog, system) {
            this.dateFormat = system['date_format'].js;
            this.configService = configService;
            this.paymentService = paymentService;
            this.translate = $translate;
            this.$mdDialog = $mdDialog;

            this.promise = null;
            this.loaded = false;
            this.account = {};
            this.details = [];

            this.paginationLabel = {
                page: $translate['instant']('page'),
                rowsPerPage: $translate['instant']('rowsPerPage'),
                of: $translate['instant']('of')
            };
            this.query = {
                page: 1,
                limit: 10,
                order: 'date'
            };
            this.options = [5, 10, 20, 50];
        }

        static get $inject() {
            return ['configService', '$q', 'paymentService', '$translate', '$mdDialog', 'system'];
        }

        $onInit() {
            this.type = this.type || "P";
        }

        $onChanges(attrs) {
            const load = _.get(attrs, "load.currentValue", false);

            if (load && !this.loaded) this.loadData();
        }

        loadData() {
            if (_.has(this.account, "id")) {
                this.promise = this.paymentService.getAccountDetails(this.account.id, this.type).then(data => {
                    this.loaded = true;
                    this.details = this.getData(_.flatten(data));
                });
            }
        }

        getData(data) {
            const order = this.query.order[0] === '-' ? 'desc' : 'asc';
            const min = (this.query.page - 1) * this.query.limit, max = (this.query.page) * this.query.limit;

            return _.chain(data)
                .orderBy(detail => {
                    return moment(detail.date, this.dateFormat).format('X')
                }, order)
                .slice(min, max)
                .value();
        }

        onReorder(order) {
            this.query = _.assign(this.query, {order});
        }

        onPaginate(page, limit) {
            this.query = _.assign(this.query, {page, limit});
        }

        showDetail(item, event) {
            const dialog = _.assign(require('../dialogs/account-details-dialog'), {
                targetEvent: event,
                locals: {
                    item: _.assign({}, item, {title: this.getTitle(item)}),
                }
            });

            this.$mdDialog.show(dialog);
        }

        getTitle(item) {
            switch (item.type) {
                case 'visit':
                    return `${this.translate.instant('visit_at')} ${item.date}`;
                case 'org':
                    return this.translate.instant('visit_organization_payment', {
                        organization: item.organization,
                        date: item.visit.date
                    });
                case 'plan':
                    let title = this.translate.instant('dental_module_treatment_plan_default_title', {date: item.date});
                    if (title === item.title) return item.title
                    return `${item.title} (${title})`
                case 'encasement':
                    return this.translate.instant('title_encasement_w_remaining', {
                        date: item.date,
                        amount: item.remaining_amount
                    });
            }
        }
    }


    module.exports = {
        controller: AccountDetailsCtrl,
        controllerAs: "vm",
        bindings: {
            account: "<",
            load: "<",
            type: "@"
        },
        template: require('payment/views/account-details.tpl.html'),
    };

})();
