/**
 * Created by BETALOS on 14/10/2016.
 */

(function () {
    'use strict';

    class DefaultValue {
        constructor(configService) {
            this.priority = 0;
            this.restrict = "A";
            this.require = "ngModel";

            this.configService = configService;
        }

        static create() {
            return new DefaultValue(...arguments);
        }

        link(scope, element, attrs, ngModelCtrl) {
            let key = attrs['mnDefaultValue'];
            let delayed = _.has(attrs, 'delayedDefaultValue');

            if (delayed) scope.$watch(attrs['delayedDefaultValue'], value => value ? this.setValue(ngModelCtrl, key) : null);
            else this.setValue(ngModelCtrl, key);
        }

        setValue(ngModelCtrl, key) {
            let modelValue = ngModelCtrl.$modelValue;
            let value = _.get(this.configService.defaultValues, key, null);

            if (value && !modelValue) {
                ngModelCtrl.$setViewValue(value);
                ngModelCtrl.$commitViewValue();
                ngModelCtrl.$render();
            }
        }
    }

    DefaultValue.create.$inject = ["configService"];

    module.exports = DefaultValue.create;

})();
