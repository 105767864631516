/**
 * Created by Amine on 02/03/2017.
 */
(function () {

    "use strict";

    class SrefActive {
        constructor($transitions, $state) {
            this.priority = 0;
            this.restrict = "A";
            this.scope = {
                active: "=mnSrefActive",
            }

            this.$transitions = $transitions;
            this.skipConditions = [
                (key, value) => _.includes($state.current.name, "generic-states") && key !== 'generic-states'
            ];
        }

        static create() {
            return new SrefActive(...arguments);
        }

        link(scope, element, attrs) {
            this.$transitions.onFinish({}, (transition) => this.checkState(scope, element, transition));
        }

        checkState(scope, element, transition) {
            const toState = transition.to();

            _.forEach(scope.active, (value, key) => {
                if (_.some(this.skipConditions, condition => _.isFunction(condition) ? condition(key, value) : condition)) return false;

                element.toggleClass(value, _.includes(toState.name, key));
            });

            return true;
        }
    }

    SrefActive.create.$inject = ['$transitions', '$state'];

    module.exports = SrefActive.create;

})();
