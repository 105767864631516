/**
 * Created by amine on 11/12/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controller: MedicinePosologiesCtrl,
        controllerAs: "vm",
        template: require("shared/views/medicine-posologies.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
    };

    MedicinePosologiesCtrl.$inject = ["$q", "$mdDialog", "medicineService", "prescriptionService"];

    function MedicinePosologiesCtrl($q, $mdDialog, medicineService, prescriptionService) {
        let vm = this;

        vm.$onInit = init;
        vm.cancel = $mdDialog.cancel;
        vm.foo = _.noop;
        vm.edit = edit;
        vm.remove = remove;
        vm.submit = submit;

        function init() {
            vm.promise = $q.all([medicineService.getPosologies(vm.medicine), prescriptionService.getConfig()]).then(success);

            function success(data) {
                vm.posologies = data[0];
                vm.useArab = _.get(data[1], "arab_generator.is_used", false);
            }
        }

        function edit($index) {
            vm.editing = $index;
            vm.item = _.clone(vm.posologies[$index]);
        }

        function remove($index) {
            vm.posologies.splice($index, 1);
            medicineService.savePosologies(vm.medicine, vm.posologies);
        }

        function submit() {
            if (_.isNil(vm.editing)) add();
            else setList()
        }

        function add() {
            if (!_.isEmpty(vm.item.text)) medicineService.addPosology(vm.medicine, vm.item)
                .then(() => {
                    vm.posologies.push(_.clone(vm.item));
                    vm.item = {};
                })
        }

        function setList() {
            vm.posologies[vm.editing] = _.clone(vm.item)
            vm.editing = null;
            vm.item = {};
            medicineService.savePosologies(vm.medicine, vm.posologies);
        }
    }

})();