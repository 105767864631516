/**
 * Created by Amine on 26/08/2016.
 */
(function () {
    'use strict';

    window.emmet = require("emmet-core/emmet");
    const {Ace, edit} = require("ace-builds");

    require("ace-builds/src-noconflict/mode-yaml");
    require("ace-builds/src-noconflict/mode-html");
    require("ace-builds/src-noconflict/mode-xml");
    require("ace-builds/src-noconflict/mode-json");
    require("ace-builds/src-noconflict/mode-python");
    require("ace-builds/src-noconflict/mode-css");
    require("ace-builds/src-noconflict/mode-javascript");
    require("ace-builds/src-noconflict/mode-django");
    require("ace-builds/src-noconflict/theme-tomorrow_night_eighties");

    require("ace-builds/src-noconflict/ext-emmet");
    require("ace-builds/src-noconflict/ext-searchbox");
    require("ace-builds/src-noconflict/ext-language_tools");

    const {beautify} = require("ace-builds/src-noconflict/ext-beautify");

    require("ace-builds/webpack-resolver");

    class AceController {
        constructor($element) {
            this.ngModelCtrl = null;
            const node = $("div:first", $element).get(0);
            this.editor = edit(node);
            this.editor.setFontSize('14px');
            this.editor.$blockScrolling = Infinity;
            this.editor.setTheme('ace/theme/tomorrow_night_eighties');

            this.editor.commands.addCommand({
                name: 'save',
                exec: () => this.submit(),
                bindKey: {"win": "Ctrl-S", "mac": "Cmd-S"}
            })
        }

        static get $inject() {
            return ["$element"];
        }

        $onInit() {
            this.submitFunc = this.submitFunc || _.noop;
            this.ngModelCtrl.$render = () => this.render();

            this.editor.on("change", () => {
                this.ngModelCtrl.$setViewValue(this.editor.getValue());
                this.ngModelCtrl.$commitViewValue();
            });

            //this.modeChanged(this.mode);
            setTimeout(() => this.editor.resize(true));
        }

        $onChanges(changes) {
            this.modeChanged(changes.mode.currentValue)
        }

        modeChanged(mode) {
            if (this.mode !== mode) this.mode = mode;

            switch (this.mode) {
                case "HTML":
                    this.editor.getSession().setMode('ace/mode/html');
                    break;
                case "CSS":
                    this.editor.getSession().setMode('ace/mode/css');
                    break;
                case "JSON":
                    this.editor.getSession().setMode('ace/mode/json');
                    this.editor.getSession().setTabSize(4);
                    this.editor.getSession().setUseWrapMode(true);

                    this.ngModelCtrl.$parsers = [value => this.parser(value)];
                    break;
                default:
                    this.editor.getSession().setMode('ace/mode/text');
            }
        }


        $onDestroy() {
            try {
                this.editor.destroy();
            } catch (e) {
            }
        }

        render() {
            if (!_.isNil(this.ngModelCtrl.$viewValue)) {
                if (this.mode === "JSON") {
                    this.editor.setValue(JSON.stringify(this.ngModelCtrl.$viewValue, null, '\t'));
                } else {
                    this.editor.setValue(this.ngModelCtrl.$viewValue, 0);
                }
            }
        }

        parser(value) {
            try {
                return JSON.parse(value)
            } catch (e) {
                return
            }
        }

        submit() {
            if (_.isFunction(this.submitFunc)) this.submitFunc();
        }
    }

    module.exports = {
        bindings: {
            mode: "<",
            submitFunc: "&?submit",
        },
        require: {
            ngModelCtrl: 'ngModel'
        },
        controllerAs: "vm",
        controller: AceController,
        template: '<div class="flex"></div>'
    };


})();
