/**
 * Created by BETALOS on 20/06/2017.
 */
(function () {

    'use strict';

    class ListDialogCtrl {
        constructor($mdDialog, listService) {
            this.dialog = $mdDialog;
            this.listService = listService;
        }

        static get $inject() {
            return ["$mdDialog", "listService"];
        }

        $onInit() {
            this.list = _.assign({}, this.list, {model_name: this.mnModel});
            this.isDynamic = this.isDynamic || false;
            this.options = this.options || {};
            this.options = _.assign(this.options, this.listService.postOnInit(this.mnModel, this.options));
        }

        cancel() {
            this.dialog.cancel();
        }

        submit() {
            this.promise = this.listService.handleList(this.list)
                .then(data => this.dialog.hide(data));
        }

        customListInit(){
            if(this.mnModel === "CustomList"){
                this.list.custom_slug=this.options.custom_slug;
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: ListDialogCtrl,
        template: require("../views/list.default.tpl.html"),
    };

})();
