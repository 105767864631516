  /**
 * Created by amine on 30/03/2017.
 */
(function () {
    'use strict';

    module.exports = mnSelectOnFocus;

    mnSelectOnFocus.$inject = ["$timeout"];

    function mnSelectOnFocus($timeout) {
        let isTrue = false;

        let directive = {
            restrict: 'A',
            priority: 0,
            link: {
                pre: mnSelectOnFocus
            }
        };

        function mnSelectOnFocus(scope, $element, attrs) {
            scope.$watch(attrs['mnSelectOnFocus'], handleFocus);

            function handleFocus(value) {
                isTrue = value;
            }

            $element.on("focus", function () {
                if (isTrue) {
                    $timeout(function () {
                        $element.select();
                    });
                }
            });

            $("input, textarea, select", $element).on("focus", function () {
                let $this = $(this);
                if (isTrue) {
                    $timeout(function () {
                        $this.select();
                    });
                }
            })
        };

        return directive;
    };

})()