/**
 * Created by BETALOS on 29/12/2016.
 */
(function () {
    'use strict';

    mnCheckBoxGroup.$inject = ["$timeout"];

    function mnCheckBoxGroup($timeout) {
        let directive = {
            restrict: 'E',
            link: link,
            scope: {
                change: "&ngChange",
                ngModel: "="
            },
            require: "ngModel",
            template: ""
        };


        function link(scope, $element, attrs, ngModelController) {
            $element.on("click", "button.md-button", select);

            if($element.is('[required]')) ngModelController.$validators.required = required;

            let modelValue = [];

            let killWatch = scope.$watch("ngModel", function (value) {
                if (_.isNil(value)) return;

                killWatch();
                modelValue = ngModelController.$modelValue
                $timeout(_.bind(modelChanged, null, value));
            });

            function select() {
                let $button = $(this);
                let value = $button.attr("value");

                if (_.includes(modelValue, value)) {
                    $(this).removeClass("active");
                    _.pull(modelValue, value);
                }

                else {
                    $(this).addClass("active");
                    modelValue = _.isEmpty(modelValue) ? _.castArray(value) : _(modelValue).concat(value).compact().value();
                }

                ngModelController.$setViewValue(modelValue);
                ngModelController.$commitViewValue();
                ngModelController.$validate();

                scope.change();
            }

            function required(modelValue) {
                return !_.isEmpty(modelValue);
            }

            function modelChanged(value) {
                $("button.md-button", $element).removeClass("active");

                _.forEach(value, function (item) {
                    $("button.md-button[value=" + item + "]", $element).addClass("active");
                })
            }

            scope.$on("destroy", function () {
                $("button.md-button", $element).off("click");
            });
        }

        return directive;
    }

    module.exports = mnCheckBoxGroup;

})();