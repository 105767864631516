/**
 * Created by amine on 22/02/2017.
 */

(function () {
    'use strict';

    class AccountBalanceCtrl {
        constructor(patientService) {
            this.patientService = patientService;

            this.account = null;
        }

        static get $inject() {
            return ['patientService'];
        }

        $onInit() {
            this.account.subscribe(account => {
                if (!_.isNil(account)) this.reload(account);
            })
        }

        reload(account) {
            this.patientService.getFormPatient(account)
                .then(data => {
                    this.handleAccountChange(_.get(data, 'financial_status', {}));
                });
        }

        handleAccountChange(financial_status) {
            this.balance = {
                value: _.get(financial_status, 'abs_account_balance', 0),
                sign: _.get(financial_status, 'account_balance_sign', 0)
            }

            this.organizations = {
                value: _.get(financial_status, 'abs_organizations_balance', 0)
            }

            this.patient = {
                value: _.get(financial_status, 'abs_patient_part_balance', 0),
                sign: _.get(financial_status, 'patient_part_balance_sign', 0)
            }
        }
    }

    module.exports = {
        controller: AccountBalanceCtrl,
        controllerAs: "vm",
        bindings: {
            account: "<account"
        },
        template: require('payment/views/account-balance.html'),
    };

})();
