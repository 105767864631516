(function () {

    'use strict';

    module.exports = MnRainbowIcon;

    MnRainbowIcon.$inject = ["$mdDialog", "$mdRainbow"];

    function MnRainbowIcon($mdDialog, $mdRainbow) {

        var directive = {
            restrict: 'A',
            priority: 0,
            ngModel: "=",
            link: linkFunc,
        };

        function linkFunc(scope, elem, attrs) {

            elem.bind('click', function (e) {
                scope.showColorPicker(e)
            });
            var didJustClose = false;
            var defaultOkText = 'Select';
            var defaultCancelText = 'Cancel';

            // Merge Options Object with scope.  Scope will take precedence much like css vs style attribute.
            if (scope.options !== undefined) {
                for (var opt in scope.options) {
                    if (scope.options.hasOwnProperty(opt)) {
                        var scopeKey;
                        //if ( scope.hasOwnProperty( opt ) ) { // Removing this because optional scope properties are not added to the scope.
                        scopeKey = opt;
                        //} else
                        if (scope.hasOwnProperty('mdColor' + opt.slice(0, 1).toUpperCase() + opt.slice(1))) {
                            scopeKey = 'mdColor' + opt.slice(0, 1).toUpperCase() + opt.slice(1);
                        }
                        if (scopeKey && (scope[scopeKey] === undefined || scope[scopeKey] === '')) {
                            scope[scopeKey] = scope.options[opt];
                        }
                    }
                }
            }

            var ngModel = elem.controller('ngModel');

            var updateValue = function (val) {
                scope.value = val || ngModel.$viewValue || '';
                scope.value = val || ngModel.$viewValue || '';
            };

            scope.defaultOkText = defaultOkText;
            scope.defaultCancelText = defaultCancelText;
            scope.okText = scope.okText === undefined ? defaultOkText : scope.okText;
            scope.cancelText = scope.cancelText === undefined ? defaultCancelText : scope.cancelText;
            scope.mdColorClearButton = scope.mdColorClearButton === undefined ? true : scope.mdColorClearButton;
            scope.mdColorPreview = scope.mdColorPreview === undefined ? true : scope.mdColorPreview;

            scope.mdColorAlphaChannel = scope.mdColorAlphaChannel === undefined ? true : scope.mdColorAlphaChannel;
            scope.mdColorSpectrum = scope.mdColorSpectrum === undefined ? true : scope.mdColorSpectrum;
            scope.mdColorSliders = scope.mdColorSliders === undefined ? true : scope.mdColorSliders;
            scope.mdColorGenericPalette = scope.mdColorGenericPalette === undefined ? true : scope.mdColorGenericPalette;
            scope.mdColorMaterialPalette = scope.mdColorMaterialPalette === undefined ? true : scope.mdColorMaterialPalette;
            scope.mdColorHistory = scope.mdColorHistory === undefined ? true : scope.mdColorHistory;
            scope.mdColorHex = scope.mdColorHex === undefined ? true : scope.mdColorHex;
            scope.mdColorRgb = scope.mdColorRgb === undefined ? true : scope.mdColorRgb;
            scope.mdColorHsl = scope.mdColorHsl === undefined ? true : scope.mdColorHsl;

            updateValue();
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newVal) {
                updateValue(newVal);
            });

            scope.$watch('value', function (newVal, oldVal) {
                if (newVal !== '' && typeof newVal !== 'undefined' && newVal && newVal !== oldVal) {
                    ngModel.$setViewValue(newVal);
                }
            });

            scope.clearValue = function clearValue() {
                ngModel.$setViewValue('');
                ngModel.$render();
            };
            scope.showColorPicker = function showColorPicker($event) {
                if (didJustClose) {
                    return;
                }
                $mdRainbow.show({
                    value: scope.value,
                    defaultValue: scope.default,
                    random: scope.random,
                    clickOutsideToClose: scope.clickOutsideToClose,
                    hasBackdrop: scope.hasBackdrop,
                    skipHide: scope.skipHide,
                    preserveScope: scope.preserveScope,
                    multiple: scope.multiple,

                    okText: scope.okText,
                    cancelText: scope.cancelText,
                    mdColorAlphaChannel: scope.mdColorAlphaChannel,
                    mdColorSpectrum: scope.mdColorSpectrum,
                    mdColorSliders: scope.mdColorSliders,
                    mdColorGenericPalette: scope.mdColorGenericPalette,
                    mdColorMaterialPalette: scope.mdColorMaterialPalette,
                    mdColorHistory: scope.mdColorHistory,
                    mdColorHex: scope.mdColorHex,
                    mdColorRgb: scope.mdColorRgb,
                    mdColorHsl: scope.mdColorHsl,
                    mdColorDefaultTab: scope.mdColorDefaultTab,

                    $event: $event,

                }).then(function (color) {
                    scope.value = color;
                });
            };
        };

        return directive;
    };
})()