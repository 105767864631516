
(function () {
    "use strict";

    var dialog = {
        controller: DeviceDialogCtrl,
        controllerAs: "vm",
        template: require("rehabilitation/views/device-dialog.tpl.html"),
        parent: $(document.body),
        clickOutsideToClose: true,
        bindToController: true,
        multiple: true,
        focusOnOpen: false
    };

    module.exports = dialog;

    DeviceDialogCtrl.$inject = ["$mdDialog", "$scope", "rehabilitationService"];

    function DeviceDialogCtrl($mdDialog, $scope, rehabilitationService) {
        var vm = this;
        vm.submit = submit;
        vm.cancel = cancel;
        init();

        function init() {

        }



        function submit() {
            rehabilitationService
                .saveDevice(vm.device, vm.type)
                .then(success);
            function success(data) {
                vm.device = data;
                $mdDialog.hide(data);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }


})();
