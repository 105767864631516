(function () {

    'use strict';

    module.exports = {
        controller: ModelSearchCtrl,
        controllerAs: "vm",
        bindings: {
            items: "=",
            initItems: "<",
            ngShow: "&",
            searchBy: '@',
            searchFunc: '<?',
            isSearching: "&"
        },
        template: tpl
    };

    ModelSearchCtrl.$inject = ["$scope"];

    function ModelSearchCtrl($scope) {
        let vm = this;


        vm.$onInit = init;
        vm.cancel = cancel;
        vm.showInput = showInput;


        function init() {
            vm.key = "";
            vm.shown = false;
            vm.isSearching = _.isNil(vm.isSearching) ? _.noop : vm.isSearching;
            vm.handleSearch = _.isNil(vm.searchFunc) ? handleSearch : vm.searchFunc;

            $scope.$watch('vm.initItems', handleItems, true);

            function handleItems() {
                if (vm.ngShow) handleSearch();
                else cancel();
            }
        }

        function showInput() {
            vm.shown = true;
        }

        function cancel() {
            vm.key = "";
            vm.shown = false;

            vm.items = vm.initItems;
            vm.isSearching({$event: false});
        }

        function handleSearch() {
            if (vm.key.length == 0) vm.items = vm.initItems;
            else {
                let key = _.chain(vm.key).toLower().deburr().value();
                vm.items = _.reduce(vm.initItems, (items, item) => {
                    let title = _.chain(item).get(vm.searchBy).toLower().deburr().value();

                    if (_.includes(title, key)) return _.concat(items, item);
                    else return items;
                }, []);
            }

            vm.isSearching({$event: vm.key.length > 0});
        }

    }


    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        return [
            '<md-button class="md-icon-button" ng-click="vm.showInput()" aria-label="cancel" tabindex="-1">',
            '   <md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>',
            '</md-button>',
            '<div class="search-element" ng-show="vm.shown">',
            '   <input type="search" translate-once-placeholder="search" ng-model="vm.key" ng-model-options="{debounce : 400}" mn-auto-focus="vm.shown" ng-change="vm.handleSearch(vm.key)" />',
            '   <md-button class="md-icon-button cancel-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">',
            '       <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '   </md-button>',
            '</div>',

        ].join('');
    }

})();