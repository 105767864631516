/**
 * Created by BETALOS on 30/09/2016.
 */
(function () {
    "use strict";

    class AgeDirective {
        constructor(system, $translate, moment) {
            this.priority = 0;
            this.restrict = "A";
            this.require = "ngModel";
            this.scope = {
                birthDate: "=mnAge", ngModel: "="
            };

            this.moment = moment;
            this.dateFormat = system['date_format'].js;
            this.monthLabel = _.toLower($translate.instant('month'));
        }

        static create() {
            return new AgeDirective(...arguments);
        }

        compile(element, attrs) {
            element.attr('min', 0);
            return this.link;
        }

        link(scope, element, attrs, ngModelController) {
            let $parent = element.parents('md-input-container');
            let $labelElem = $("label", $parent);

            scope.isMonth = false;
            scope.label = $labelElem.text();
            scope.monthLabel = `${scope.label} (${this.monthLabel})`;

            ngModelController.$render = () => this.render(scope, ngModelController, element);

            scope.$watch('birthDate', value => this.birthDateChange(value, scope, ngModelController));
            scope.$watch('ngModel', (value, oldValue) => this.ageChanged(value, oldValue, scope, ngModelController));
        }

        //
        birthDateChange(value, scope, ctrl) {
            let oldValue = ctrl.$viewValue;
            let age = _.isNil(value) ? null : this.moment().diff(this.moment(value, this.dateFormat), 'years');

            if (age === 0) {
                scope.isMonth = true;
                age = this.moment().diff(this.moment(value, this.dateFormat), 'month');
            } else scope.isMonth = false;

            ctrl.$viewValue = age;
            ctrl.$render();

            if (!_.isNil(oldValue)) ctrl.$commitViewValue();
        }

        ageChanged(value, oldValue, scope, ctrl) {
            if (value === oldValue) return false;
            else if (value < 0) {
                ctrl.$viewValue = 1;
                ctrl.$render();
                ctrl.$commitViewValue();
            } else if (!_.isNil(value) && value !== this.moment().diff(this.moment(scope.birthDate, this.dateFormat), scope.isMonth ? 'month' : 'years')) {
                scope.birthDate = this.moment().subtract(value, 'years').date(1).month(0).format(this.dateFormat);
            }
        }

        //
        render(scope, ctrl, element) {
            let $parent = element.parents('md-input-container');
            let $labelElem = $("label", $parent);

            this.handleLabel(scope, $labelElem);

            if (_.isNil(ctrl.$viewValue)) {
                $parent.removeClass('md-input-has-value');
                element.val("");
            } else {
                element.val(ctrl.$viewValue);
                $parent.addClass('md-input-has-value');
            }
        }

        handleLabel(scope, $labelElem) {
            if (_.isEmpty(scope.label)) {
                scope.label = $labelElem.text();
                scope.monthLabel = `${scope.label} (${this.monthLabel})`;
            }

            if (scope.isMonth) $labelElem.text(scope.monthLabel);
            else $labelElem.text(scope.label);
        }
    }

    AgeDirective.create.$inject = ["system", "$translate", "moment"];

    module.exports = AgeDirective.create;

})();
