/**
 * Created by Issmahane on 27/12/2016.
 * Edited by Amine on 13/10/2021.
 */
(function () {

    'use strict';

    const PROCEDURE_DIALOG = require("shared/dialogs/procedure-search-dialog");
    const MC_PROCEDURE_DIALOG = require("medical-care/dialogs/medical-care-procedure-search-dialog");

    class SearchProcedureCtrl {
        constructor($q, $mdDialog) {
            this.q = $q;
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$q", "$mdDialog"];
        }

        $onInit() {
            this.disabled = !!this.disabled;
            this.medicalCare = !!this.medicalCare;
            this.callback = _.isFunction(this.callback) ? this.callback : _.noop;
        }

        show($event) {
            let dialog = PROCEDURE_DIALOG;

            if (this.medicalCare) {
                dialog = MC_PROCEDURE_DIALOG;
            }
            this.dialog.show(_.assign({}, dialog, {targetEvent: $event}))
                .then(data => this.callback({data}), _.noop);
        }

    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        let icon = _.isNil(attrs['icon']) ? "mdi-plus" : attrs['icon'];
        let classes = _.isNil(attrs['classes']) ? '' : attrs['classes'];
        let isNotFirst = elem.is('[is-not-first]') ? 'not-first-button' : '';
        let label = _.isNil(attrs['label']) ? "procedure_search" : attrs['label'];
        let disabled = _.isNil(attrs['ngDisabled']) ? '' : " ng-disabled=\"vm.disabled\" ";

        if (elem.is("[mn-disabled]") || elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");
        if (elem.is("[mn-callback]")) console.error("{ mn-callback } are removed use { callback }");

        return [
            '<md-button ' + disabled + ' class="md-raised mn-button-icon ' + isNotFirst + classes + ' not-last-button" ng-click="vm.show($event)" aria-label="find model">',
            '   <md-icon md-font-icon="' + icon + '" md-font-set="mdi"></md-icon>',
            '   <span translate-once="' + label + '"></span>',
            '</md-button>'
        ].join('');
    }

    module.exports = {
        bindings: {
            callback: "&",
            disabled: "<?ngDisabled",
            medicalCare: "<?",
        },
        template: tpl,
        controllerAs: "vm",
        controller: SearchProcedureCtrl,
    };

})();