(function () {

    'use strict';

    module.exports = rehabilitationService;

    rehabilitationService.$inject = ["$q", "mnWebSocket", "$http"];

    function rehabilitationService($q, mnWebSocket, $http) {
        let self = this;

        self.saveDevice = saveDevice;
        self.getDevices = getDevices;
        self.deleteDevice = deleteDevice;

        function saveDevice(device, type) {
            var deferred = $q.defer();
            var url = `/api/${type == 'monitoring' ? 'monitoring-device' : 'training-device'}/${!_.isNil(device.id) ? device.id + '/' : '' }`;

            $http.post(url, device)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }

        function getDevices(res,pk=null) {
            var deferred = $q.defer();
            var url = `/api/${res}/${!_.isNil(pk) ? pk + '/' : '' }`;

            $http.get(url)
                .then(successCallback, errorCallback);

            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }
        function deleteDevice(res,pk) {
            var deferred = $q.defer();
            var url = `/api/${res}/${pk}/`;
            $http.delete(url)
                .then(successCallback, errorCallback);
            function successCallback(response) {
                deferred.resolve(response.data);
            }

            function errorCallback(err) {
                deferred.reject(err);
            }

            return deferred.promise;
        }


    }

})();
