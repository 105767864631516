(function () {
    'use strict';

    const TEXT_PRESCRIPTION_ITEM = require('./text-prescription-item.tpl.html');
    const MEDICAL_PRESCRIPTION_ITEM = require('./medical-prescription-item.tpl.html');
    const BIOLOGY_PRESCRIPTION_ITEM = require('./biology-prescription-item.tpl.html');
    const PROCEDURE_PRESCRIPTION_ITEM = require('./procedure-prescription-item.tpl.html');
    const CARE_PRESCRIPTION_ITEM = require('./care-prescription-item.tpl.html');

    let mnPrescriptionItem = {
        controller: mnPrescriptionController,
        controllerAs: "vm",
        bindings: {
            item: '<mnItem',
            type: "@mnType"
        },
        template: ""
    };

    mnPrescriptionController.$inject = ["prescriptionService", "$element", "$compile", "$scope", "$timeout", "$sce"];

    function mnPrescriptionController(prescriptionService, $element, $compile, $scope, $timeout, $sce) {
        let vm = this;
        let downloaded = false;

        vm.$postLink = postLink;
        vm.$onInit = init;
        vm.duplicateItem = duplicateItem;

        vm.editItem = (item, reload = true) => prescriptionService.editState(item, reload);
        vm.deleteItem = (item, ev) => prescriptionService.deleteItem(item, ev);
        vm.constructedTitle = item => prescriptionService.constructedTitle(item);

        // vm.printItem = prescriptionService.printItem;

        function init() {
            if ($element.is('[mn-unique')) {
                $timeout(function () {
                    $element
                        .addClass("layout-column")
                        .addClass("flex");

                    $("md-card, md-card > div:first", $element).addClass("flex");
                })
            }

            $scope.$watch('vm.item', function (value) {
                if (_.isNil(value)) return;

                if (vm.item.prescription_type === "TextPrescription")
                    vm.item.trustedContent = $sce.trustAsHtml(vm.item.content);
                if (vm.item['is_legacy'] && vm.item.prescription_type === "BiologyPrescription")
                    biologyLegacy()

                vm.printLink = {
                    group: prescriptionService.printGroup(value),
                    model: value.id
                }
            });
        }

        function postLink() {
            let tpl = viewPath();

            if ($element.is("[inner]")) {
                $element.html(tpl);
            } else {
                let mainTpl = require('./prescription-item.tpl.html');

                $element.html(mainTpl);
                $element.find("md-card-content").replaceWith(tpl);
            }
            $compile($element.contents())($scope);
        }

        function biologyLegacy() {
            if (downloaded) return false;

            downloaded = true;
            prescriptionService.getLegacyBiologyPrescription(vm.item.id).then(biologyLegacySuccess)

            function biologyLegacySuccess(data) {
                vm.item = data;
            }
        }

        function viewPath() {
            switch (_.chain(vm.item).get('prescription_type', vm.type).kebabCase().value()) {
                case 'text-prescription':
                    return TEXT_PRESCRIPTION_ITEM;
                case 'medical-prescription':
                    return MEDICAL_PRESCRIPTION_ITEM;
                case 'biology-prescription':
                    return BIOLOGY_PRESCRIPTION_ITEM;
                case 'procedure-prescription':
                    return PROCEDURE_PRESCRIPTION_ITEM;
                case 'care-prescription':
                    return CARE_PRESCRIPTION_ITEM;
            }
        }

        function duplicateItem() {
            prescriptionService.duplicateState(vm.item.id, vm.item['prescription_type'], false);
        }

    }

    module.exports = mnPrescriptionItem;
})();