/**
 * Created by amine on 27/07/2016.
 */

(function () {
    'use strict';

    class AccountDetailCtrl {
        constructor($mdDialog, $translate) {
            this.dialog = $mdDialog;
            this.$translate = $translate;
        }

        static get $inject() {
            return ["$mdDialog", "$translate"];
        }

        $onInit() {
            this.item = this.item || null;
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        controller: AccountDetailCtrl,
        template: require("payment/views/account-details-dialog.tpl.html"),
        controllerAs: "vm",
        bindToController: true,
        multiple: true,
        parent: $(document.body),
        clickOutsideToClose: true,
    };
})();
