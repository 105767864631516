(function () {
    'use strict';

    const VOICE_RECOGNITION_DIALOG = require('../dialogs/voice-recognition-dialog');

    class VoiceRecognitionCtrl {
        constructor($mdDialog, $element) {
            if (!('webkitSpeechRecognition' in window)) $element.remove();
            else this.dialog = $mdDialog;

            this.ngModelController = this.ngModelController || null;
        }

        static get $inject() {
            return ["$mdDialog", "$element"];
        }

        record(ev) {
            this.dialog.show(_.assign({}, VOICE_RECOGNITION_DIALOG, {
                targetEvent: ev,
                locals: {
                    value: this.ngModelController.$modelValue
                }
            })).then(data => this.success(data));
        }

        success(data) {
            this.ngModelController.$setViewValue(data);
            this.ngModelController.$commitViewValue();
        }

    }


    template.$inject = ['$element'];

    function template($element) {
        const disabled = $element.is('[ng-disabled]') ? 'ng-disabled="vm.disabled"' : '';

        return `
            <md-button tabindex="-1" class="md-icon-button" ng-click="vm.record($event)" aria-label="record" ${disabled}>
               <md-icon md-font-icon="mdi-microphone" md-font-set="mdi"></md-icon>
            </md-button>
        `;
    }


    module.exports = {
        template: template,
        controllerAs: "vm",
        bindings: {
            disabled: '=?ngDisabled',
        },
        require: {
            ngModelController: "ngModel",
        },
        controller: VoiceRecognitionCtrl,
    };

})();