/**
 * Created by amine on 03/09/2021.
 */
(function () {

    'use strict';

    const EXT_PATIENT_LIST_DIALOG = require('patient/dialogs/ext-api-search-dialog');

    class ExternalApiSearchCtrl {
        constructor($element, $mdDialog) {
            this.element = $element;
            this.dialog = $mdDialog;

            this.choose = this.choose ? this.choose : false;
            this.remove = this.remove ? this.remove : _.noop;
            this.query = this.query ? this.query : _.noop;
            this.callback = this.callback ? this.callback : _.noop;
            this.selectOnly = this.selectOnly ? this.selectOnly : false;
            this.multiple = this.multiple || false;
        }

        static get $inject() {
            return ["$element", "$mdDialog"];
        }

        $onInit() {
            this.element.parent().addClass("layout-align-start-center");
        }

        show(event) {
            this.dialog.show(
                _.assign({}, EXT_PATIENT_LIST_DIALOG, {
                    targetEvent: event,
                    locals: {}
                })
            ).then(data => {
                if (this.multiple) {
                    this.callback({patients: data});
                } else {
                    this.choose = true;
                    this.callback({patient: data});
                }
            }, _.noop);
        }

        cancel() {
            this.remove();
            this.choose = false;
        }

        // modalCallBack(msg) {
        //     if (msg.subject === this.subject) {
        //         if (this.multiple) {
        //             this.callback({patients: msg.data});
        //         } else {
        //             this.choose = true;
        //             this.callback({patient: msg.data});
        //         }
        //     }
        // }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl(elem, attrs) {
        let disabled = attrs['disabled'] ? ' ng-disabled="vm.disabled" ' : "";

        elem.addClass("layout-row");
        return `
            <div class="mn-opts" flex="nogrow">
                <md-button class="md-raised md-primary" ng-click="vm.show($event)" aria-label="find model" ${disabled}>
                    <md-icon md-font-icon="mdi-cloud-search" md-font-set="mdi"></md-icon>
                </md-button>
            </div>
        `;
    }

    module.exports = {
        bindings: {
            remove: '&?',
            callback: '&',
            query: '=?',
            selectOnly: '=?',
            disabled: "<?",
            multiple: "<?"
        },
        template: tpl, // or template
        controllerAs: "vm",
        controller: ExternalApiSearchCtrl,
    };

})();