/**
 * Created by BETALOS on 24/04/2017.
 */
(function () {

    'use strict';

    const Croppr = require('croppr');
    const WEB_CAM_SELECTED = 'web-cam-selected';
    const {ImageCapture} = require('image-capture');

    module.exports = {
        locals: {},
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: WebCamDialogCtrl,
        template: require('shared/views/web-cam-dialog.tpl.html'),
    };

    WebCamDialogCtrl.$inject = [
        "$mdDialog", "$element", "$scope", "$window", "$timeout", "$translate", "$mdConstant", "system", "moment",
        "$q"
    ];

    function WebCamDialogCtrl(
        $mdDialog, $element, $scope, $window, $timeout, $translate, $mdConstant, system, moment, $q
    ) {
        let vm = this;

        const dateTimeFormat = 'DD-MM-YYYY HH:mm'; //system['datetime_format'].js;

        let $video = $('video', $element);
        let $img = $('.flux-controller img', $element);

        let img = $img.get(0);
        let video = $video.get(0);

        let currentIndex = -1;
        let lastCamSelected = localStorage.getItem(WEB_CAM_SELECTED);

        let constraints = {
            audio: false,
            video: {
                aspectRatio: 4 / 3,
                width: {ideal: 2048}
            }
        };

        let enter = $mdConstant.KEY_CODE.ENTER;
        let space = $mdConstant.KEY_CODE.SPACE;
        let up = $mdConstant.KEY_CODE.UP_ARROW;
        let left = $mdConstant.KEY_CODE.LEFT_ARROW;
        let remove = $mdConstant.KEY_CODE.DELETE;
        let down = $mdConstant.KEY_CODE.DOWN_ARROW;
        let right = $mdConstant.KEY_CODE.RIGHT_ARROW;

        let stream = null;
        let imageCapture = null;
        let mediaStreamTrack = null;

        vm.error = false;
        vm.success = false;
        vm.cropper = false;
        vm.showPreview = false;

        vm.$onInit = init;

        vm.cancel = $mdDialog.cancel;

        vm.quitPreview = quitPreview;

        vm.switchCamera = switchCamera;
        vm.selectDevice = selectDevice;

        vm.swipeLeft = swipeLeft;
        vm.swipeRight = swipeRight;

        vm.setImage = setImage;
        vm.savePicture = saveImage;
        vm.removeImage = removeImage;

        vm.capture = captureImage;
        vm.cropImage = cropImage;

        function init() {
            vm.item = null;
            vm.items = [];
            vm.previews = [];

            $scope.$on('$destroy', onDestroy);
            $element.on('keydown', handleKeys);

            const getUserMedia = _.get($window, 'navigator.mediaDevices.getUserMedia', null);
            const getUserDevices = _.get($window, 'navigator.mediaDevices.enumerateDevices', null);

            if (!_.isNull(getUserDevices) && !_.isNil(getUserMedia)) {
                vm.promise = $window.navigator['mediaDevices']['enumerateDevices']()
                    .then(devices => {
                        vm.videoDevices = _.filter(devices, d => d.kind === 'videoinput');
                        $scope.$applyAsync();

                        if (vm.videoDevices.length === 0) error();
                        else if (lastCamSelected) {
                            let device = _.find(vm.videoDevices, ['deviceId', lastCamSelected]);

                            if (device) vm.promise = vm.selectDevice(device).then(null, () => error());
                            else vm.promise = vm.selectDevice(vm.videoDevices[0]).then(null, () => error());
                        } else vm.promise = vm.selectDevice(vm.videoDevices[0]).then(null, () => error());
                    });
            } else error();

            function onDestroy() {
                $element.off('keydown', handleKeys);
                vm.previews.forEach(value => URL.revokeObjectURL(value));

                if (vm.cropper) vm.cropper.destroy();
                if (stream) stream.getTracks().forEach(track => track.stop());
            }

            function handleKeys(ev) {
                if (_.includes([remove, enter, space, up, down, left, right], ev.keyCode)) {
                    ev.preventDefault();
                    ev.stopPropagation();
                    ev.stopImmediatePropagation();
                }

                if (_.includes([enter, space], ev.keyCode)) setTimeout(captureImage);
                if (ev.keyCode === remove && vm.items.length > 0) removeImage(vm.items.length - 1);
                if (_.includes([up, left], ev.keyCode) && currentIndex > 0 && vm.showPreview) {
                    setImage(currentIndex - 1);
                }
                if (_.includes([down, right], ev.keyCode) && currentIndex < vm.items.length - 1 && vm.showPreview) {
                    setImage(currentIndex + 1);
                }
            }
        }

        function swipeLeft() {
            if (currentIndex < vm.items.length - 1 && vm.showPreview) {
                setImage(currentIndex + 1);
            }
        }

        function swipeRight() {
            if (currentIndex > 0 && vm.showPreview) {
                setImage(currentIndex - 1);
            }
        }

        function quitPreview() {
            if (!vm.multi) vm.item = null;
            if (vm.cropper) vm.cropper.destroy();

            vm.cropper = false;
            vm.showPreview = false;
        }

        function error() {
            vm.error = true;
        }

        function switchCamera() {
            const currentIndex = _.indexOf(vm.videoDevices, vm.currentDevice);

            if ((currentIndex + 1) === vm.videoDevices.length) return selectDevice(vm.videoDevices[0]);
            else return selectDevice(vm.videoDevices[currentIndex + 1]);
        }

        function selectDevice(device) {
            const deferred = $q.defer();

            if (stream) stream.getTracks().forEach(track => track.stop());

            vm.currentDevice = device;
            localStorage.setItem(WEB_CAM_SELECTED, vm.currentDevice.deviceId);

            constraints.video = _.assign(constraints.video, {deviceId: {exact: _.get(device, 'deviceId')}});

            $window.navigator['mediaDevices']['getUserMedia'](constraints)
                .then(handleStream, (err) => {
                    error();
                    deferred.reject(err);
                });

            function handleStream(stm) {
                vm.success = true;
                stream = stm;

                mediaStreamTrack = stream.getVideoTracks()[0];
                imageCapture = new ImageCapture(mediaStreamTrack);

                $video.on('error', () => deferred.reject());
                $video.on('loadeddata', () => deferred.resolve(true));

                if ("srcObject" in video) video.srcObject = stream;
                else video.src = URL.createObjectURL(stream);

                $scope.$applyAsync();
            }

            return deferred.promise;
        }

        function captureImage() {
            const deferred = $q.defer();
            const settings = mediaStreamTrack.getSettings();

            imageCapture.takePhoto({
                imageWidth: settings.width, imageHeight: settings.height
            }).then(success, deferred.reject);

            function success(blob) {
                const objectUrl = URL.createObjectURL(blob);

                if (vm.multi) {
                    vm.items.push(blob);
                    vm.previews.push(objectUrl);
                    currentIndex = vm.items.length - 1;
                } else {
                    vm.item = blob;
                    vm.showPreview = true;
                }

                if (vm.cropper) vm.cropper.setImage(objectUrl);
                else img.src = objectUrl;

                $scope.$applyAsync();
                deferred.resolve(true);
            }

            return deferred.promise;
        }

        function setImage(index) {
            vm.showPreview = true;

            currentIndex = index;
            img.src = vm.previews[index];
        }

        function removeImage(index) {
            vm.items.splice(index, 1);
            vm.previews.splice(index, 1);
        }

        function saveImage() {
            if (vm.multi) {
                const items = _.map(vm.items, (item, i) => mapFunc(item, i));
                $mdDialog.hide(items);
            } else if (vm.cropper) {
                vm.promise = getImagePortion()
                    .then(blob => handleOneItem(blob))
            } else handleOneItem(vm.item);
        }

        function handleOneItem(blob) {
            const item = new File([blob], getPictureTitle(0), {type: 'image/png'});
            $mdDialog.hide(item);
        }

        function mapFunc(item, i) {
            return new File([item], getPictureTitle(i), {type: 'image/png'});
        }

        function getPictureTitle(index) {
            return $translate['instant']('file_manager_new_capture', {
                index: index + 1,
                date: moment().format(dateTimeFormat)
            });
        }

        function cropImage() {
            if (!vm.cropper) vm.cropper = new Croppr(img, _.assign({
                returnMode: 'real',
                startSize: [90, 90],
            }, vm.noAspect ? {} : {aspectRatio: 1}));
        }

        function getImagePortion() {
            const box = vm.cropper.getValue();

            const width = box.width;
            const height = box.height;
            const canvas = document.createElement('canvas');
            const canvasContext = canvas.getContext('2d');

            canvas.width = width;
            canvas.height = height;

            canvasContext.drawImage(vm.cropper['imageEl'], box.x, box.y, width, height, 0, 0, width, height);

            return new Promise(resolve => canvas.toBlob(resolve));
        }

    }

})();
