/**
 * Created by Amine on 30/08/2017.
 */
(function () {

    'use strict';

    class DefaultTemplateCtrl {
        constructor(printService, $mdToast, $translate) {
            this.toast = $mdToast;
            this.service = printService;

            this.successToast = $mdToast.simple()
                .textContent($translate.instant('default_template_saved'))
                .position("bottom left")
                .hideDelay(1000);
        }

        static get $inject() {
            return ["printService", "$mdToast", "$translate"];
        }

        $onInit() {
            let defaultTemplate = _.get(this.service.staffDefaults, `${this.group.id}.template`, null);
            this.value = _.isNil(defaultTemplate) ? null : defaultTemplate.uid;
        }

        valueChanged(value) {
            this.service.saveDefaults(this.group, value)
                .then(() => this.toast.show(this.successToast));
        }

        cancel() {
            this.value = null;
        }
    }

    function tpl() {
        return [
            '<md-input-container style="min-width: unset">',
            '   <label>{{ vm.group.name }}</label>',
            '   <md-select ng-model="vm.value" ng-change="vm.valueChanged(vm.value)" style="min-width: unset">',
            '       <md-option ng-value="null">&nbsp;</md-option>',
            '       <md-option ng-repeat="template in vm.group.templates track by template.uid" ng-value="template.uid">',
            '           {{ template.name }}',
            '       </md-option>',
            '   </md-select>',
            '   <div class="mn-option-buttons flex-nogrow layout-row">',
            '       <md-button ng-if="vm.value && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">',
            '           <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>',
            '       </md-button>',
            '   </div>',
            '</md-input-container>'
        ].join("");
    }

    module.exports = {
        template: tpl,
        bindings: {
            group: "<",
        },
        controllerAs: "vm",
        controller: DefaultTemplateCtrl,
    };

})();