/**
 * Created by BETALOS on 03/03/2017.
 */
(function () {

    'use strict';

    const WEB_CAM_DIALOG = require('shared/dialogs/web-cam-dialog');

    class WebCamCtrl {
        constructor($mdDialog) {
            this.dialog = $mdDialog;
        }

        static get $inject() {
            return ["$mdDialog"];
        }

        capture(ev) {
            ev.stopPropagation();

            this.dialog.show(_.assign({}, WEB_CAM_DIALOG, {
                targetEvent: ev,
                locals: {
                    multi: false
                }
            })).then(file => this.success(file));
        }

        success(file) {
            this.ngModelController.$setViewValue(file);
            this.ngModelController.$commitViewValue();
            this.ngModelController.$render();
        }

    }

    function tpl() {
        return [
            '<md-button class="md-icon-button" ng-click="vm.capture($event)" aria-label="capture">',
            '   <md-icon md-font-icon="mdi-camera" md-font-set="mdi"></md-icon>',
            '</md-button>',
        ].join('');
    }

    module.exports = {
        require: {
            ngModelController: "ngModel"
        },
        template: tpl,
        controllerAs: "vm",
        controller: WebCamCtrl,
    };

})();
