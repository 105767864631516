/**
 * Created by amine on 14/11/2017.
 */
(function () {

    'use strict';

    let mnAutocompleteList = {
        controller: MnAutocompleteListController,
        controllerAs: "vm",
        require: {ngModelController: "ngModel"},
        bindings: {
            list: "=ngModel",
            change: "&ngChange",
            disabled: "<?ngDisabled",
            fill: "<?fill",
            locked: "<?lockAdd",
            selectAll: "<?selectOnFocus",
            tplParams: "<?tplParams"
        },
        template: tpl,
    };

    MnAutocompleteListController.$inject = ["listService", "$mdDialog", "$attrs", "$timeout", "$element"];

    function MnAutocompleteListController(listService, $mdDialog, attrs, $timeout, $element) {
        let vm = this;

        let model = attrs['mnModel'];
        let label = attrs['mnLabel'];
        let tpl = _.isString(attrs['mnTpl']) ? attrs['mnTpl'] : "default";

        vm.$onInit = init;

        vm.add = add;
        vm.cancel = cancel;
        vm.changeValue = changeValue;
        vm.querySearch = querySearch;

        function init() {
            vm.disabled = _.isNil(vm.disabled) ? $element.is('[disabled]') : vm.disabled;
            vm.fill = _.isNil(vm.fill) ? false : vm.fill;
            vm.locked = _.isNil(vm.locked) ? false : vm.locked;
            vm.minLength = _.isNil(vm.minLength) ? 0 : vm.minLength;
            vm.selectAll = _.isNil(vm.selectAll) ? true : false;
            vm.tplParams = _.isNil(vm.tplParams) ? {} : vm.tplParams;

            vm.items = [];
            vm.searchText = null;

            vm.ngModelController.$formatters.push(formatter);

            function formatter(valueFromModel) {
                if (_.has(valueFromModel, "id") && !_.has(valueFromModel, "value"))
                    listService.get(valueFromModel)
                        .then(success);

                function success(data) {
                    vm.list = data;

                    //vm.ngModelController.$setViewValue(value);
                    //vm.ngModelController.$commitViewValue();
                    vm.ngModelController.$render();
                }

                return valueFromModel;
            }

            // $("md-autocomplete", $element).on("focus", function () {
            //     console.log(11);
            // })
            //selectAll

        }


        function querySearch(query) {
            return listService.autocompleteListSearch(model, query, vm.fill);
        }

        function changeValue() {
            $timeout(vm.change, 100);
        }

        function cancel() {
            vm.list = null;
        }

        function add(ev) {
            $mdDialog.show(_.assign(require('../dialogs/list-dialog'), {
                template: require("shared/views/list." + tpl + ".tpl.html"),
                targetEvent: ev,
                locals: {
                    mnLabel: label,
                    mnModel: attrs['mnModel'],
                    tplParams: vm.tplParams
                },
            })).then(done);

            function done(data) {
                vm.items.push(data);
                vm.list = data;
            }
        }

    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl($elem, attrs, $translate) {
        const required = $elem.is("[required]") ? " required " : "";
        const disabled = !_.isUndefined(attrs['ngDisabled']) ? ' ng-disabled="vm.disabled" ' : "";
        const is_disabled = $elem.is('[disabled]') ? " disabled " : "";

        const change = $elem.is('[ng-change]') ? ' md-selected-item-change="vm.changeValue() "' : "";
        const label = $translate['instant'](attrs['mnLabel']);
        const tooltip = _.isNil(attrs['tooltip']) ? label : $translate['instant'](attrs['tooltip']);

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-autocomplete flex 
                            md-delay="400"
                            md-no-cache="true"
                            md-selected-item="vm.list"
                            md-search-text="vm.searchText"
                            md-items="item in vm.querySearch(vm.searchText)"
                            md-item-text="item.value"
                            md-require-match="true"
                            md-min-length="vm.minLength"
                            ${change}
                            ${required}
                            ${disabled}
                            ${is_disabled}
                            md-floating-label="${label}">
                <md-item-template>
                    <span md-highlight-text="vm.searchText">{{item.value}}</span>
                </md-item-template>
            </md-autocomplete>
            <md-tooltip md-direction="bottom" md-delay="150">
                <span>${tooltip}</span>
            </md-tooltip>
            <div class="mn-option-buttons flex-nogrow layout-row" ng-if="!vm.disabled">
                <md-button ng-if="!vm.locked" class="md-icon-button" ng-click="vm.add($event)" aria-label="add" tabindex="-1">
                    <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                </md-button>
                <md-button ng-if="vm.list && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                    <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
            </div>
        `;
    }

    module.exports = mnAutocompleteList;

})();
