/**
 * Created by BETALOS on 19/08/2016.
 */
(function () {

    'use strict';

    const VISUALIZE_FILE = require('stand-alone/file-manager/dialogs/visualize-file-dialog');

    class SideNavCtrl {
        constructor(
            patientService, measureService, $q, $element, $mdDialog, configService, $translate, frontDeskService,
            uploadService, mnWebSocket, $auth, moment, $state, $scope, system
        ) {
            this.$q = $q;
            this.$auth = $auth;
            this.$scope = $scope;
            this.moment = moment;
            this.dialog = $mdDialog;
            this.$element = $element;
            this.$translate = $translate;
            this.mnWebSocket = mnWebSocket;
            this.configService = configService;
            this.patientService = patientService;
            this.measureService = measureService;
            this.frontDeskService = frontDeskService;
            this.uploadService = uploadService;

            this.summarySubscription = null;
            this.measureSubscription = null;
            this.sideNaveSubscription = null;
            this.dateFormat = system['date_format'].js;

            this.patientBlock = false;
            this.currentPatient = null;
            this.isDental = this.configService.isDental();
            this.callbacks = {
                patientFile: () => $state.go("app.patient-form", {'patient_id': this.currentPatient})
            }
        }

        static get $inject() {
            return [
                'patientService', 'measureService', '$q', '$element', '$mdDialog', 'configService', '$translate',
                'frontDeskService', 'uploadService', 'mnWebSocket', '$auth', 'moment', '$state', '$scope', 'system'
            ];
        }

        $onInit() {
            this.sideNaveSubscription = this.configService.sideNaveSubject
                .subscribe(data => {
                    if (!data) return;

                    switch (data.subject) {
                        case 'activePatientAdded':
                            this.activePatientAdded(data);
                            break;

                        case 'clearActivePatient':
                            this.clearActivePatient();
                            break;
                    }
                });
        }

        $onDestroy() {
            this.sideNaveSubscription.unsubscribe();
        }

        activePatientAdded(data) {
            if (_.isNil(data.patient) || this.currentPatient === data.patient) return;

            if (this.summarySubscription) this.summarySubscription.unsubscribe();
            if (this.measureSubscription) this.measureSubscription.unsubscribe();
            if (this.generalAccountSubscription) this.generalAccountSubscription.unsubscribe();

            this.mnWebSocket.sub(
                "frontdesk.Practice.stat_changed", "patient_side_nave", data => this.updateEntry(data)
            );

            this.summarySubscription = this.patientService.summaryFileUpdates(data.patient)
                .subscribe(data => this.handleSummaryUpdates(data));

            this.generalAccountSubscription = this.patientService.generalAccountUpdates(data.patient)
                .subscribe(data => this.handleGeneralAccountUpdates(data));

            this.measureSubscription = this.measureService.measureUpdates(data.patient)
                .subscribe(data => this.handleMeasuresUpdates(data));

            this.$q.all([
                this.frontDeskService.getEntryByVisit(data.visit, data.patient),
                this.measureService.sideNavMeasure(data.patient),
                this.patientService.summaryFile(data.patient),
                this.configService.get(["patient_current_fields", "practice_payment", 'calendar_config'])
            ]).then(data => this.success(data));
        }

        success(data) {
            this.summaryFile = data[2];
            const hasKey = _.has(data, '[0].patient');
            this.entry = hasKey ? data[0] : {patient: data[0]};

            const patientData = _.get(data, `[0]${hasKey ? '.patient' : ''}.general_account`);

            this.currentPatient = _.get(data, `[0]${hasKey ? '.patient' : ''}.id`);
            this.patientFullName = _.get(data, `[0]${hasKey ? '.patient' : ''}.full_name`);
            this.showBalanceContainer = !_.get(data, "3.practice_payment.side_nav_statement", false);
            this.showOnlyNotValidated = _.get(data, "3.practice_payment.balance_only_not_validated", false);

            this.dayFilter = _.get(data, '3.calendar_config.practice_filter', true);

            const path = `[0]${hasKey ? '.patient' : ''}.`;
            const keys = _.chain(data)
                .get("3.patient_current_fields")
                .pickBy(v => v)
                .keys()
                .map(x => `${path}${_.replace(x, /\$\$/g, '.')}`)
                .value();

            this.patientFields = _.reduce(keys, (arr, item) => {
                const value = this.$translate.instant(_.get(data, item, null));
                return _.chain(arr).concat(value).compact().value();
            }, []);

            this.patientBalance = this.getPatientBalance(patientData);
            this.patientImgSrc = `/api/patient/${this.currentPatient}/data/?t=${this.moment().valueOf()}`;

            $('.measure-block', this.$element).html(data[1]);
        }

        getPatientBalance(data) {
            return  _.assign({}, data, this.showOnlyNotValidated ? {
                amount_received: data['amount_received_without_payed'],
                amount_consumed: data['amount_consumed_without_payed'],
                dental_progress: data['dental_progress_without_payed'],
            } : {});
        }

        handleSummaryUpdates(summaryFile) {
            this.summaryFile = summaryFile;
        }

        handleMeasuresUpdates(data) {
            $('.measure-block', this.$element).html(data);
        }

        updateEntry(data) {
            if (data.id === _.get(this.entry, 'id', null)) {
                this.entry = data;
                const patientData = _.get(data, 'patient.general_account');
                this.patientBalance = this.getPatientBalance(patientData);
                this.$scope.$applyAsync();
            }
        }

        isEntryToday() {
            return this.entry && this.moment(this.entry.entry_time).format(this.dateFormat) === this.moment().format(this.dateFormat);
        }

        handleGeneralAccountUpdates(data) {
            this.patientBalance = this.getPatientBalance(data);
            this.$scope.$applyAsync();
        }

        viewImage(ev) {
            const file = {name: '', mime: 'image/png', url: `/api/patient/${this.currentPatient}/data/`};

            const dialog = _.assign({}, VISUALIZE_FILE, {
                targetEvent: ev,
                locals: {files: _.castArray(file), fileIndex: 0, allowEdit: false}
            });

            this.dialog.show(dialog);
        }

        clearActivePatient() {
            this.entry = null;
            this.summaryFile = null;
            this.patientBlock = false;
            this.patientFields = null;
            this.currentPatient = null;
            this.patientFullName = null;

            $('.measure-block', this.$element).html("");

            if (this.summarySubscription) this.summarySubscription.unsubscribe();
            if (this.measureSubscription) this.measureSubscription.unsubscribe();
            if (this.generalAccountSubscription) this.generalAccountSubscription.unsubscribe();

            this.summarySubscription = null;
            this.measureSubscription = null;
            this.generalAccountSubscription = null;

            this.mnWebSocket.unsub("frontdesk.Practice.stat_changed", "patient_side_nave");
        }

        openMenu($mdMenu, $event) {
            $event.stopPropagation();
            $mdMenu.open($event);
        }

        endVisit($event) {
            $event.stopPropagation();
            this.frontDeskService.decideMethod(this.entry, 'sv', 'ev');
        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: SideNavCtrl,
        template: require('shared/views/side-nav.tpl.html'),
    };

})();
