(function () {

    const angular = require('angular');

    const mnPrescriptionItem = require("./components/prescription-item/prescription-item");
    const mnBiologyTable = require("./components/biology-table/biology-table");
    const mnLegacyBiologyTable = require("./components/legacy-biology-table/legacy-biology-table");


    const prescriptionService = require("./services/prescriptionService");

    const mnBiologyRow = require("./directives/biology-row/biology-row");

    const TextPrescriptionFormCtrl = require("./controllers/text-prescription-form/TextPrescriptionFormCtrl");
    const PrescriptionListCtrl = require("./controllers/prescription-list/PrescriptionListCtrl");
    const PrescriptionViewCtrl = require("./controllers/prescription-view/PrescriptionViewCtrl");
    const PrescriptionContainerCtrl = require("./controllers/prescription-container/PrescriptionContainerCtrl");
    const MedicalPrescriptionFormCtrl = require("./controllers/medical-prescription-form/MedicalPrescriptionFormCtrl");
    const BiologyPrescriptionFormCtrl = require("./controllers/biology-prescription-form/BiologyPrescriptionFormCtrl");
    const ProcedurePrescriptionFormCtrl = require("./controllers/procedure-prescription-form/ProcedurePrescriptionFormCtrl");
    const CarePrescriptionFormCtrl = require("./controllers/care-prescription-form/CarePrescriptionFormCtrl");


    angular
        .module("medinet")

        .component("mnPrescriptionItem", mnPrescriptionItem)
        .component("mnBiologyTable", mnBiologyTable)
        .component("mnLegacyBiologyTable", mnLegacyBiologyTable)

        .service("prescriptionService", prescriptionService)

        .directive("mnBiologyRow", mnBiologyRow)

        .controller("TextPrescriptionFormCtrl", TextPrescriptionFormCtrl)
        .controller("PrescriptionListCtrl", PrescriptionListCtrl)
        .controller("PrescriptionViewCtrl", PrescriptionViewCtrl)
        .controller("PrescriptionContainerCtrl", PrescriptionContainerCtrl)
        .controller("MedicalPrescriptionFormCtrl", MedicalPrescriptionFormCtrl)
        .controller("BiologyPrescriptionFormCtrl", BiologyPrescriptionFormCtrl)
        .controller("ProcedurePrescriptionFormCtrl", ProcedurePrescriptionFormCtrl)
        .controller("CarePrescriptionFormCtrl", CarePrescriptionFormCtrl);


})();
