/**
 * Created by BETALOS on 13/06/2017.
 */
(function () {

    'use strict';

    module.exports = tablePagination;

    tablePagination.$inject = [];

    function tablePagination() {

        var directive = {
            restrict: 'A',
            require: '^mdTablePagination',
            compile: paginationCompile,
        };

        function paginationCompile(element, attrs) {
            element
                .append(buttonElem)
                .addClass('has-submit');
            
            return linkFunc;
        }
        
        function linkFunc(scope, element, attrs, ctrl) {
            ctrl.submitFunc = submitFunc;

            function submitFunc() {
            }
        }

        var buttonElem = [
            '<md-button class="md-raised md-primary" aria-label="validate" ng-click="$pagination.submitFunc()">',
                '<label translate-once="validate"></label>',
            '</md-button>'
        ].join('')

        return directive;
    }

})()