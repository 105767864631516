(function () {

    'use strict';

    const SELECTED_CATALOG_KEY = "search_procedure_catalog";

    class ProcedureSearchCtrl {
        constructor(catalogService, $mdDialog) {
            this.catalogService = catalogService;
            this.dialog = $mdDialog;

            this.selected = [];
            this.catalogs = [];
            this.selectedCatalog = null;
            this.search = {};

            this.cancel = () => $mdDialog.cancel();
        }

        static get $inject() {
            return ["catalogService", "$mdDialog"];
        }

        $onInit() {
            this.catalogService.getCatalogs()
                .then(data => {
                    const savedId = parseInt(localStorage.getItem(SELECTED_CATALOG_KEY)) || null;
                    this.catalogs = data;
                    this.selectCatalog(_.find(this.catalogs, ["id", savedId]));
                });
        }

        selectProcedures() {
            this.dialog.hide(this.selected);
        }

        selectRow(data) {
            this.selected.push(data);
        }

        selectCatalog(catalog = null) {
            this.selectedCatalog = catalog;
            localStorage.setItem(SELECTED_CATALOG_KEY, _.get(this.selectedCatalog, "id", null));

            if (!!this.selectedCatalog) {
                this.search = {
                    "_id": {
                        "$in": _.map(this.selectedCatalog.procedures, "id")
                    }
                }
            } else {
                this.search = {};
            }
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        controller: ProcedureSearchCtrl,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        template: require("shared/views/procedure-dialog.tpl.html"),
    };

})();