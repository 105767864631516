(function () {
    'use strict';

    module.exports = PrescriptionViewCtrl;

    PrescriptionViewCtrl.$inject = ["prescriptionService", "$transition$", "$scope"];

    function PrescriptionViewCtrl(service, $transition$, $scope) {
        let vm = this;

        let currentType = _.get($transition$.params('to'), 'type');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');

        vm.$onInit = init;

        function init() {
            service.prescriptionType.next({type: currentType});

            service.itemEdited.next({id: -1, type: null});
            service.itemDuplicated.next({id: -1, type: null});
            service.itemActive.next({id: currentPrescription, type: currentType});

            vm.type = currentType;

            vm.promise = service.getPrescription(currentPrescription, currentType)
                .then(success);

            function success(data) {
                vm.prescription = data;
            }

            let itemDeletedSubscription = service.itemDeleted.subscribe(itemDeleted);

            function itemDeleted(item) {
                if (_.isEqual(item, {id: currentPrescription, prescription_type: currentType})) service.listState();
            }

            $scope.$on("$destroy", onDestroy);

            function onDestroy() {
                itemDeletedSubscription.unsubscribe();
            }
        }

    }
})();