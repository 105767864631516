(
    function () {
        'use strict';

        var mnRehabilitationDevice = {
            controller: DevicesCtrl,
            controllerAs: "vm",
            bindings: {
                model: "=model",
                resource: "=resource",
                type: "=type",
                label: "=mnLabel",
                icon: "=mnIcon"
            },
            template: require('rehabilitation/views/devices.tpl.html'), // or template,
        };


        DevicesCtrl.$inject = ["$stateParams", "rehabilitationService", "$mdDialog"];

        function DevicesCtrl($stateParams, rehabilitationService, $mdDialog) {
            let vm = this;

            // vm.model = $stateParams['model'];
            // vm.resource = $stateParams['resource'];
            // vm.type = $stateParams['type'];
            vm.$onInit = init;
            vm.editItem = editItem;
            vm.removeItem = removeItem;

            function init() {
                vm.devices = [];
                loadDevices();

            }

            function loadDevices() {
                vm.promise = rehabilitationService.getDevices(vm.resource).then((data) => {
                    vm.devices = data;
                })
            }

            function editItem(obj = null) {
                $mdDialog.show(_.assign(require("rehabilitation/dialogs/device-dialog"), {
                    locals: {
                        type: vm.type,
                        device: obj
                    }

                })).then((data) => {
                    if (_.has(data, 'id') && !_.find(vm.devices, {id: data.id})) {
                        vm.devices.push(data);
                    }
                });

            }

            function removeItem(obj) {

                rehabilitationService
                    .deleteDevice(vm.resource, obj.id)
                    .then(success);

                function success(data) {
                    loadDevices();
                }
            }
        }

        module.exports = mnRehabilitationDevice;
    }
)();