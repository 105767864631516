/**
 * Created by BETALOS on 14/07/2016.
 */
(function () {

    'use strict';

    module.exports = {
        locals: {},
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        focusOnOpen: false,
        controller: CustomContactDialogCtrl,
        template: require("shared/views/custom-contact.tpl.html"),
    };

    CustomContactDialogCtrl.$inject = ["$mdDialog", "customContactService", "frontDeskService", "$q"];

    function CustomContactDialogCtrl($mdDialog, customContactService, frontDeskService, $q) {
        let vm = this;

        vm.$onInit = init;
        vm.cancel = cancel;
        vm.submit = submit;

        function init() {
            if (!_.isNil(vm.contact)) {
                vm.promise = customContactService.getContact(vm.contact.id)
                    .then(success);
            }
            function success(data) {
                vm.contact = data;
            }
        }

        function submit() {
            vm.promise = customContactService.addContact(vm.contact)
                .then(doneCallbacks);

            function doneCallbacks(data) {
                $mdDialog.hide(data);
            }
        }

        function cancel() {
            $mdDialog.cancel();
        }
    }

})();