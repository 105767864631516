(function () {
    'use strict';


    var mnTrainingBlock = {
        controller: mnTrainingBlockCtrl,
        controllerAs: "vm",
        bindings: {
            test:"=mnTest"
        },
        template: require('rehabilitation/views/training-block.tpl.html'), // or template,
    };

    mnTrainingBlockCtrl.$inject = [];

    function mnTrainingBlockCtrl() {
    }
    module.exports = mnTrainingBlock;
})()