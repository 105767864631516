(function () {

    'use strict';

    module.exports = RehabilitationSettingsCtrl;

    RehabilitationSettingsCtrl.$inject = ["rehabilitationService", "$transitions", "$state", "$scope", "$mdDialog"];

    function RehabilitationSettingsCtrl(rehabilitationService, $transitions, $state, $scope, $mdDialog) {
        let vm = this;

        var settings = require('../json/settings.json');

        vm.$onInit = init;
        vm.newItem = newItem;

        function init() {
            vm.settings = settings;

        }

        function newItem(item) {
            if (_.has(item, 'dialog')) {
                $mdDialog.show(_.assign(require(`rehabilitation/dialogs/${item['dialog']}`), {
                    locals:   item['locals']

                })).then((data) => {
                    _.noop()
                });

            }
            else {
                $state.go(item['state']);

            }
        }
    }

})();