(function () {

    var angular = require('angular');


    var rehabilitationService = require("./services/rehabilitationService");


    var RehabilitationCtrl = require("./controllers/RehabilitationCtrl");
    var CurrentExamsCtrl = require("./controllers/CurrentExamsCtrl");
    var ExamsListCtrl = require("./controllers/ExamsListCtrl");
    var SettingsCtrl = require("./controllers/SettingsCtrl");


    var mnTrainingBlock = require("./components/TrainingBlock");
    var mnRehabilitationDevice = require("./components/RehabilitationDevices");


    angular
        .module("medinet")

        .service("rehabilitationService", rehabilitationService)

        .component("mnTrainingBlock", mnTrainingBlock)
        .component("mnRehabilitationDevice", mnRehabilitationDevice)

        .controller("RehabilitationCtrl", RehabilitationCtrl)
        .controller("ExamsListCtrl", ExamsListCtrl)
        .controller("CurrentExamsCtrl", CurrentExamsCtrl)
        .controller("SettingsCtrl", SettingsCtrl);
})();
