/**
 * Created by Issmahane
 */
(function () {
    'use strict';

    mnBiologyTableCtrl.$inject = ["patientService", "$stateParams"];

    function mnBiologyTableCtrl(patientService, $stateParams) {
        let vm = this;

        vm.$onInit = init;
        vm.$onChanges = changes;
        vm.deleteDetail = deleteDetail;

        function init() {
            vm.currentPatient = _.isUndefined(vm.currentPatient) ? $stateParams.pId : vm.currentPatient;

            vm.readOnly = _.isNil(vm.readOnly) ? false : vm.readOnly;
            vm.noRemove = _.isNil(vm.noRemove) ? false : vm.noRemove;
            vm.isModel = _.isNil(vm.isModel) ? false : vm.isModel;
            vm.isResult = _.isNil(vm.isResult) ? false : vm.isResult;

            if (!_.isNil(vm.currentPatient)) patientService.getMinimalPatient(vm.currentPatient).then(success);
            else vm.patientData = null;

            function success(res) {
                vm.patientData = res;
            }
        }

        function changes(changes) {
            if (_.has(changes, "readOnly.currentValue")) {
                vm.readOnly = _.get(changes, "readOnly.currentValue", false);
            }
        }

        function deleteDetail(exam) {
            vm.details = _.reject(vm.details, function (o) {
                return o.exam.id === exam.id;
            });
        }
    }

    module.exports = {
        controller: mnBiologyTableCtrl,
        controllerAs: "vm",
        bindings: {
            details: '=prescriptionDetails',
            isResult: "=?",
            isModel: "=?",
            currentPatient: "<?patient",
            readOnly: "<",
            noRemove: "<",
        },
        template: require('./biology-table.tpl.html'), // or template,
    };

})();
