/**
 * Created by BETALOS on ------. [edited by amine 15/10/2019]
 */

(function () {
    'use strict';

    class SelectSearchHeaderCtrl {
        constructor($scope, $element, $compile) {
            this.$scope = $scope;
            this.$element = $element;
            this.$compile = $compile;
            this.initItems = this.initItems || [];
            this.searchFunc = this.searchFunc || null;
            this.searchKey = "";
            this.searchBy = "";
            this.inputFocus = false;

            this.itemsFunc = this.itemsFunc || _.noop;
            this.subject = null;
            this.delayedSearch = _.mnDelay(() => this.search(), 50);
        }

        static get $inject() {
            return ["$scope", "$element", "$compile"];
        }

        $onInit() {
            this.clearSearch();
            const tpl = `
                <input type="search" ng-model-options="{debounce: 300}" ng-keydown="$event.stopPropagation();" ng-model="vm.searchKey" 
                    ng-change="vm.search()" translate-once-placeholder="search" class="md-text" mn-auto-focus="vm.inputFocus">
            `;

            let compiledDirective = this.$compile(tpl);
            let directiveElement = compiledDirective(this.$scope);
            this.$element.find("md-select-header").append(directiveElement);
        }

        $onChanges(changes) {
            if (changes.subject && changes.subject.isFirstChange() && changes.subject.currentValue) {
                this.subject.subscribe(event => {
                    if (event === "open") this.inputFocus = !this.inputFocus;
                    else this.clearSearch();
                });
            }

            if (changes.initItems) this.delayedSearch();
        }

        search() {
            if (_.isFunction(this.searchFunc)) {
                this.searchFunc({$event: this.searchKey})
            } else {
                this.defaultSearch()
            }
        }

        defaultSearch() {
            const items = _.filter(this.initItems, item => {
                const keyStr = _.chain(this.searchKey).toLower().deburr().value();
                const title = _.chain(item).get(this.searchBy).toLower().deburr().value();

                return _.includes(title, keyStr);
            });

            this.$scope.$applyAsync(() => this.itemsFunc({$event: items}));
        }

        clearSearch() {
            this.searchKey = "";
            this.$scope.$applyAsync(() => {
                this.itemsFunc({$event: this.initItems})
            });
        }
    }

    tpl.$inject = ['$element'];

    function tpl($element) {
        $element.parent().addClass("select-with-search");

        return "<md-select-header></md-select-header>";
    }

    module.exports = {
        template: tpl,
        bindings: {
            subject: "<",
            itemsFunc: "&?items",
            initItems: "<?",
            searchBy: '@?',
            searchFunc: '&?',
        },
        controllerAs: "vm",
        controller: SelectSearchHeaderCtrl
    };
})();