/**
 * marked as edited 17/11/2017 amine
 */

(function () {
    'use strict';

    module.exports = MedicalPrescriptionFormCtrl;

    MedicalPrescriptionFormCtrl.$inject = ["system", "prescriptionService", "$transition$", "$translate", "$mdDialog",
        "configService", "medicineService", "$q", "$scope", "dragulaService", "observationService", "authService"];

    function MedicalPrescriptionFormCtrl(
        system, prescriptionService, $transition$, $translate, $mdDialog, configService, medicineService, $q,
        $scope, dragulaService, observationService, authService
    ) {
        let vm = this;
        let generatorLock = false;
        let skipChange = false;
        let dateFormat = system['date_format'].js;

        const medicineDialog = require("shared/dialogs/medicine-form-dialog");
        const Generator = require("../../utils/prescription-generator");
        const ArGenerator = require("../../utils/prescription-ar-generator");

        let currentPatient = _.get($transition$.params('to'), 'pId');
        let currentVisit = _.get($transition$.params('to'), 'visitId');
        let currentPrescription = _.get($transition$.params('to'), 'prescriptionId');
        let currentCopy = _.get($transition$.params('to'), 'copy');

        const isRoot = authService.isRootProfile();

        let filters = {
            therapeutic_class: null,
            medicine_category: null,
            inn: null,
            laboratory: null,
            product_type: null,
            type: 'base',
            lang: 'O'
        };

        let detail = {
            medicine: false,
            packaging: null,
            text: "",
            text_ar: "",
            line: "",
            secondary_line: ""
        };

        let generator = {
            quantity: 1,
            unit: null,
            frequencies: [],
            period: {
                quantity: 0,
                type: 'day_type'
            }
        };

        vm.$onInit = init;
        vm.cancel = goBack;

        vm.getMedicines = medicineService.getMedicines;
        vm.getMedicalPrescription = prescriptionService.getMedicalPrescription;
        vm.duplicateState = prescriptionService.duplicateState;

        vm.get = configService.get;

        vm.addDetail = addDetail;
        vm.insertQuantity = insertQuantity;
        vm.generateText = generateText;

        vm.submit = submit;
        vm.editDetail = editDetail;
        vm.deleteDetail = deleteDetail;
        vm.changeMedicine = changeMedicine;
        vm.showMedicineDialog = showMedicineDialog;
        vm.saveModel = saveModel;
        vm.editMedicine = editMedicine;
        vm.getDefault = getDefault;
        vm.filterMedicine = filterMedicine;
        vm.filterChanged = filterChanged;
        vm.hasFilter = hasFilter;
        vm.showPosologies = showPosologies;
        vm.posologySelected = posologySelected;
        vm.prescriptionTotal = prescriptionTotal;
        vm.langChanged = langChanged;
        vm.sortItems = sortItems;
        vm.signPrescription = signPrescription;
        vm.unlockPrescription = unlockPrescription;

        vm.saveDefault = medicineService.saveDefault;
        vm.addPosology = medicineService.addPosology;
        vm.bookmarkMedicine = medicineService.bookmarkMedicine;
        vm.deleteItem = prescriptionService.deleteItem;

        vm.nextDetail = nextDetail;
        vm.handleAppointment = handleAppointment;
        vm.productTypeSelected = productTypeSelected;

        vm.returnToObservation = id => observationService.returnTo(id);


        // @todo vidal:tocheck
        //vm.showLeaflet = showLeaflet;
        //vm.showWarning = showWarning;

        function init() {
            prescriptionService.prescriptionType.next({type: 'MedicalPrescription'});
            prescriptionService.fileManager.next(false);

            prescriptionService.itemActive.next({id: -1, type: null});
            prescriptionService.itemDuplicated.next({id: -1, type: null});
            prescriptionService.itemEdited.next({id: currentPrescription, type: 'MedicalPrescription'});

            vm.minLength = 3;
            vm.filter = false;
            vm.filters = _.cloneDeep(filters);
            vm.detail = _.cloneDeep(detail);
            vm.generator = _.cloneDeep(generator);
            vm.posologie_shown = false;
            vm.posPromise = null;
            vm.currentPatient = currentPatient;
            vm.productTypeFilter = [
                {
                    value: null,
                    label: "all_medicines",
                    icon: "mdi-checkbox-multiple-blank-circle"
                }, {
                    value: false,
                    label: "only_medicines",
                    icon: "mdi-pill"
                }, {
                    value: true,
                    label: "only_para",
                    icon: "mdi-multiplication"
                }
            ];
            vm.showFileManager = false;
            vm.manipulateStock = false;

            let promise = [prescriptionService.getConfig(), configService.get("prescription_fields_config", true)];

            let itemDeletedSubscription = prescriptionService.itemDeleted.subscribe(itemDeleted);
            let itemAddedSubscription = prescriptionService.addMedicalPrescriptionSubject.subscribe(itemAdded);
            let fileManagerSubscription = prescriptionService.fileManager.subscribe(next => {
                vm.showFileManager = !!next;
            });

            if (!_.isNull(currentCopy)) {
                prescriptionService.itemDuplicated.next({id: currentCopy, type: 'MedicalPrescription'});
                promise.push(prescriptionService.getMedicalPrescription(currentCopy));
            } else if (!_.isNull(currentPrescription)) {
                promise.push(prescriptionService.getMedicalPrescription(currentPrescription));
            } else {
                promise.push(createEmptyMedicalPrescription());
            }

            vm.promise = $q.all(promise).then(success, _.noop);

            dragulaService.options($scope, "medicines-bag", {
                revertOnSpill: false,
                moves: function (el, container, handle) {
                    return $(handle).is('.drag-handle') || $(handle).is('.drag-handle > .mdi-drag');
                }
            });
            $scope.$on("$destroy", onDestroy);


            function success(data) {
                vm.configuration = data[0];

                vm.fieldsConfiguration = handleFieldsConfig(data[1]);

                let actualData = data[2];

                vm.prescription =
                    _.isNull(currentCopy)
                        ? actualData
                        : _.chain(actualData)
                            .pick(['details', 'advise', 'greetings'])
                            .assign(
                                {
                                    visit: {id: currentVisit},
                                    prescription_date: moment().format(dateFormat)
                                }
                            )
                            .value();

                vm.manipulateStock = vm.configuration.stock_integration || !!vm.prescription.order;

                vm.advanceForm = _.get(vm.configuration, "advance_mp", false);
                vm.useArabGenerator = _.get(vm.configuration, "arab_generator.is_used", false);
                vm.generatorInstance = new Generator(vm.configuration.generator, $translate);
                vm.arGeneratorInstance = new ArGenerator(vm.configuration['arab_generator'].generator, $translate);

                vm.usePara = _.get(vm.configuration, "use_para", false);

                if (_.get(vm.configuration, 'use_bookmarked_db', false)) filters.type = "is_bookmarked";
                if (_.get(vm.configuration, 'use_arab_as_default', false)) filters.lang = "AR";
                //use_arab_as_default

                filters.product_type = vm.usePara ? null : false;
                vm.filters = _.cloneDeep(filters);

                vm.tplParams = {
                    useArab: _.get(vm.configuration, "arab_generator.is_used", false)
                };

                if (_.isNull(currentCopy)) {
                    vm.editModel = actualData['is_model'];

                    prescriptionService.prescriptionType.next({
                        type: 'MedicalPrescription',
                        model: vm.editModel
                    })
                }
            }

            function handleFieldsConfig(config) {
                return _.chain(config).map((item, key) => {
                    return {
                        key: key,
                        label: $translate.instant(key),
                        meta_data: {
                            dict_uid: item
                        }
                    }
                }).sortBy('key').value();
            }

            function createEmptyMedicalPrescription() {
                return {
                    details: [],
                    advise: "",
                    greetings: "",
                    future_appointment: null,
                    visit: {id: currentVisit},
                    prescription_date: moment().format(dateFormat),
                }
            }

            function itemDeleted(item) {
                if (_.isEqual(item, {
                    id: currentPrescription,
                    prescription_type: 'MedicalPrescription'
                })
                ) prescriptionService.listState();
            }

            function itemAdded(id) {
                vm.promise = prescriptionService.getMedicalPrescription(id)
                    .then(itemAddedSuccess);
            }

            function itemAddedSuccess(prescription) {
                vm.prescription.details = _.chain(vm.prescription.details)
                    .concat(prescription.details)
                    .uniqBy('medicine.id')
                    .value();
            }


            function onDestroy() {
                itemAddedSubscription.unsubscribe();
                itemDeletedSubscription.unsubscribe();
                fileManagerSubscription.unsubscribe();
            }

            //observation section
            vm.observationId = _.get($transition$.params('to'), 'observationId');
            if (!_.isNil(vm.observationId)) vm.observationContext = true;
        }

        function submit(quit) {
            return prescriptionService.saveMedicalPrescription(vm.prescription, currentPatient)
                .then(data => {
                    if (vm.editModel) prescriptionService.modelAdded.next(data);
                    else prescriptionService.itemAdded.next(data);

                    if (vm.observationContext) {
                        observationService.saveMedicalPrescriptionEntry(data.id, vm.observationId)
                            .then(observationId => {
                                afterSave(quit, data, {observationId: observationId});
                            });
                    } else afterSave(quit, data);
                })

        }

        function afterSave(quit, data, supQuery = {}) {
            const _afterSave = (quit, data, supQuery) => {
                let promise = $q.when(data);

                if (vm.prescription.is_locked && !data.is_signed) {
                    promise = prescriptionService.sign("medical-prescription", data);
                } else if (!vm.prescription.is_locked && data.is_signed) {
                    promise = prescriptionService.unsign("medical-prescription", data);
                }
                promise.then(data => {
                    if (quit === "list") return prescriptionService.listState();
                    if (quit === "back") return goBack();

                    if (_.isNull(currentPrescription)) {
                        vm.prescription = data;
                        currentPrescription = data.id;
                        prescriptionService.refreshState(data, supQuery);
                    } else {
                        vm.prescription.is_locked = data.is_locked;
                        vm.prescription.is_signed = data.is_signed;
                    }
                });
            }

            if (vm.manipulateStock) {
                prescriptionService.orderFromMedicalPrescription(data)
                    .then(newData => {
                        _afterSave(quit, newData, supQuery);
                    });
            } else {
                _afterSave(quit, data, supQuery);
            }

        }

        function saveModel($event) {
            let confirm = $mdDialog.mnPromptDialog()
                .title('exam_model_title')
                .event('prescription.MedicalPrescription.title_validation')
                .placeholder('title')
                .targetEvent($event);

            $mdDialog.show(confirm).then(success);

            function success(data) {
                let model = _.assign(_.pick(vm.prescription, ['details', 'advise', 'greetings']), {title: data});

                prescriptionService.saveMedicalPrescription(model)
                    .then(success);

                function success(data) {
                    prescriptionService.modelAdded.next(data);
                }
            }
        }

        function generateText() {
            $scope.$applyAsync(() => {
                if (generatorLock) {
                    generatorLock = false;
                    return false;
                }

                if (_.isEmpty(vm.detail.text)) {
                    vm.detail.line = "";
                    vm.detail.secondary_line = "";
                }

                let new_line = vm.generatorInstance.formulaParser(vm.generator),
                    new_s_line = "";

                vm.detail.text = vm.detail.line + new_line;

                if (vm.filters.lang === "AR") {
                    new_s_line = vm.arGeneratorInstance.formulaParser(vm.generator);
                    vm.detail.text_ar = vm.detail.secondary_line + new_s_line;
                }

            });
        }

        function resetGenerator() {
            vm.detail = _.cloneDeep(detail);
            vm.generator = _.assign(_.cloneDeep(generator), {unit: _.get(vm.configuration, 'default_values.dosage_form', null)});
        }

        function nextDetail() {
            vm.detail.line = vm.generatorInstance.nextLine(vm.detail.text);
            vm.generator = _.cloneDeep(generator);

            if (vm.filters.lang === "AR") {
                vm.detail.secondary_line = vm.arGeneratorInstance.nextLine(vm.detail.text_ar);
            }

            if (!_.isNil(vm.detail.medicine['dosage_form'])) vm.generator.unit = vm.detail.medicine['dosage_form'];
            else setDefaultUnit();

            generateText();
        }

        function addDetail() {
            let medicine = vm.detail.medicine;
            if (vm.filters.lang === "AR") {
                medicine = _.cloneDeep(_.get(vm.detail.medicine, 'ar_instance'));
                if (!_.isNil(medicine)) {
                    _.unset(vm.detail.medicine, 'ar_instance');
                    medicine.original = _.cloneDeep(vm.detail.medicine)
                } else {
                    medicine = _.cloneDeep(vm.detail.medicine)
                }
            }

            let index = _.findIndex(vm.prescription.details, ["medicine.id", medicine.id]);

            if (index === -1) {
                const newItem = _.chain(vm.detail).cloneDeep().assign({medicine: medicine}).value();
                vm.prescription.details.push(newItem);
            } else {
                let pickedField = ["text", "packaging"];
                if (vm.filters.lang === "AR") pickedField.push("text_ar");

                vm.prescription.details[index] = _.assign(vm.prescription.details[index], _.pick(vm.detail, pickedField))
            }

            resetGenerator();
        }

        function changeMedicine(medicine) {
            vm.posologie_shown = false;

            if (_.isNil(medicine) || skipChange) {
                skipChange = false;
                return
            }

            generatorLock = true;
            let index = _.findIndex(vm.prescription.details, ["medicine.id", medicine.id]);

            if (index !== -1) {
                let item = _.cloneDeep(vm.prescription.details[index]);

                if (medicine['is_ar']) {
                    const ar_instance = _.omit(medicine, ['original']);
                    vm.filters.lang = 'AR';
                    skipChange = true;
                    vm.detail.medicine = _.assign({}, medicine.original, {ar_instance});
                } else vm.filters.lang = 'O';

                vm.detail.text = item.text;
                vm.detail.text_ar = item.text_ar;

                if (!_.isNil(item.packaging)) vm.detail.packaging = item.packaging;
            } else {
                if (vm.filters.lang === "AR") {
                    let original = medicine;
                    medicine = _.get(original, "ar_instance", null);

                    if (_.isNil(medicine)) {
                        medicine = original;
                        return;
                    }
                }

                getDefault(medicine);
            }

            let dosageForm = _.get(vm.detail.medicine, 'dosage_form', null)
            if (!_.isNil(dosageForm)) vm.generator.unit = dosageForm;
            else setDefaultUnit();
        }

        function insertQuantity(value) {
            vm.generator.quantity = value;
            generateText()
        }

        function editDetail(item) {
            vm.posologie_shown = false;
            vm.detail.medicine = item.medicine;
        }

        function deleteDetail(item) {
            _.remove(vm.prescription.details, {medicine: item.medicine});
        }

        function showMedicineDialog($event) {
            $mdDialog.show(_.assign(medicineDialog, {
                targetEvent: $event,
                locals: {
                    para: true
                }
            })).then(success);

            function success(data) {
                if (_.isEmpty(vm.detail.medicine)) vm.detail.medicine = data;
            }
        }

        function editMedicine(item, $event) {
            $mdDialog.show(_.assign(medicineDialog, {
                targetEvent: $event,
                locals: {
                    currentMedicine: item.id,
                    is_mn: item.is_mn
                }
            })).then(success, _.noop);

            function success(data) {
                vm.detail.medicine = data;
            }
        }

        function getDefault(medicine) {
            //get last or default if exist
            medicineService.getDefault(medicine).then(success);

            function success(res) {
                if (!_.isEmpty(res)) {
                    vm.detail.text = _.get(res, 'text', '');
                    vm.detail.text_ar = _.get(res, 'text_ar', '');
                } else {
                    vm.detail.text_ar = vm.detail.text = "";
                }
            }
        }

        function showPosologies() {
            vm.posologie_shown = !vm.posologie_shown;
            vm.posologies = [];
            vm.posPromise = medicineService["getPosologies"](vm.detail.medicine).then(success);

            function success(data) {
                vm.posologies = data;
            }
        }

        function posologySelected(item, replace) {
            if (_.isNil(vm.detail.text) || _.isEmpty(vm.detail.text) || replace) {
                vm.detail.text = item.text;
                if (vm.filters.lang === "AR") vm.detail.text_ar = item.text_ar;
            } else {
                vm.detail.text = `${vm.detail.text}\n${item.text}`.replace(/\s{2,}/g, "\n");
                if (vm.filters.lang === "AR") `${vm.detail.text_ar}\n${item.text_ar}`.replace(/\s{2,}/g, "\n");
            }
            vm.posologie_shown = false;
        }

        function prescriptionTotal() {
            if (vm.prescription.legacy) return 0;

            return _.reduce(_.get(vm.prescription, "details", []), total, 0);

            function total(result, item) {
                return result + (item.packaging ? item.packaging["prise"] : _.get(item.medicine, 'default_sell_prise', 0));
            }
        }

        function langChanged() {
            vm.detail.medicine = null;
            //resetGenerator();
        }

        function handleAppointment(ev) {
            let event = _.isNil(vm.prescription.future_appointment) ? {is_waiting_list: false} : vm.prescription.future_appointment;
            $mdDialog.show(_.assign(require('frontdesk/dialogs/appointment-dialog'), {
                targetEvent: ev,
                locals: {
                    timeRange: null,
                    hasPause: false,
                    patient: currentPatient,
                    event: event
                }
            })).then(success);

            function success(data) {
                vm.prescription.future_appointment = data;
            }
        }

        function hasFilter() {
            let fields = ["therapeutic_class", "medicine_category", "inn", "laboratory"];
            if (vm.usePara) fields.push("product_type");

            // is any filter non null
            return !_.every(fields, (field) => _.isNil(vm.filters[field]));
        }

        function filterChanged() {
            $scope.$applyAsync(() => {
                $("#mpaInput").trigger("focus");
            });
        }

        function productTypeSelected() {
            let item = _.find(vm.productTypeFilter, ['value', vm.filters.product_type]);

            filterChanged();
            return $translate.instant(item.label);
        }

        function filterMedicine() {
            vm.filter = !vm.filter;

            vm.filters = _.assign(_.cloneDeep(filters), _.pick(vm.filters, "type"));
        }

        function sortItems(alpha = true) {
            const details = vm.prescription.details;
            let sortFunction = [item => _.get(item, "medicine.is_para_product", false)];

            if (alpha)
                sortFunction.push(item => item.is_legacy_detail ? item.medicine_name : item.medicine.full_commercial_name);

            vm.prescription.details = [];
            vm.prescription.details = _.orderBy(details, sortFunction);
        }

        function setDefaultUnit() {
            vm.generator.unit = _.get(vm.configuration, 'default_values.dosage_form', null);
        }

        function goBack() {
            if (vm.observationContext) return observationService.returnTo(vm.observationId);
            else return window.history.back();
        }

        function signPrescription() {
            vm.prescription.is_locked = true;

            return vm.submit();
        }

        function unlockPrescription() {
            if ((vm.prescription.is_locked && vm.prescription.is_signed) || isRoot) {
                return prescriptionService.unsign("medical-prescription", vm.prescription, isRoot)
                    .then(data => vm.prescription.is_locked = data.is_locked);
            } else {
                return $q.when(vm.prescription);
            }
        }


        // @todo vidal:tocheck
        /*function showLeaflet(item) {
            prescriptionService.getLeaflet(item.medicine).then(function (data) {
                $mdDialog.show({
                    clickOutsideToClose: true,
                    scope: $scope,
                    preserveScope: true,
                    template: '<md-dialog>' +
                    '  <md-dialog-content>' +
                    '<div class="leaflet-content">' +
                    data +
                    '</div>' +
                    '  </md-dialog-content>' +
                    '</md-dialog>'
                });
            });
        }

        function showWarning(item) {

        }*/
    }
})();
