/**
 * Created by amine on 22/03/2017. md-cell
 */
(function () {
    "use strict";

    class InputMdCell {
        constructor() {
            this.priority = 0;
            this.restrict = "A"
            this.require = "?ngModel";
        }

        static create() {
            return new InputMdCell(...arguments);
        }

        compile(element, attrs) {
            let $input = $("input", element);

            if ($input.length > 0 && element.parents("tr.search-tr").length == 0 && element.is('[blur-update]')) {
                $input.attr("ng-model-options", "{updateOn: 'blur'}");
            }

            return this.link;
        }

        link(scope, element, attrs, ctrl) {
            let $input = $("input", element);

            if ($input.length > 0) {
                if (element.parents("tr.search-tr").length == 0) element.addClass("mn-input-cell");

                $input
                    .on("click", ev => this.click(ev))
                    .on("blur", () => this.blur(element))
                    .on("focus", ev => this.focus(ev, element))
            }
        }

        click(ev) {
            ev.stopPropagation();
        }

        focus(ev, element) {
            if ($(ev.currentTarget).is('[readonly]')) return;

            else {
                $(ev.currentTarget).select();
                element.addClass("mn-input-focused");
            }
        }

        blur(element) {
            if ($("input", element).is('[readonly]')) return;
            else element.removeClass("mn-input-focused");
        }
    }

    InputMdCell.create.$inject = [];

    module.exports = InputMdCell.create;

})();