(function () {

    'use strict';

    module.exports = ExamsListCtrl;

    ExamsListCtrl.$inject = ["rehabilitationService"];

    function ExamsListCtrl(rehabilitationService) {
        let vm = this;

        vm.$onInit = init;

        function init() {
            let promise = [];

        }

    }

})();