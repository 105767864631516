/**
 * Created by BETALOS on 15/07/2016.
 */
(function () {
    'use strict';

    const FullScreen = require('shared/utils/full-screen');
    const AGENDA_DIALOG = require('frontdesk/dialogs/agenda-dialog');
    const ACCOUNT_UPDATE_DIALOG = require('auth/dialogs/account-update-dialog');

    const TOOLBAR_LINKS_ACL = [
        {resource: 'encasement', action: 'get'},
        {resource: 'exam', action: 'get'},
        {resource: 'visit', action: 'get'},
        {resource: 'states', action: 'get'}
    ];

    class ToolbarCtrl {
        constructor($scope, $element, mnSidenav, authService, $timeout, $mdDialog, $mdToast, configService, visitService, system, $translate, maintenanceService) {
            this.scope = $scope;
            this.timeout = $timeout;
            this.translate = $translate;
            this.dialog = $mdDialog;
            this.toast = $mdToast;
            this.sidenav = mnSidenav;
            this.authService = authService;
            this.configService = configService;
            this.maintenanceService = maintenanceService;

            this.isFullScreen = false;
            this.licencedFor = system['licenced_for'];
            this.username = this.authService.user['username'];

            this.backupsSubscription = null;

            visitService.visitSubLinks.subscribe(
                data => this.showRepportLink = !_.get(data, 'disabled.EXAM', false)
            );

            $(document).on("fullscreenchange webkitfullscreenchange", $element, () => this.handleFullScreenChange());
        }

        static get $inject() {
            return [
                "$scope", "$element", "mnSidenav", "authService", "$timeout", "$mdDialog", "$mdToast", "configService",
                "visitService", "system", "$translate", "maintenanceService"
            ];
        }

        $onInit() {
            this.currentYear = moment().format("YYYY");
            this.defaultState = this.authService.getDefaultState();
            
            this.sideNavSubscription = this.sidenav.sideNavSubject
                .subscribe(status => {
                    if (_.isNull(status)) return;

                    this.isShown = status;

                    if (status) this.sidenav.lastState('left');
                    else this.sidenav.close('left', false);
                });

            this.handleBackups();
        }

        $onDestroy() {
            if (this.sideNavSubscription) this.sideNavSubscription.unsubscribe();
            if (this.backupsSubscription) this.backupsSubscription.unsubscribe();
        }

        sideNav() {
            this.sidenav.toggle("left");
        }

        updateAccount(ev) {
            this.dialog.show(_.assign({}, ACCOUNT_UPDATE_DIALOG, {
                locals: {},
                targetEvent: ev,
            }))
        }

        showAgenda(ev) {
            this.dialog.show(_.assign({}, AGENDA_DIALOG, {
                targetEvent: ev,
            }));
        }

        fullScreen() {
            if (document['webkitIsFullScreen']) FullScreen.cancelFullScreen();
            else FullScreen.goFullScreen();
        }

        handleFullScreenChange() {
            this.isFullScreen = document['webkitIsFullScreen'];
            this.scope.$applyAsync();
        }

        linkCount() {
            let hasModules = _.sumBy([
                !this.showRepportLink,
                !this.configService.hasStock(),
                !this.configService.hasCareSheet()
            ], i => _.toInteger(i));

            return {'max-width': `calc(100% / ${10 - (this.handleLinksAcl() + hasModules)})`};
        }

        handleLinksAcl() {
            return _.chain(TOOLBAR_LINKS_ACL).map(
                i => !this.authService.aclHandler(i)
            ).sumBy(i => _.toInteger(i)).value();
        }

        getDefaultStateLabel() {
            if (this.defaultState === 'app.frontDesk.index') return 'front_desk_link_label';
            else if (this.defaultState === 'app.patient') return 'patient_manage';
            else if (this.defaultState === 'app.workflow.daily-workflow') return 'visit_list';
            else if (this.defaultState === 'app.workflow.daily-workflow') return 'daily_workflow';
        }

        _getToast(msg, action = null) {
            const simpleToast = this.toast.simple()
                .textContent(this.translate.instant(msg)) //
                .position("bottom left")
                .highlightAction(true)
                .highlightClass('md-primary')
                .hideDelay(0);

            if (action) simpleToast.action(this.translate.instant(action));

            return this.toast.show(simpleToast);
        }

        handleBackups() {
            this.configService.getByHttp("backups_config")
                .then(data => {
                    if (_.get(data, "internal_error", false)) this.maintenanceService.internalErrorAlert();
                })
            this.backupsSubscription = this.maintenanceService.backupsNotifications()
                .subscribe(backup => {
                    if (!backup) return false;

                    if (backup.is_generated) {
                        this._getToast("app_backup.ready_notification", "app_backup.download").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.downloadBackup(backup);
                            }
                        }, _.noop);
                    } else if (backup.has_failed && !backup.locked) {
                        this._getToast("app_backup.failed_notification", "app_backup.reload").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.reloadBackup(backup);
                            }
                        }, _.noop);
                    } else if (backup.locked) {
                        this._getToast("app_backup.cs_notification").then(resp => {
                            if (resp === "ok") {
                                this.maintenanceService.reloadBackup(backup);
                            }
                        }, _.noop);
                    }
                });
        }
    }

    module.exports = {
        controllerAs: "vm",
        controller: ToolbarCtrl,
        template: require('shared/views/toolbar.tpl.html')
    };

})();
