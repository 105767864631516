/**
 * Created by BETALOS on 18/01/2017.
 */


(function () {

    'use strict';

    const {Subject} = require("rxjs");
    const CONTACT_DIALOG = require('shared/dialogs/contact-dialog');

    class PhysicianContactCtrl {
        constructor(physicianService, $timeout, $mdDialog, $attrs, $element) {
            this.physicianService = physicianService;
            this.dialog = $mdDialog;
            this.$timeout = $timeout;
            this.$attrs = $attrs;
            this.$element = $element;
            this.ngModelController = this.ngModelController || null;

            this.eventSubject = new Subject();
            this.internalValue = null;
            this.items = [];
            this.initItems = [];
        }

        static get $inject() {
            return ["physicianService", "$timeout", "$mdDialog", "$attrs", "$element"];
        }

        $onInit() {
            this.physicianService.getContacts().then(items => this.initItems = items);

            this.ngModelController.$render = () => this.renderCurrentValue();

            this.newContactSubscription = this.physicianService.newContactSubject.subscribe(data => {
                this.initItems.push(data);

                this.internalValue = data;
                this.internalValueChanged();
            });
        }

        $onDestroy() {
            this.newContactSubscription.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        setItems(items) {
            const item = _.find(this.initItems, ['id', _.get(this.internalValue, 'id', null)]);
            this.items = _.chain(items).pushOrUpdate(item).compact().uniqBy('id').sortBy("full_name").value();
        }

        mdSelectEvent(event) {
            this.eventSubject.next(event);
        }

        showModal($event) {
            const dialog = _.assign({}, CONTACT_DIALOG, {
                targetEvent: $event,
                locals: {
                    hasBooth: false,
                    isPhysician: true
                }
            });

            this.dialog.show(dialog).then(data => {
                this.physicianService.newContactSubject.next(data);
            });
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        const isRequired = $elem.is("[required]") ? "required" : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = $elem.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        return `
            <md-input-container>
                <label translate-once="${attrs['mnLabel']}"></label>
                <md-select md-on-open="vm.mdSelectEvent('open')" md-on-close="vm.mdSelectEvent('close')" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id'}" 
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled}>
                    <mn-select-search-header subject="vm.eventSubject" search-by="full_name" items="vm.setItems($event)" init-items="vm.initItems"></mn-select-search-header>
                    <md-option ng-repeat="item in vm.items track by item.id" ng-value="item" ng-bind="::item.full_name"></md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ${ngDisabled} ${isDisabled} ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.showModal($event)" aria-label="add" tabindex="-1">
                        <md-icon md-font-icon="mdi-plus" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ${isDisabled} ng-if="!vm.disabled" ng-if="vm.internalValue && !vm.disabled" class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        bindings: {
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: PhysicianContactCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
