(function () {

    'use strict';

    class NotificationListDialogCtrl {
        constructor($mdDialog, notificationService) {
            this.dialog = $mdDialog;
            this.service = notificationService;

            this.notifications = []
        }

        static get $inject() {
            return ["$mdDialog", "notificationService"];
        }

        $onInit() {
            this.promise = this.service.getNotifications()
                .then(data => this.notifications = data);
        }

        updateNotification(notification, ev) {
            this.service.notificationDialog(notification, null, ev);
        }

        removeNotification(item, ev) {
            this.promise = this.service.removeNotification(item, ev)
                .then(() => {
                    this.notifications = _.without(this.notifications, item);
                });
        }

        cancel() {
            this.dialog.cancel();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: NotificationListDialogCtrl,
        template: require("../views/notification-list-dialog.tpl.html"),
    };


})();