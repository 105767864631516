/**
 * Created by amine on 19/07/2017 // edited by amine on 14/04/2020 C19.
 */
(function () {

    'use strict';

    const MODAL_ID = "patient_modal";
    const nEqual = {"$ne": true};

    class InputSearchModalCtrl {
        constructor(modalService, patientService, $element, $attrs) {
            this.modalService = modalService;
            this.patientService = patientService;
            this.element = $element;
            this.attrs = $attrs;

            this.componentId = _.get($attrs, "componentId", "default");
            this.subject = _.format('{0}.{1}', this.componentId, MODAL_ID);
            this.currentValue = null;
            this.choose = false;
            this.disabled = this.disabled || false;
            this.modalSubscription = null;

            this.ngModelController = this.ngModelController || null;
            this.callback = this.callback || null;
            this.remove = this.remove || null;

            this.filter = this.filter || {
                is_archived: nEqual,
                is_deleted: nEqual,
                is_draft: nEqual,
                has_visit: true
            };
        }

        static get $inject() {
            return ["modalService", "patientService", "$element", "$attrs"];
        }

        $onInit() {
            this.modalSubscription = this.modalService.subject.subscribe(data => this.modalCallBack(data));

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        $onDestroy() {
            if (this.modalSubscription) this.modalSubscription.unsubscribe();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.currentValue) {
                if (_.isNumber(this.ngModelController.$viewValue)) this.setPatient(this.ngModelController.$viewValue);
                else this.currentValue = this.ngModelController.$viewValue;
            }
        }

        internalValueChanged(patient) {
            this.currentValue = patient;

            if (patient) {
                if (_.isFunction(this.callback)) this.callback(this.currentValue);
                //this.patientSelected({$patient: this.currentValue});
            } else {
                if (_.isFunction(this.remove)) this.remove();
                //this.patientRemoved();
            }

            this.ngModelController.$setViewValue(this.currentValue);
            this.ngModelController.$commitViewValue();
        }

        modalCallBack(msg) {
            if (_.eq(msg.subject, this.subject)) {
                this.choose = true;
                this.setPatient(msg.data);
            }
        }

        setPatient(patient) {
            this.patientService.getMinimalPatient(_.get(patient, 'id', patient))
                .then(data => this.internalValueChanged(data));
        }

        showModal($event) {
            if (!_.isNil($event)) $event.stopPropagation();
            if (this.disabled) return;

            this.modalService.showModal(MODAL_ID, this.filter, this.componentId, $event);
        }

        cancel($event) {
            if (!_.isNil($event)) $event.stopPropagation();
            this.choose = false;
            this.internalValueChanged(null);
        }
    }

    tpl.$inject = ['$element', '$attrs', '$translate'];

    function tpl($elem, $attrs, $translate) {
        const label = _.get($attrs, "placeholder", "payment_choose_patient");

        $elem.addClass("layout-row");
        return `
            <md-input-container ng-click="vm.showModal($event)">
                <input ng-model="vm.currentValue.full_name" aria-label="patient fullname"
                readonly >
                <label translate-once="${label}"></label>
                <div class="mn-option-buttons flex-nogrow layout-row">
                    <md-button ng-if="!vm.disabled" class="md-icon-button" ng-click="vm.showModal($event)" aria-label="search">
                        <md-icon md-font-icon="mdi-magnify" md-font-set="mdi"></md-icon>
                    </md-button>
                    <md-button ng-if="vm.choose && !vm.disabled" class="md-icon-button" ng-click="vm.cancel($event)" aria-label="cancel">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            </md-input-container>
        `;
    }

    module.exports = {
        template: tpl,
        controllerAs: "vm",
        controller: InputSearchModalCtrl,
        require: {ngModelController: "ngModel"},
        bindings: {
            //change: "&ngChange",
            disabled: "=ngDisabled",
            filter: "=?filter",
            callback: '<?',
            remove: '<?'
        }
    };

})();
