/**
 * Created by Hp on 25/09/2017.
 */

(function () {
        'use strict';

        module.exports = {
            controller: ConventionDialogCtrl,
            controllerAs: "vm",
            parent: $(document.body),
            locals: {},
            bindToController: true,
            clickOutsideToClose: true,
            multiple: true,
            template: require('shared/views/convention-dialog.tpl.html'),
        };

        ConventionDialogCtrl.$inject = ["$mdDialog", "conventionService", "$translate"];

        function ConventionDialogCtrl($mdDialog, conventionService, $translate) {
            let vm = this;

            vm.$onInit = init;
            vm.cancel = $mdDialog.cancel;

            vm.submit = submit;
            vm.selected = [];
            vm.addSelection = addSelection;
            vm.SelectProcedure = SelectProcedure;
            vm.removeLine = removeLine;
            vm.onPaginateExceptions = onPaginateExceptions;

            function init() {
                vm.model = {tariff: {tm: 0, tp: 0}, exceptions:[]};
                vm.exceptionQuery = {
                    limit: 4,
                    page: 1,
                    order: "+code"
                };
                vm.options = [2, 4, 6,10];
                vm.paginationLabel = {
                    page: $translate.instant('page'),
                    rowsPerPage: $translate.instant('rowsPerPage'),
                    of: $translate.instant('of')
                }
            }

            function addSelection() {
                $mdDialog.hide(vm.selected);
            }

            function submit() {
                conventionService.saveConvention(vm.model).then(function (data) {
                    $mdDialog.hide([data]);
                });
            }

            function SelectProcedure() {
                $mdDialog.show( require('billing/dialogs/procedure-search-dialog') ).then(function (data) {
                    _.forEach(data, function (procedure) {
                        if (_.isNil(_.find(vm.model.exceptions, function (item) {
                                return item.procedure.code == procedure.code;
                            }))) {
                            vm.model.exceptions.push({
                                procedure: procedure,
                                tariff: {tm: 100, tp: 0}
                            });
                        }
                    })
                });

            }

            function removeLine(idx) {
                vm.model.exceptions.splice((vm.exceptionQuery.page - 1) * vm.exceptionQuery.limit + idx, 1);
            }

            function onPaginateExceptions(page, limit) {
                vm.exceptionQuery = _.assign(vm.exceptionQuery, {page: page, limit: limit});
                if (vm.model.exceptions.length < ((vm.exceptionQuery.page - 1) * vm.exceptionQuery.limit)) {
                    vm.exceptionQuery.page = 1;
                }
            }


        }

    })();
