/**
 * Created by BETALOS on 15/02/2017.
 */
(function () {
    'use strict';

    class MnCapital {
        constructor() {
            this.priority = 0;
            this.restrict = "A"
            this.require = "ngModel";
        }

        static create() {
            return new MnCapital(...arguments);
        }

        link(scope, element, attrs, ngModelController) {
            const unwatch = scope.$watch(() => ngModelController.$modelValue, (value) => {
                this.capitalize(value, ngModelController);
                unwatch();
            });

            ngModelController.$parsers.push((inputValue) => this.capitalize(inputValue, ngModelController));
        }

        capitalize(inputValue, ctrl) {
            if (_.isNil(inputValue)) return;

            let capitalized = _.toUpper(inputValue);

            if (capitalized !== inputValue) {
                ctrl.$setViewValue(capitalized);
                ctrl.$render();
            }

            return capitalized;
        }
    }

    MnCapital.create.$inject = [];

    module.exports = MnCapital.create;
})();
