/**
 * Created by Amine on 27/07/2016.
 */
(function () {
    'use strict';

    const angular = require('angular');

    const paymentService = require("./services/paymentService");
    const mnEncasementDetails = require("./components/encasement-details");
    const mnAccountDetails = require("./components/account-details");
    const mnAccountBalance = require("./components/account-balance");
    const FinancialStatementContainerCtrl = require("./controllers/FinancialStatementContainerCtrl");
    const MainPaymentContainerCtrl = require("./controllers/MainPaymentContainerCtrl");
    const MainPaymentFormCtrl = require("./controllers/MainPaymentFormCtrl");
    const visitTableLegend = require("./directives/visit-table-legend");


    angular
        .module("medinet")
        .service("paymentService", paymentService)

        .directive("mnVisitTableLegend", visitTableLegend)
        
        .component("mnEncasementDetails", mnEncasementDetails)
        .component("mnAccountDetails", mnAccountDetails)
        .component("mnAccountBalance", mnAccountBalance)

        .controller('MainPaymentContainerCtrl', MainPaymentContainerCtrl)
        .controller('MainPaymentFormCtrl', MainPaymentFormCtrl)
        .controller('FinancialStatementContainerCtrl', FinancialStatementContainerCtrl);

})();