/**
 * Created by Issmahane  26/12/2016
 * Edited by Amine  13/10/2021
 */
(function () {

    'use strict';

    class ProcedureTableCtrl {
        constructor() {

        }

        static get $inject() {
            return [];
        }

        get rowCount() {
            let count = 6;

            if (this.readonly) count -= 1;
            if (this.hasPrice) count -= 1;
            if (this.hasQte) count -= 1;
            if (this.hasCode) count -= 1;

            return count;
        }

        $onInit() {
            this.details = this.details || []
            this.readonly = !!this.readonly;
            this.hasPrice = _.isNil(this.hasPrice) ? true : !!this.hasPrice;
            this.hasQte = _.isNil(this.hasPrice) ? true : !!this.hasPrice;
            this.hasCode = _.isNil(this.hasPrice) ? true : !!this.hasPrice;
        }

        deleteDetail(index) {
            this.details.splice(index, 1);
        }

    }

    module.exports = {
        controller: ProcedureTableCtrl,
        controllerAs: "vm",
        bindings: {
            details: '=',
            readonly: "<?",
            hasPrice: "<?",
            hasQte: "<?",
            hasCode: "<?"
        },
        template: require('shared/views/procedure-table.tpl.html')
    };

})();
