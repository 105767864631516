/**
 * Created by BETALOS on 18/12/2015.
 */
(function () {
    'use strict';

    module.exports = patientService;

    const {Observable} = require('rxjs');
    const downloadJs = require('downloadjs');
    const PATIENT_ALERTS_DIALOG = require('../dialogs/patient-alerts-dialog');
    const PATIENT_RELATIONS_DIALOG = require('../dialogs/patient-relations-dialog');

    patientService.$inject = [
        'Upload', '$q', '$http', 'mnWebSocket', 'system', "$state", "$mdDialog", "$mdToast", "configService"
    ];

    function patientService(Upload, $q, $http, mnWebSocket, system, $state, $mdDialog, $mdToast, configService) {
        let self = this;

        const dateFormat = system['date_format'].js;

        self.getFormPatient = getFormPatient;
        self.getMinimalPatient = getMinimalPatient;
        self.getExtraMinimalPatient = getExtraMinimalPatient;
        self.patientEdition = patientEdition;
        self.checkExistence = checkExistence;
        self.archivePatients = archivePatients;
        self.unarchivePatients = unarchivePatients;
        self.removePatients = removePatients;
        self.restorePatients = restorePatients;
        self.privatizePatients = privatizePatients;
        self.unprivatizePatients = unprivatizePatients;
        self.updateMedicalInsurances = updateMedicalInsurances;

        self.handleTitleChange = handleTitleChange;

        self.patientFilter = patientFilter;
        self.goToEdition = goToEdition;
        self.goToPlanning = goToPlanning;

        self.getMedicalFileHistory = getMedicalFileHistory;
        self.getMedicalFileVersion = getMedicalFileVersion;
        self.getActiveMedicalFile = getActiveMedicalFile;
        self.handleMedicalFile = handleMedicalFile;

        self.getVaccinations = getVaccinations;
        self.handleVaccination = handleVaccination;
        self.removeVaccination = removeVaccination;

        self.summaryFile = summaryFile;
        self.summaryFileUpdates = summaryFileUpdates;

        self.generalAccountUpdates = generalAccountUpdates;

        self.exportBeta = exportBeta;

        self.patientAlerts = patientAlerts;

        self.manageRelations = manageRelations;
        self.getPatientRelations = getPatientRelations;
        self.updatePatientRelations = updatePatientRelations;

        self.previousPatient = previousPatient;
        self.nextPatient = nextPatient;

        self.downloadPatientSummary = downloadPatientSummary;

        self.refreshFinancialStatus = refreshFinancialStatus;

        // custom fields related
        self.customFields = [];
        self.getCustomFields = getCustomFields;
        self.setCustomFields = setCustomFields;

        self.getCategories = getCategories;
        self.handleCategory = handleCategory;
        self.removeCategory = removeCategory;


        self.uniqueIdentifierConfig = {};
        self.getIdentifierConfig = getIdentifierConfig;
        self.setIdentifierConfig = setIdentifierConfig;
        self.generateIdentifiers = generateIdentifiers;

        function getFormPatient(patient) {
            const deferred = $q.defer();
            const url = `/api/patient/${patient}/`;

            if (_.isNil(patient)) deferred.resolve({
                medical_insurances: [],
                emergency_contacts: [],
                contact_info: {phone_numbers: []},
                file_date: moment().format(dateFormat),
            });

            else $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function updateMedicalInsurances(patient) {
            return mnWebSocket.call(
                'patient.PatientMinimal.update_medical_insurances', _.pick(patient, ['id', 'medical_insurances', 'is_insured'])
            );
        }

        function handleTitleChange(patient) {
            const titleGender = _.get(patient, 'title.gender');

            if (!titleGender || titleGender === 'UNDEFINED') return;
            else patient.gender = titleGender;
        }

        function getMinimalPatient(pat_id, forEntry) {
            const query = _.assign({pk: pat_id}, forEntry ? {forEntry} : {});
            return mnWebSocket.call('patient.PatientMinimal.retrieve', query);
        }

        function getExtraMinimalPatient(pat_id) {
            return mnWebSocket.call('patient.PatientMinimal.extra_minimal', {pk: pat_id});
        }

        function patientEdition(patient, file) {
            const deferred = $q.defer();
            const url = `/api/patient/${patient.id ? patient.id + "/" : ''}`;

            if (!patient['is_insured']) patient.medical_insurances = [];

            Upload.upload({url: url, data: {data: Upload.json(patient), file, file_name: "picture"}})
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function checkExistence(patient) {
            const query = _.pick(patient, ['last_name', 'first_name']);
            return mnWebSocket.call('patient.PatientMinimal.existence', query);
        }

        function archivePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.archive', ids)
        }

        function unarchivePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.unarchive', ids)
        }

        function removePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.remove', ids);
        }

        function restorePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.restore', ids)
        }

        function privatizePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.privatize', ids)
        }

        function unprivatizePatients(patients) {
            const ids = _.map(patients, 'id');
            return mnWebSocket.call('patient.PatientMinimal.unprivatize', ids)
        }

        function patientFilter(value) {
            const equal = {"$eq": true};
            const nEqual = {"$ne": true};

            switch (value) {
                case "all":
                    return {is_deleted: nEqual};
                case "active":
                    return {
                        is_archived: nEqual,
                        is_deleted: nEqual
                    };
                case "favorite":
                    return {
                        is_archived: nEqual,
                        is_bookmarked: equal,
                        is_deleted: nEqual
                    };
                case "archived":
                    return {
                        is_archived: equal,
                        is_deleted: nEqual
                    };
                case "incomplete":
                    return {
                        is_uncompleted: equal,
                        is_archived: nEqual,
                        is_deleted: nEqual
                    };
                case "no-visit":
                    return {
                        is_archived: nEqual,
                        has_visit: nEqual,
                        is_deleted: nEqual
                    };
                case "draft":
                    return {
                        is_draft: equal,
                        is_deleted: nEqual

                    };
                case "deleted":
                    return {is_deleted: equal};
            }
        }

        function goToEdition(item) {
            $state.go("app.patient-form", {patient_id: item.id});
        }

        function goToPlanning(item) {
            $state.go('app.planning', {patient: item.id});
        }

        function exportBeta(item, $event) {
            if (!_.isNil($event)) $event.stopPropagation();
            return mnWebSocket.call("patient.Export.export", {pk: item.id})
        }

        function getMedicalFileHistory(patient) {
            return mnWebSocket.call('patient.MedicalFile.versions', {patient});
        }

        function getMedicalFileVersion(version) {
            const deferred = $q.defer();
            const url = `/api/medical-file/${version}/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getActiveMedicalFile(patient, maxDate) {
            let query = _.assign({patient}, _.isNil(maxDate) ? {} : {max_date: maxDate});
            return mnWebSocket.call('patient.MedicalFile.active_version', query);
        }

        function handleMedicalFile(medicalFile, patient) {
            const deferred = $q.defer();
            const isCreate = _.isNil(medicalFile.id);
            const url = `/api/medical-file/${isCreate ? '' : medicalFile.id + '/'}?patient=${patient}`;

            const http = $http[isCreate ? 'post' : 'put'](url, medicalFile);

            http.then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleVaccination(vaccination) {
            const deferred = $q.defer();
            const url = `/api/vaccination/${vaccination.id ? vaccination.id + '/' : ''}`

            $http.post(url, vaccination)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getVaccinations(patient) {
            const deferred = $q.defer();
            const url = `/api/vaccination/?patient=${patient}`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeVaccination(vaccination) {
            const deferred = $q.defer();
            const url = `/api/vaccination/${vaccination}/`;

            $http.delete(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getCategories() {
            const deferred = $q.defer();
            const url = `/api/patient-category/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleCategory(category) {
            const deferred = $q.defer();
            const url = `/api/patient-category/${category.id ? category.id + '/' : ''}`

            $http.post(url, category)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function removeCategory(category) {
            const deferred = $q.defer();
            const url = `/api/patient-category/${_.isObject(category) ? category.id : category}/`;

            $http.delete(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }


        function summaryFile(patient) {
            const deferred = $q.defer();

            mnWebSocket.call('patient.SummaryFile.retrieve', {patient})
                .then(success, deferred.reject);

            function success(data) {
                deferred.resolve(data['summary']);
            }

            return deferred.promise;
        }

        function summaryFileUpdates(activePatient) {
            return new Observable(observer => {
                mnWebSocket.sub('patient.SummaryFile.patient_updated', 'sideNaveSummary', data => {
                    if (data.patient === activePatient) summaryFile(activePatient)
                        .then(summary => observer.next(summary));
                });

                return {
                    unsubscribe: () => {
                        mnWebSocket.unsub('patient.SummaryFile.patient_updated', 'sideNaveSummary');
                        observer.complete();
                    }
                }
            });
        }

        function generalAccountUpdates(activePatient) {
            return new Observable(observer => {
                mnWebSocket.sub('patient.PatientMinimal.patient_general_account_updated', 'sideNaveSummary', data => {
                    if (data.patient === activePatient) observer.next(data['general_account']);
                });

                return {
                    unsubscribe: () => {
                        mnWebSocket.unsub('patient.PatientMinimal.patient_general_account_updated', 'sideNaveSummary');
                        observer.complete();
                    }
                }
            });
        }

        function patientAlerts(patient, ev) {
            ev.stopPropagation();

            $mdDialog.show(_.assign({}, PATIENT_ALERTS_DIALOG, {
                targetEvent: ev,
                locals: {patient}
            }));
        }

        function manageRelations(patient, ev) {
            ev.stopPropagation();

            $mdDialog.show(_.assign({}, PATIENT_RELATIONS_DIALOG, {
                targetEvent: ev,
                locals: {patient}
            }));
        }

        function getPatientRelations(patient) {
            patient = _.isObject(patient) ? _.get(patient, 'id') : patient;

            return mnWebSocket.call('patient.PatientMinimal.get_patient_relations', {patient});
        }

        function updatePatientRelations(patient, to_save = [], to_delete = []) {
            patient = _.isObject(patient) ? _.get(patient, 'id') : patient;

            return mnWebSocket.call('patient.PatientMinimal.update_patient_relations', {patient, to_save, to_delete});
        }

        function previousPatient(patient) {
            patient = _.isObject(patient) ? _.get(patient, 'id') : patient;

            const deferred = $q.defer();
            const url = `/api/patient/${patient}/previous/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function nextPatient(patient) {
            patient = _.isObject(patient) ? _.get(patient, 'id') : patient;

            const deferred = $q.defer();
            const url = `/api/patient/${patient}/next/`;

            $http.get(url)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function downloadPatientSummary(patient, query) {
            const promise = $http.post(`/api/lr/patient-summary/${patient}/download/`, {query: query}, {responseType: 'arraybuffer'})
                .then(res => {
                    downloadJs(res.data, res.headers('X-FILENAME'));
                }, _.noop);
            const simpleToast = $mdToast.mnAdvancedToast()
                .handle(promise)
                .label('patient_summary_download_title')
                .description('patient_summary_download_message');

            $mdToast.show(simpleToast);

            return promise;
        }

        // custom fields related
        function getCustomFields() {
            const deferred = $q.defer();

            configService.get('patient_custom_fields', false)
                .then(data => {
                    self.customFields = data || [];
                    deferred.resolve(data);
                }, deferred.error);

            return deferred.promise;
        }

        function setCustomFields(fields) {
            const deferred = $q.defer();

            configService.set({"patient_custom_fields": fields})
                .then(() => {
                    self.customFields = fields;
                    deferred.resolve(fields);
                }, deferred.error);

            return deferred.promise;
        }

        function getIdentifierConfig() {
            const deferred = $q.defer();

            configService.get('patient_identifier_config', false)
                .then(data => {
                    self.uniqueIdentifierConfig = data || {
                        is_enabled: false,
                        type: "auto",
                        format: "F{id}/{creation_year}"
                    };
                    deferred.resolve(data);
                }, deferred.error);

            return deferred.promise;
        }

        function setIdentifierConfig(config) {
            const deferred = $q.defer();

            configService.set({"patient_identifier_config": config})
                .then(() => {
                    self.uniqueIdentifierConfig = config;
                    deferred.resolve(config);
                }, deferred.error);

            return deferred.promise;
        }

        function generateIdentifiers(regenerate = false) {
            return mnWebSocket.call('patient.PatientMinimal.refresh_unique_identifiers', {regenerate});
        }

        function refreshFinancialStatus(patient) {
            return mnWebSocket.call('patient.PatientMinimal.refresh_financial_status', {patient});
        }
    }

})();
