/**
 * Created by amine on 09/03/2017.
 */
(function () {

    'use strict';

    module.exports = tableAnimate;

    tableAnimate.$inject = [];

    function tableAnimate() {

        let directive = {
            restrict: 'A',
            scope: {
                "expression": '&mnTableAnimate'
            },
            link: tableAnimateLink,
        };

        function tableAnimateLink(scope, element, attrs) {
            scope.$watch(scope.expression, handleChange);

            function handleChange(value) {
                let hidden = element.is(".hidden") || element.is(".hide-container");

                if (hidden && value) {
                    element.removeClass("hidden").removeClass("hide-container").addClass("show-container");
                } else if (!hidden) {
                    element.removeClass("show-container").addClass("hide-container");
                }
            }

        }

        return directive;
    }

})()