/**
 * Created by BETALOS on 28/03/2017.
 */
(function () {

    'use strict';

    module.exports = {
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: false,
        controller: BiologyResultDialogCtrl,
        template: require("./biology-result-dialog.tpl.html"),
    };

    BiologyResultDialogCtrl.$inject = ["$mdDialog", "prescriptionService", "system", "$q"];

    function BiologyResultDialogCtrl($mdDialog, prescriptionService, system, $q) {
        let vm = this;

        let dateFormat = system['date_format'].js;

        vm.$onInit = init;
        vm.submit = submit;
        vm.preSave = preSave;
        vm.handleBiology = handleBiology;
        vm.cancel = $mdDialog.cancel;

        function init() {
            vm.prescription = null;

            vm.promise = prescriptionService.getBiologyPrescriptionList(vm.patient)
                .then(success);

            function success(data) {
                vm.bilogyPrescriptions = data;
            }
        }

        function handleBiology(item) {
            let promises = [];

            if (item['is_result']) {
                promises.push(prescriptionService.getBiologyPrescription(item.id));
                promises.push(prescriptionService.getResult(item.request_id));
            }

            else promises.push(prescriptionService.getBiologyPrescription(item.id));

            vm.promise = $q.all(promises).then(success);

            function success(data) {
                if (item['is_result']) {
                    vm.prescription = _.assign(data[0], {request: {id: item.request_id}});
                    resultCallback(data[1]);
                }

                else {
                    vm.prescription = _.assign(_.pick(data[0], ['details', 'comment']), {
                        prescription_date: moment().format(dateFormat),
                        visit: {id: item['visit_id']},
                        request: _.pick(item, 'id')
                    });

                }
            }

            function resultCallback(data) {
                if (_.isNil(data)) return;

                vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                    if (!_.isUndefined(data[detail.exam.id])) {
                        if (detail.exam['is_unique']) detail.value = data[detail.exam.id];
                        else detail.value = _.merge(detail.value, data[detail.exam.id])
                    }

                    result.push(detail);

                    return result;
                }, []);
            }
        }

        function handleDetails() {
            vm.prescription.details = _.reduce(vm.prescription.details, function (result, detail) {
                if (detail.exam['is_unique'] && !_.isNull(detail.value)) {
                    result.push(detail);
                }
                if (!detail.exam['is_unique']) {
                    _.forEach(detail.value, function (value, uid) {
                        if (_.isNull(value)) _.unset(detail.value, uid);
                    });

                    if (!_.isEmpty(detail.value)) result.push(detail);
                }
                return result;
            }, []);
        }

        function submit() {
            handleDetails();

            vm.promise = prescriptionService.saveBiologyPrescriptionResult(vm.prescription, vm.patient)
                .then($mdDialog.cancel);
        }

        function preSave() {
            handleDetails();

            return prescriptionService.saveBiologyPrescriptionResult(vm.prescription, vm.patient)
                .then(success);

            function success(data) {
                vm.prescription.id = data.id;
            }
        }

    }

})();