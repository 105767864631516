/**
 * Created by BETALOS on 16/03/2016. [edited by amine 16/10/2019]
 */

(function () {

    'use strict';

    class PhysicianCtrl {
        constructor($scope, physicianService, modalService, $timeout, $element) {
            this.physicianService = physicianService;
            this.modalService = modalService;
            this.$timeout = $timeout;
            this.$scope = $scope;
            this.$element = $element;
            this.ngModelController = this.ngModelController || null;

            this.internalValue = null;
            this.label = "";
            this.items = [];
        }

        static get $inject() {
            return ["$scope", "physicianService", "modalService", "$timeout", "$element"];
        }

        $onInit() {
            this.physicianService.physicianSubject.subscribe(items => this.items = items);

            this.ngModelController.$render = () => this.renderCurrentValue();
        }

        renderCurrentValue() {
            if (this.ngModelController.$viewValue !== this.value) {
                this.value = this.ngModelController.$viewValue
            }

            this.internalValue = this.value;
        }

        internalValueChanged() {
            this.value = this.internalValue;
            this.ngModelController.$setViewValue(this.internalValue);
            this.ngModelController.$commitViewValue();
        }

        cancel() {
            this.internalValue = null;
            this.internalValueChanged();
        }
    }

    tpl.$inject = ['$element', '$attrs'];

    function tpl($elem, attrs) {
        const isRequired = $elem.is("[required]") ? "required" : "";
        const isDisabled = $elem.is('[disabled]') ? "disabled" : "";
        const ngRequired = $elem.is("[ng-required]") ? "ng-required='vm.required'" : "";
        const ngDisabled = $elem.is("[ng-disabled]") ? 'ng-disabled="vm.disabled"' : "";

        const inline = $elem.is('[inline]');

        const multiple = $elem.is('[multiple]') ? 'multiple' : "";
        const optionIf = $elem.is('[multiple]') ? 'vm.value.length > 0 && !vm.disabled' : "vm.value && !vm.disabled";
        const noRemove = $elem.is('[noRemove]') ? 'false' : "true";

        if ($elem.is("[mn-disabled]") || $elem.is("[mn-required]")) console.error("{ mn-disabled | mn-required } are removed use { ng-disabled | ng-required }");

        $elem
            .addClass("layout-row")
            .addClass("layout-align-start-center");

        if (!$elem.is('[flex]') && !$elem.is('.flex')) $elem.addClass("flex-nogrow");

        if (!inline) {
            return `<md-input-container>
                        <label translate-once="{{::vm.label}}"></label>
                        <md-select class="no-badge" aria-label="physician" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id'}"
                                ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled} ${multiple}>
                            <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                                <span ng-bind="::item.full_name"></span>
                            </md-option>
                        </md-select>
                        <div class="mn-option-buttons flex-nogrow layout-row" ng-if="${optionIf} && ${noRemove}">
                            <md-button ${isDisabled} class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                                <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                            </md-button>
                        </div>
                    </md-input-container>`;
        } else {
            $elem.addClass("inline-select");

            return `
                <md-select class="no-badge" aria-label="physician" ng-model="vm.internalValue" ng-model-options="{ trackBy: '$value.id'}"
                        ng-change="vm.internalValueChanged()" ${ngRequired} ${isRequired} ${ngDisabled} ${isDisabled} ${multiple}
                       placeholder="{{vm.label | translate}}">
                    <md-option ng-repeat="item in vm.items track by item.id" ng-value="item">
                        <span ng-bind="::item.full_name"></span>
                    </md-option>
                </md-select>
                <div class="mn-option-buttons flex-nogrow layout-row" ng-if="${optionIf} && ${noRemove}">
                    <md-button ${isDisabled} class="md-icon-button" ng-click="vm.cancel()" aria-label="cancel" tabindex="-1">
                        <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                    </md-button>
                </div>
            `;
        }
    }

    module.exports = {
        template: tpl,
        bindings: {
            label: '@mnLabel',
            disabled: "=?ngDisabled",
            required: "=?ngRequired"
        },
        controllerAs: "vm",
        controller: PhysicianCtrl,
        require: {ngModelController: "ngModel"}
    };

})();
