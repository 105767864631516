(function () {

    'use strict';

    class ModelSearchDirective {
        constructor(modalService) {
            this.priority = 0;
            this.restrict = "A";
            this.require = "ngModel";
            this.scope = {query: "<"};
            this.service = modalService;
        }

        static create() {
            return new ModelSearchDirective(...arguments);
        }

        link(scope, element, attrs, ngModelController) {
            let modalId = attrs['modelSearchDirective'];
            let componentId = attrs['componentId'] ? attrs['componentId'] : "default";

            let subject = `${componentId}.${modalId}`;

            element.on('click', ev => this.handleClick(scope, modalId, componentId, ev));

            let modalSubscription = this.service.subject.subscribe(response => {
                if (response.subject == subject) {
                    ngModelController.$setViewValue(response.data);
                    ngModelController.$commitViewValue();
                    ngModelController.$validate();
                }
            });

            scope.$on('$destroy', () => modalSubscription.unsubscribe());
        }

        handleClick(scope, modalId, componentId, ev) {
            this.service.showModal(modalId, scope.query || {}, componentId, ev);
        }
    }

    ModelSearchDirective.create.$inject = ["modalService"];

    module.exports = ModelSearchDirective.create;

})();