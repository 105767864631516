/**
 * Edited by amine on 13/10/2021.
 */
(function () {

    'use strict';

    const BIOLOGY_RESUME_DIALOG = require('../../dialogs/biology-resume-dialog/biology-resume-dialog');

    class PrescriptionContainerCtrl {
        constructor($q, $state, prescriptionService, $transition$, $scope, $transitions, visitService, $mdDialog, observationService, configService) {
            this.q = $q;
            this.state = $state;
            this.prescriptionService = prescriptionService;
            this.visitService = visitService;
            this.dialog = $mdDialog;
            this.observationService = observationService;
            this.configService = configService;

            this.currentPatient = _.get($transition$.params('to'), 'pId');
            this.currentVisit = _.get($transition$.params('to'), 'visitId');
            this.observationId = _.get($transition$.params('to'), 'observationId');

            this.models = [];
            this.prescriptions = [];
            this.list = [];

            this.promise = null;
            this.patient = null;
            this.lock = true;
            this.showModel = false;
            this.showBiologyExamTree = false;
            this.showFileManager = false;
            this.prescriptionType = null;
            this.useCarePrescription = false;
            this.currentDuplicated = {id: -1, type: null};
            this.currentPrescription = {id: -1, type: null};
            this.currentEditPrescription = {id: -1, type: null};

            this.subscriptions = {
                itemDeleted: null,
                itemEdited: null,
                itemActive: null,
                itemDuplicated: null,
                itemAdded: null,
                modelAdded: null,
                prescriptionType: null,
                currentPatient: null,
                fileManager: null,
            }

            if (_.eq($state.current.name, "app.visit.prescription")) this.listState();

            this.transitionsListener = $transitions.onBefore({}, transition => {
                const state = transition.to();

                if (!_.eq(state.name, "app.visit.prescription.list") && _.eq(state.name, "app.visit.prescription")) {
                    this.listState();

                    return false;
                }

                return true;
            });
        }

        static get $inject() {
            return [
                "$q", "$state", "prescriptionService", "$transition$", "$scope", "$transitions", "visitService", "$mdDialog",
                "observationService", "configService"
            ];
        }

        $onInit() {
            this.promise = this.prescriptionService.getAllPrescriptionLists(this.currentPatient)
                .then(({prescriptions, models, useCarePrescription}) => {
                    this.prescriptions = this.sortPrescriptions(prescriptions);
                    this.models = this.sortPrescriptions(models);
                    this.useCarePrescription = useCarePrescription;

                    this.list = this.filterPrescriptions();
                });
            // this.promise = this.q.all([
            //     this.prescriptionService.getTextPrescriptionList(this.currentPatient),
            //     this.prescriptionService.getMedicalPrescriptionList(this.currentPatient),
            //     this.prescriptionService.getBiologyPrescriptionList(this.currentPatient),
            //     this.prescriptionService.getProcedurePrescriptionList(this.currentPatient),
            //     this.prescriptionService.getCarePrescriptionList(this.currentPatient),
            //
            //     this.prescriptionService.getModelTextPrescriptionList(),
            //     this.prescriptionService.getModelMedicalPrescriptionList(),
            //     this.prescriptionService.getModelBiologyPrescriptionList(),
            //     this.prescriptionService.getModelProcedurePrescriptionList(),
            //     this.prescriptionService.getModelCarePrescriptionList(),
            //
            //     this.configService.getByHttp("medical_care_config")
            // ])

            this.subscriptions.itemDeleted = this.prescriptionService
                .itemDeleted
                .subscribe(item => {
                    _.remove(this.list, item);
                    _.remove(this.prescriptions, item);
                    _.remove(this.models, item);
                });
            this.subscriptions.itemEdited = this.prescriptionService.itemEdited.subscribe(item => this.currentEditPrescription = item);
            this.subscriptions.itemActive = this.prescriptionService.itemActive.subscribe(item => this.currentPrescription = item);
            this.subscriptions.itemDuplicated = this.prescriptionService.itemDuplicated.subscribe(item => this.currentDuplicated = item);
            this.subscriptions.itemAdded = this.prescriptionService.itemAdded
                .subscribe(item => {
                    let index = _.findIndex(this.prescriptions, _.pick(item, ['id', 'prescription_type']));

                    if (index === -1) {
                        this.prescriptions = _.concat(this.prescriptions, item);
                    } else {
                        item.constructed_title = item.prescription_date;
                        this.prescriptions.splice(index, 1, item);
                    }

                    this.prescriptions = this.sortPrescriptions(this.prescriptions);
                    this.list = this.filterPrescriptions();

                    this.showModel = false;
                });
            this.subscriptions.modelAdded = this.prescriptionService
                .modelAdded
                .subscribe(item => {
                    let index = _.findIndex(this.models, _.pick(item, ['id', 'prescription_type']));

                    if (index === -1) {
                        this.models = this.sortPrescriptions(_.concat(this.models, item));
                    } else {
                        item.constructed_title = item.title;
                        this.models.splice(index, 1, item);
                    }

                    this.list = this.filterModels();
                    this.showModel = true;
                });
            this.subscriptions.prescriptionType = this.prescriptionService
                .prescriptionType
                .subscribe(data => {
                    this.prescriptionType = _.get(data, 'type', null);
                    this.showBiologyExamTree = false;

                    if (this.showModel || _.get(data, 'model', false)) {
                        this.showModel = true;
                        this.list = this.filterModels();
                    } else {
                        this.showModel = false;
                        this.list = this.filterPrescriptions();
                    }
                });
            this.subscriptions.currentPatient = this.visitService.currentPatientSubject.subscribe(data => this.patient = data);
            this.subscriptions.fileManager = this.prescriptionService.fileManager.subscribe(next => this.showFileManager = !!next);

            if (!_.isNil(this.observationId)) this.observationContext = true;
        }

        $onDestroy() {
            this.subscriptions.each(subscription => {
                if (subscription) subscription.unsubscribe();
                if (this.transitionsListener) this.transitionsListener();
            });
        }

        toggleBiologyExamTree() {
            this.showBiologyExamTree = !this.showBiologyExamTree;
            this.showModel = false;
        }

        toggleModels() {
            this.showBiologyExamTree = false;
            this.showModel = !this.showModel;

            if (this.showModel) this.list = this.filterModels();
            else this.list = this.filterPrescriptions();
        }

        filterModels() {
            if (_.isNil(this.prescriptionType)) {
                return this.models;
            } else {
                return _.filter(this.models, item => item.prescription_type === this.prescriptionType);
            }
        }

        filterPrescriptions() {
            if (_.isNil(this.prescriptionType)) {
                return this.prescriptions;
            } else {
                return _.filter(this.prescriptions, {'prescription_type': this.prescriptionType});
            }
        }

        showModels(show) {
            if (show) {
                this.showModel = true;
                this.list = this.filterModels();
            } else {
                this.showModel = false;
                this.list = this.filterPrescriptions();
            }
        }

        pushNewModelPrescription(item) {
            const index = _.findIndex(this.models, {id: item.id});

            if (index === -1) this.models.push(item);
            else this.models.splice(index, 1, item);

            this.models = this.sortModelPrescription(this.models);
            this.showModels(true);
        }

        sortModelPrescription(list) {
            return _.sortBy(list, item => {
                return _.chain(item.title).deburr().toLower().value();
            });
        }

        viewPrescription(item, reload = true) {
            if (item['is_model']) {
                this.prescriptionService.duplicateState(item.id, item.prescription_type);
            } else if (item.visit_id === this.currentVisit) {
                this.prescriptionService.editState(item, reload);
            } else {
                this.state.go("app.visit.prescription.view", {
                    type: item['prescription_type'],
                    prescriptionId: item.id,
                    legacy: item.is_legacy ? 1 : null,
                }, {inherit: true}).then(this.state.reload);
            }
        }

        sortPrescriptions(list) {
            return _.sortBy(list, item => {
                    item.constructed_title = item.is_model ? item.title : item.prescription_date;
                    return item.is_model ? item.title : -1 * item.sort_date;
                }
            );
        }

        newPrescription(type) {
            const url = `app.visit.prescription.${_.kebabCase(type)}`;
            this.prescriptionService.fileManager.next(false);

            this.state.go(url, {
                copy: null,
                prescriptionId: null,
            }, {inherit: true}).then(() => this.reloadState(url, this.state.is(url)));
        }

        reloadState(url, reload) {
            if (reload) this.state.reload(url);
            this.showModels(false);
        }

        showBiologyResume(ev) {
            this.dialog.show(_.assign(BIOLOGY_RESUME_DIALOG, {
                targetEvent: ev,
                locals: {
                    currentPatient: this.currentPatient
                }
            }))
        }

        useBiologyModelPrescription(item) {
            this.prescriptionService.useBiologyModel.next(item.id);
        }

        editItem(item) {
            const isList = _.includes(["app.visit.prescription.list", "app.visit.prescription.view"], this.state.current.name);
            this.prescriptionService.editState(item, !isList);
        }

        duplicateItem(item) {
            const isList = _.includes(["app.visit.prescription.list", "app.visit.prescription.view"], this.state.current.name);

            this.prescriptionService.duplicateState(item.id, item['prescription_type'], !isList);
            this.currentEditPrescription = {id: -1, type: null};
            this.currentPrescription = {id: -1, type: null};
        }

        deleteItem(item) {
            this.prescriptionService.deleteItem(item);
        }

        addResult(item) {
            this.state.go("app.visit.prescription.biology-prescription", {
                prescriptionId: null,
                copy: null,
                requestId: item.id
            }, {inherit: true}).then(this.state.reload);
        }

        addMedicalPrescription(item) {
            this.prescriptionService.addMedicalPrescriptionSubject.next(item.id);
        }

        addProcedurePrescription(item) {
            this.prescriptionService.addProcedurePrescriptionSubject.next(item.id);
        }

        addTextPrescription(item) {
            this.prescriptionService.addTextPrescriptionSubject.next(item.id);
        }

        toggleFileManager() {
            this.showFileManager = !this.showFileManager;

            this.prescriptionService.fileManager.next(this.showFileManager);
        }

        listState() {
            this.prescriptionService.listState();
        }

        printGroup(value) {
            this.prescriptionService.printGroup(value);
        }

        returnToObservation(id) {
            this.observationService.returnTo(id)
        };
    }

    module.exports = PrescriptionContainerCtrl;

})();
