(function () {
    'use strict';

    mnRadioGroup.$inject = [];

    function mnRadioGroup() {
        let directive = {
            restrict: 'E',
            link: link,
            scope: {
                model: "=ngModel",
                change: "&ngChange",
                clicked: "&ngClick",
            },
            require: "ngModel",
            template: ""
        };

        function link(scope, $element, attrs, ngModelController) {
            let clicked = false;
            let allowNull = $element.is('[allow-null]');

            $element.off("click");
            $element.on("click", "button.md-button", select);

            ngModelController.$viewChangeListeners = [modelChanged];

            scope.$watch("model", modelChanged);

            function select() {
                let $node = $(this);
                let value = $node.attr("value");

                if (!$node.is(".active")) {
                    $("button.md-button", $element).removeClass("active");
                    $node.addClass("active");
                    clicked = true;
                } else if (allowNull) {
                    $node.removeClass("active");
                    value = null;
                    clicked = true;
                }

                if (clicked) {
                    ngModelController.$setViewValue(value);
                    ngModelController.$commitViewValue();
                    ngModelController.$validate();
                }
            }

            function modelChanged() {
                let oldValue = $(".active", $element).attr("value");

                if (oldValue != scope.model) {
                    if (clicked) scope.clicked();
                    else activeBtn(scope.model);

                    scope.change();
                }
                clicked = false;
            }

            function activeBtn(value) {
                $("button.md-button", $element).removeClass("active");
                $(`button.md-button[value="${_.escape(value)}"]`, $element).addClass("active");
            }

            scope.$on("destroy", function () {
                $("button.md-button", $element).off("click");
            });
        }

        return directive;
    }

    module.exports = mnRadioGroup;
})();