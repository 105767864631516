/**
 * Created by Hp on 03/05/2017.
 */
(function () {
    'use strict';
    module.exports = ContactsCtrl;

    ContactsCtrl.$inject = ["$state", "customContactService", "$mdToast", "$translate", "printService", "$stateParams", "$mdDialog"];

    function ContactsCtrl($state, customContactService, $mdToast, $translate, printService, $stateParams, $mdDialog) {
        var vm = this;

        vm.$onInit = init;
        vm.EditContact = EditContact;

        function init() {
            vm.filter = {is_deleted: {$ne: true}};
            vm.actions = {
                single: [{
                    icon: 'mdi-pencil',
                    label: 'edition',
                    resource: 'invoice',
                    action: 'get',
                    behavior: 'disable',
                    method: EditContact
                },
                    {
                        icon: 'mdi-delete',
                        label: 'delete',
                        resource: 'custom-contact',
                        action: 'delete',
                        behavior: 'disable',
                        method: deleteContact
                    }],
                multiple: []
            }
        }

        function EditContact(obj) {
            const dialog = _.assign({}, require('../dialogs/custom-contact-dialog'), {
                targetEvent: null,
                locals: {
                    contact: obj
                },
                clickOutsideToClose: false,
                focusOnOpen: false
            });
            $mdDialog.show(dialog)
                .then(_.isFunction(vm.reset) ? vm.reset : _.noop);
        }

        function deleteContact(obj) {
            $mdDialog.show(
                $mdDialog.confirm()
                    .parent($(document.body))
                    .clickOutsideToClose(true)
                    .title('')
                    .textContent($translate.instant('delete_object_warning'))
                    .ariaLabel('delete_object_warning')
                    .ok($translate.instant('confirm'))
                    .cancel($translate.instant('cancel'))
            ).then(function () {
                customContactService.removeContact(obj.id).then(_.isFunction(vm.reset) ? vm.reset : _.noop);
            })

        }

    }
})()