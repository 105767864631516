/**
 * Created by BETALOS on 03/05/2017.
 */
(function () {

    'use strict';

    module.exports = defaultChip;

    defaultChip.$inject = ["$compile"];

    function defaultChip($compile) {

        var directive = {
            restrict: 'EA',
            priority: 0,
            scope: {
                isDefault: "=isDefault",
            },
            link: linkFunc,
        };

        function linkFunc(scope, elem, attrs) {
            var myChip = elem.parent().parent();
            scope.$watch('isDefault', function (newValue) {
                if (newValue == true) {
                    myChip.addClass('default-convention');
                } else {
                    myChip.removeClass('default-convention');
                }
            });
        };

        return directive;
    };

})()