/**
 * Created by Smahane on 30/12/2016.
 */
(function () {
    "use strict";

    module.exports = mnAsyncValidation;

    mnAsyncValidation.$inject = ["$q", "mnWebSocket"];

    function mnAsyncValidation($q, mnWebSocket) {
        const directive = {
            restrict: 'A',
            scope: {
                query: "&?",
                onValidate: '&?',
                onInvalidate: '&?'
            },
            require: 'ngModel',
            link: link,
        };

        function link(scope, element, attrs, ngModelController) {
            let event = attrs.mnAsyncValidation;

            if (!_.isEmpty(event)) ngModelController.$asyncValidators.mnAsyncValidation = validation;

            function validation(modelValue) {
                let defer = $q.defer();

                let event = attrs.mnAsyncValidation;
                let query = _.isUndefined(scope.query) ? {} : scope.query();

                mnWebSocket.call(event, _.assign(query, {value: modelValue}))
                    .then(function (data) {
                        if (data) {
                            if (_.isFunction(scope.onValidate)) scope.onValidate({data});
                            defer.resolve();
                        } else {
                            if (_.isFunction(scope.onInvalidate)) scope.onInvalidate({data});
                            defer.reject();
                        }
                    });

                return defer.promise;
            }
        }

        return directive;
    }

})();
