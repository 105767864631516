(function () {

    'use strict';

    module.exports = RehabilitationCtrl;

    RehabilitationCtrl.$inject = ["rehabilitationService"];

    function RehabilitationCtrl(rehabilitationService) {
        let vm = this;

        vm.$onInit = init;

        function init() {
            let promise = [];

        }

    }

})();