/**
 * Created by amine on 06/09/2021.
 */
(function () {

    'use strict';

    class ExtApiService {
        constructor(mnWebSocket) {
            this.ws = mnWebSocket;
        }

        static get $inject() {
            return ["mnWebSocket"];
        }

        getPatient(pk) {
            return this.ws.call("patient.ExtApi.get", {pk});
        }

        getList(query) {
            return this.ws.call("patient.ExtApi.list", query);
        }

        persistPatient(item) {
            return this.ws.call("patient.ExtApi.persist", item);
        }

        persistPatients(list) {
            return this.ws.call("patient.ExtApi.persist_all", list);
        }
    }

    module.exports = ExtApiService;

})();
