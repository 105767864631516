(function () {

    'use strict';

    class NotificationDialogCtrl {
        constructor(notificationService, $mdDialog, $translate, moment, system, authService, configService) {
            this.timeFormat = system['time_format'].js;
            this.dateFormat = system['date_format'].js;

            this.moment = moment;
            this.dialog = $mdDialog;
            this.$translate = $translate;
            this.service = notificationService;
            this.authService = authService;
            this.configService = configService;

            this.query = {is_archived: {"$ne": true}, is_deleted: {"$ne": true}};
            this.intervals = this.intervals = [
                {label: "notification_next_7_days", params: [7, 'day']},
                {label: "notification_next_15_days", params: [15, 'day']},
                {label: "notification_next_month", params: [1, 'month']},
                {label: "notification_next_three_months", params: [3, 'month']},
                {label: "notification_next_six_months", params: [6, 'month']},
            ];
        }

        $onInit() {
            this.patient = this.patient ? this.patient : null;
            this.notification = this.notification ? this.notification : {
                patient: this.patient,
                time: this.moment().format(this.timeFormat),
                date: this.moment().format(this.dateFormat),
                trigger_for: this.authService.staff ? [this.authService.staff] : []
            };

            this.promise = this.configService.get("notification_field", true)
                .then(value => {
                    this.field = {
                        label: this.$translate.instant('alert_description'),
                        meta_data: {
                            dict_uid: value
                        }
                    };
                });
        }

        static get $inject() {
            return ["notificationService", "$mdDialog", "$translate", "moment", "system", "authService", "configService"];
        }

        submit() {
            this.promise = this.service.handleNotification(this.notification)
                .then(data => this.dialog.hide(data));

            return this.promise;
        }

        cancel() {
            this.dialog.cancel();
        }

        unsetPatient() {
            _.unset(this.notification, 'patient');
        }

        setInterval(item) {
            this.notification.date = this.moment().add(item.params[0], item.params[1]).format(this.dateFormat);
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        focusOnOpen: false,
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: NotificationDialogCtrl,
        template: require("../views/notification-dialog.tpl.html"),
    };


})();
