(function () {

    'use strict';

    const SpeechRecognition = require('stand-alone/text-editor/utils/speech-recognition');

    class VoiceRecognitionDialogController {
        constructor($scope, $mdDialog, $element, $mdConstant, $translate) {
            this.dialog = $mdDialog;

            this.enter = $mdConstant.KEY_CODE.ENTER;
            this.value = this.value ? this.value : '';
            this.recognition = new SpeechRecognition();
            this.$valid = $('.valid-speech', $element);
            this.$invalid = $('.invalid-speech', $element);
            this.startTalking = $translate.instant('voice_recognition_start_talking');

            $scope.$on('$destroy', () => this.onDestroy());
            $element.on('keydown', ev => this.handleKeys(ev));

            this.$valid.on('click', () => this.handleInvalidClick());
            this.$invalid.on('click', () => this.handleInvalidClick());
        }

        static get $inject() {
            return ["$scope", "$mdDialog", "$element", "$mdConstant", "$translate"];
        }

        $onInit() {
            if (_.isEmpty(this.value)) this.emptyContent();
            else this.$valid.text(this.value);

            this.startRecognition();
        }

        onDestroy() {
            this.recognition.stop();
        }

        cancel() {
            this.dialog.cancel();
        }

        startRecognition() {
            this.recognition.start();
            this.recognition.subject.subscribe(data => this.updateContent(data));
        }

        handleKeys(ev) {
            if (ev.keyCode === this.enter && !this.$valid.is(":focus")) {
                ev.preventDefault();
                ev.stopPropagation();
                ev.stopImmediatePropagation();

                this.submit();
            }
        }

        updateContent(results) {
            const final = _.get(results, "results.0.isFinal");
            const transcript = _.get(results, "results.0.0.transcript");

            if (final) {
                this.$invalid.empty();
                this.$valid.text(`${this.$valid.text()} ${transcript}`);
            }

            else this.$invalid.text(transcript);
        }

        submit() {
            const finalValue = `${this.$valid.text()} ${this.$invalid.text()}`.trim();
            this.dialog.hide(finalValue);
        }

        toggleRecognition() {
            if (this.recognition.isStopped) this.startRecognition();
            else this.onDestroy();
        }

        emptyContent() {
            this.$valid.empty();
            this.$invalid.text(this.startTalking);
        }

        handleInvalidClick() {
            if(this.$invalid.text() === this.startTalking) {
                this.$invalid.empty();
                this.$valid.text(this.startTalking);
                this.$valid.selectContentEditable();
            }

            else if (!this.$valid.is(':focus')) {
                const currentValue = `${this.$valid.text()} ${this.$invalid.text()}`;
                this.$invalid.empty();
                this.$valid.text(currentValue);
                this.$valid.focusEnd();
            }

            this.onDestroy();
        }
    }

    module.exports = {
        multiple: true,
        controllerAs: "vm",
        bindToController: true,
        parent: $(document.body),
        clickOutsideToClose: true,
        controller: VoiceRecognitionDialogController,
        template: require("shared/views/voice-recognition-dialog.tpl.html"),
    };

})();
