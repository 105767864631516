/**
 * Created by BETALOS on 23/08/2016.
 */
(function () {

    'use strict';

    module.exports = measureService;

    const downloadJs = require('downloadjs');
    const {Subject, Observable} = require('rxjs');

    measureService.$inject = ["$q", "$http", "mnWebSocket", "visitService", "configService", "system", "$mdDialog", "$mdToast", "$translate"];

    function measureService($q, $http, mnWebSocket, visitService, configService, system, $mdDialog, $mdToast, $translate) {
        let self = this;

        let timeFormat = system['time_format'].js;

        self.measurementsComponent = new Subject();

        self.showChart = showChart;

        self.getMeasures = getMeasures;
        self.handleMeasure = handleMeasure;

        self.getGXPData = getGXPData;
        self.getMeasureChart = getMeasureChart;

        self.getMeasurementResume = getMeasurementResume;
        self.getMeasuresAndMeasurement = getMeasuresAndMeasurement;
        self.handleMeasurement = handleMeasurement;
        self.handleVisitMeasurement = handleVisitMeasurement;
        self.removeMeasurement = removeMeasurement;
        self.downloadGrowthChart = downloadGrowthChart;
        self.measureUpdates = measureUpdates;
        self.sideNavMeasure = sideNavMeasure;

        function getMeasures(flag) {
            let deferred = $q.defer();
            let measure_url = "/api/measure/";

            if (!_.isUndefined(flag)) measure_url += '?flag=' + flag;

            $q.all([$http.get(measure_url), configService.get("measure_config", true)])
                .then(success, deferred.reject);

            function success(response) {
                let measures = _.isNil(response[1]) ? response[0].data : _.orderBy(response[0].data, item => _.get(response, `[1].${item.id}`));

                deferred.resolve(measures);
            }

            return deferred.promise;
        }

        function handleMeasure(measure) {
            let deferred = $q.defer();
            let measure_url = "/api/measure/";

            if (!_.isUndefined(measure.id)) measure_url += measure.id + '/';

            $http.post(measure_url, measure)
                .then(success, deferred.reject);

            function success(response) {
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getGXPData(patient) {
            return mnWebSocket.call('patient.Measurement.get_growth_xp_data', {
                patient: patient
            })
        }

        function getMeasureChart(patient, measure) {
            return mnWebSocket.call('patient.Measurement.get_measure_chart', {
                patient: patient,
                measure: measure.id
            })
        }

        function getMeasurementResume(options) {
            let query = {
                patient_id: _.parseInt(options.patient_id),
                consultation_id: options.consultation_id,
                with_measures: _.get(options, "with_measures", true),
                limit_date: _.get(options, "limit_date")
            };

            if (!_.isNil(options.visit_id)) query['visit_id'] = _.parseInt(options.visit_id);


            let deferred = $q.defer();

            $q.all([mnWebSocket.call('patient.Measurement.get_measurements', query), configService.get("measure_config", true)])
                .then(success, deferred.reject);

            function success(data) {
                data[0].measures = _.isNil(data[1]) ? data[0].measures : _.orderBy(data[0].measures, function (item) {
                    return _.get(data, `[1].${item.id}`);
                });

                deferred.resolve(data[0]);
            }

            return deferred.promise;
        }

        function handleMeasurement(measurement, patient) {
            let deferred = $q.defer();
            let measurement_url = "/api/measurement/";

            if (!_.isUndefined(measurement.id)) measurement_url += measurement.id + "/";

            measurement['measure_values'] = _.reduce(measurement['measure_values'], function (result, item, key) {
                let value = _.get(item, 'value', null);
                let date = _.get(item, 'date', null);

                if (_.isNull(date) && _.isNumber(_.parseInt(key))) _.unset(item, 'date');

                if (!_.isNull(value) && !_.has(item, 'date')) {
                    item.measure = {id: _.parseInt(key)};
                    result[key] = item;
                }

                return result;
            }, {});

            $http.post(measurement_url, measurement)
                .then(success, deferred.reject);

            function success(response) {
                mnWebSocket.pub('patient.Measurement.patient_updated', {patient: patient}, false);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function handleVisitMeasurement(visit, measurement, patient) {
            let deferred = $q.defer();
            let visit_url = "/api/visit/" + visit + "/";
            let measurementCopy = _.cloneDeep(measurement);

            let measurementDate = _.get(measurementCopy, 'date', null);

            measurementCopy['measure_values'] = _.reduce(measurement['measure_values'], function (result, item, key) {
                let value = _.get(item, 'value', null);
                //let date = _.get(item, 'date', null);

                // why the date should be nil ?? @todo to verify
                if (!_.isNil(value) /*&& _.isNil(date)*/) {
                    item.measure = {id: _.parseInt(key)};
                    result[key] = item;
                }

                return result
            }, {});

            if (_.isEmpty(measurementCopy['measure_values'])) deferred.reject();
            else if (_.isNull(measurementDate)) visitService.getVisit(visit).then(applyDate);
            else $http.put(visit_url, {measurement: measurementCopy}).then(success, deferred.reject);

            function applyDate(visitData) {
                measurementCopy['date'] = {
                    date: visitData['visit_date'],
                    time: moment().format(timeFormat)
                };
                $http.put(visit_url, {measurement: measurementCopy}).then(success, deferred.reject);
            }

            function success(response) {
                mnWebSocket.pub('patient.Measurement.patient_updated', {patient: patient}, false);
                deferred.resolve(response.data);
            }

            return deferred.promise;
        }

        function getMeasuresAndMeasurement(patient) {
            let deferred = $q.defer();
            let measure_url = "/api/measure/";
            let measurement_url = "/api/measurement/?patient=" + patient;

            $q.all([$http.get(measure_url), $http.get(measurement_url), configService.get("measure_config", true)])
                .then(success, deferred.reject)

            function success(responses) {
                deferred.resolve({
                    measures: _.isNil(responses[2]) ? responses[0].data : _.orderBy(responses[0].data, function (item) {
                        return _.get(responses, `[2].${item.id}`);
                    }),

                    measurements: responses[1].data
                });
            }

            return deferred.promise;
        }

        function removeMeasurement(measurement_id, patient) {
            let deferred = $q.defer();
            let url = "/api/measurement/" + measurement_id + "/";

            $http.delete(url)
                .then(success, deferred.reject);

            function success(response) {
                mnWebSocket.pub('patient.Measurement.patient_updated', {patient: patient}, false);
                deferred.resolve(response.data);
            }

            return deferred.promise
        }

        function directDownloadGrowthChart(patient_id) {
            const deferred = $q.defer();

            $http.get(`/api/lr/pediatric-charting/direct-download/?patient=${patient_id}`, {responseType: 'arraybuffer'})
                .then(res => {
                    downloadJs(res.data, res.headers('X-FILENAME'));

                    deferred.resolve(true)
                }, _.noop);

            const simpleToast = $mdToast.mnAdvancedToast()
                .handle(deferred.promise)
                .label('measurement_pediatric_chart_direct_download_title')
                .description('measurement_pediatric_chart_direct_download_message');


            $mdToast.show(simpleToast);

            return deferred.promise;
        }

        function defferedDownloadGrowthChart(patient_id) {
            const deferred = $q.defer();

            const simpleToast = $mdToast.simple()
                .textContent($translate['instant']('measurement_pediatric_chart_generating'))
                .position("bottom left")
                .hideDelay(120000);

            $mdToast.show(simpleToast);

            mnWebSocket.unsub("patient.Measurement.generate_pediatric_chart", `chart_${patient_id}`);
            mnWebSocket.sub("patient.Measurement.generate_pediatric_chart", `chart_${patient_id}`, data => {
                mnWebSocket.unsub("patient.Measurement.generate_pediatric_chart", `chart_${patient_id}`);

                const simpleToast = $mdToast.simple()
                    .textContent($translate['instant']('measurement_pediatric_chart_downloading'))
                    .action($translate['instant']('measurement_pediatric_chart_download'))
                    .position("bottom left")
                    .hideDelay(90000);

                $mdToast.show(simpleToast).then(res => {
                    if (res === 'ok') {
                        $http.get(`/api/lr/pediatric-charting/download/?path=${data.path}&filename=${data.filename}`, {responseType: 'arraybuffer'})
                            .then((res) => downloadJs(res.data, res.headers('X-FILENAME')), _.noop);
                    }
                });
            });

            $http.get(`/api/lr/pediatric-charting/demand/?patient=${patient_id}`, {responseType: 'arraybuffer'})
                .then(() => deferred.resolve(true), _.noop);

            return deferred.promise;
        }

        function downloadGrowthChart(patient_id, directDownload = false) {
            if (directDownload) return directDownloadGrowthChart(patient_id);
            else return defferedDownloadGrowthChart(patient_id);
        }

        function measureUpdates(activePatient) {
            return new Observable(observer => {
                mnWebSocket.sub('patient.Measurement.patient_updated', 'sideNaveMeasures', handleUpdate);

                function handleUpdate(data) {
                    if (data.patient === activePatient) sideNavMeasure(activePatient).then(data => observer.next(data));
                }

                return {
                    unsubscribe: function () {
                        mnWebSocket.unsub('patient.Measurement.patient_updated', 'sideNaveMeasures');
                        observer.complete();
                    }
                }
            });
        }

        function sideNavMeasure(patient) {
            let deferred = $q.defer();
            let calculateFormula = require('shared/utils/calculate-formula');

            $q.all([getMeasurementResume({patient_id: patient}), configService.get("patient_measure_config")])
                .then(success, deferred.reject);

            function success(data) {
                let measures = _.chain(data)
                    .get('[0].measures')
                    .filter(function (item) {
                        return _.get(data, _.format('[1]["{0}"]', item.id), false);
                    })
                    .value();

                let resolve = _.reduce(measures, function (div, measure) {
                    let $measureItem = $("<div class='measure-item' />");
                    let value = _.get(data, _.format('[0].measurements[{0}].value', measure.id), null);
                    let unit = _.get(measure, 'unit.value', "");

                    let $measureTitle = $('<span class="measure" />').text(measure.name);
                    let $measureValue = $('<span class="measurement-value" />');

                    if (_.isNull(value) && measure.type !== "calculated") return div;

                    if (measure.type === "float") {
                        $measureItem.append($measureTitle);
                        $measureItem.append($measureValue.text(_.format('{0} {1}', value.toFixed(2), unit)));
                    }

                    if (measure.type === "boolean" && !_.isNull(value)) {
                        $measureItem.append($measureTitle);
                        $measureItem.append($measureValue.append($('<span class="boolean-value" />').addClass(value ? 'green' : 'red')));
                    }

                    if (measure.type === "calculated") {
                        let calc = parseFloat(calculateFormula(measure['formula'], data[0].measurements, data[0].measures));
                        if (calc) {
                            $measureItem.append($measureTitle);
                            $measureItem.append($measureValue.text(_.format('{0} {1}', calc.toFixed(2), unit)));
                        }
                    }

                    if (_.includes(["integer", "string", 'date'], measure.type)) {
                        $measureItem.append($measureTitle);
                        $measureItem.append($measureValue.text(_.format('{0} {1}', value, unit)));
                    }

                    if (!$measureItem.is(':empty')) div.append($measureItem);

                    return div;

                }, $('<div />')).html();

                deferred.resolve(resolve);
            }

            return deferred.promise
        }

        function showChart(patient, $event) {
            $mdDialog.show(_.assign(require('../dialogs/measurement-chart-dialog'), {
                targetEvent: $event,
                locals: {
                    patient: patient
                }
            }))
        }

    }
})();
