/**
 * Created by amine on 27/07/2016.
 */

(function () {
    'use strict';

    const getObjectId = require('shared/utils/get-object-id');

    class MedicalPlanPaymentDialogCtrl {
        constructor($mdDialog, $q, $translate, medicalCareService, paymentService, moment) {
            this.dialog = $mdDialog;
            this.$q = $q;
            this.$translate = $translate;
            this.medicalCareService = medicalCareService;
            this.paymentService = paymentService;
            this.moment = moment;

            this.rawPlans = [];
            this.plans = {
                unpaid: {
                    items: [],
                    label: "unpaid_visit_label"
                },
                paid: {
                    items: [],
                    label: "paid_visit_label"
                },
                exempt: {
                    items: [],
                    label: "exempt_visit_label"
                }
            };
            this.payments = [];
            this.selectedPlan = null;
            this.showProcedures = false;
            this.newPayment = false;
            this.showForm = false;

            this.transactionUID = getObjectId();
        }

        static get $inject() {
            return ["$mdDialog", "$q", "$translate", "medicalCareService", "paymentService", "moment"];
        }

        $onInit() {
            this.planId = this.planId || null;
            this.patientId = this.patientId || null;

            if (this.patientId) this.loadPlans();
            else {
                this.medicalCareService.get(this.planId.id ? this.planId.id : this.planId, "plan")
                    .then(data => {
                        this.patientId = _.get(data, "patient_id");
                        this.loadPlans()
                            .then(() => {
                                this.selectedPlan = data;
                                this.refreshPlan();
                            });
                    })
            }
        }

        loadPlans() {
            return this.medicalCareService.getByPatient(this.patientId, 'plan')
                .then(data => {
                    this.rawPlans = data;
                    this.plans.unpaid.items = _.filter(this.rawPlans, plan => {
                        const financial_status = plan.financial_status;
                        return financial_status.has_remaining_amount;
                    });
                    this.plans.paid.items = _.filter(this.rawPlans, plan => {
                        const financial_status = plan.financial_status;
                        return financial_status.is_paid
                    });
                    this.plans.exempt.items = _.filter(this.rawPlans, plan => {
                        const financial_status = plan.financial_status;
                        return financial_status.is_exempt
                    });
                });
        }

        planSelected() {
            return this.$q.all([this.medicalCareService.getCures(this.selectedPlan.id), this.paymentService.medicalPlanPayments(this.selectedPlan)])
                .then(data => {
                    this.selectedPlan = _.assign(this.selectedPlan, {cures: data[0]});
                    this.payments = data[1];
                });
        }

        addPayment() {
            this.showForm = true;

            const gross_remaining_amount = this.selectedPlan.financial_status.remaining_amount; //+ this.visit.financial_status.global_discount;
            let payee = {
                _module: "patient.models",
                _model: "Patient",
                id: this.patientId
            };

            this.payment = {
                payer: payee,
                payer_type: "P",
                payee: payee,
                payee_type: "P",
                payment_date: this.moment(),
                total: gross_remaining_amount,
                amount: this.selectedPlan.financial_status.remaining_amount,
                payment_mode: {
                    due_date: this.moment()
                }
            };
        }

        payerChange() {
            const organization = _.get(this.selectedPlan, "insurance.organization");
            const patient = {
                _module: "patient.models",
                _model: "Patient",
                id: this.patientId
            };
            switch (this.payment.payer_type) {
                case "P":
                    this.payment = _.assign(this.payment, {payer: patient});
                    break;
                case "T":
                    this.payment = _.assign(this.payment, {
                        payer: patient,
                        organization_affectation: {
                            organization: organization,
                            affected_percentage: _.get(organization, "default_refund_amount", 0)
                        }
                    });
                    this.applyAffectation('percentage', this.selectedPlan, this.payment.organization_affectation);
                    break;
                case "O":
                    this.payment = _.assign(this.payment, {payer: this.paymentService.getCls("unregistered")});
                    break;
            }
        }

        applyAffectation(type, plan, affectation) {
            if (type === "value") {
                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));

                if (affected_amount > 0) {
                    this.payment.amount = parseFloat(_.get(plan, "financial_status.total", 0)) - affected_amount;

                    this.paymentService.applyAffectation(type, plan, affectation)
                }
            } else {
                const remaining_amount = parseFloat(_.get(plan, "financial_status.remaining_amount", 0));
                this.paymentService.applyAffectation(type, plan, affectation, remaining_amount);

                const affected_amount = parseFloat(_.get(affectation, "affected_amount", 0));
                if (affected_amount > 0) {
                    this.payment.amount = this.payment.total - affected_amount;
                }
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        submit(quit = false) {
            // const delivery_document = this.selectedPlan.delivery_document;
            this.selectedPlan.financial_status = _.assign(this.selectedPlan.financial_status, {
                paid_amount: this.selectedPlan.financial_status.paid_amount + this.payment.amount,
                is_validated: true,
                validate_at: this.moment(),
                organization_affectation: this.payment.payer_type === 'T' ? _.get(this.payment, "organization_affectation") : null
            });

            let payment = {
                total_amount: this.payment.amount,
                payment_date: this.payment.payment_date,
                transaction_uid: this.transactionUID,
                details: [{
                    total_amount: this.payment.amount,
                    medical_plan: this.selectedPlan,
                    _cls: this.paymentService.getCls('medical_plan')
                }],
                encasement: {
                    encasement_date: this.payment.payment_date,
                    total_amount: this.payment.amount,
                    payer: this.payment.payer,
                    payee: this.payment.payee,
                    payment_mode: this.payment.payment_mode,
                    physician: this.payment.physician,
                    comment: this.payment.comment,
                    transaction_uid: this.transactionUID
                }
            };

            return this.paymentService
                .addPayment(payment)
                .then(() => {
                    this.showForm = false;

                    if (quit) this.dialog.hide();
                    else this.refreshPlan();
                });

        }

        refreshPlan(data = null) {
            const newUID = _.mnDelay(() => {
                this.transactionUID = getObjectId();
            }, 500)

            this.planSelected()
                .then(() => {
                    newUID();
                    if (data) this.selectedPlan = data;
                    this.showForm = false;
                    this.payment = null;
                });
        }

        closePlan($event) {
            return this.paymentService.closeMedicalPlan($event, this.selectedPlan, true)
                .then(data => {
                    this.refreshPlan(data);
                    this.loadPlans();
                });
        }

        reopenPlan($event) {
            return this.paymentService.reopenMedicalPlan($event, this.selectedPlan, true)
                .then(data => {
                    this.refreshPlan(data);
                    this.loadPlans();
                });
        }

        resetPayments(ev) {
            const deferred = this.$q.defer();
            const confirm = this.dialog.confirm()
                .multiple(true)
                .title(this.$translate.instant("reset_dental_plan_confirm_title"))
                .htmlContent(this.$translate.instant("reset_medical_plan_confirm_text"))
                .ariaLabel('remove plan confirm')
                .targetEvent(ev)
                .ok(this.$translate.instant('confirm_ok'))
                .cancel(this.$translate.instant('confirm_cancel'));

            this.dialog.show(confirm).then(() => {
                this.paymentService
                    .resetMedicalPlanPayments(this.selectedPlan)
                    .then(() => {
                        this.refreshPlan();
                        this.loadPlans();
                    });
            }, deferred.reject);


            return deferred.promise;
        }

        maxAmount() {
            const fixedTotal = parseFloat(this.selectedPlan.financial_status.total.toFixed(2));
            if (this.payment.payer_type === 'T') {
                return fixedTotal - _.get(this.payment, "organization_affectation.affected_amount", 0);
            } else {
                return fixedTotal;
            }
        }

        openSchedulingDialog(event) {
            this.medicalCareService.openSchedulingDialog(this.selectedPlan.patient_id, this.selectedPlan, true, event);
        }
    }

    module.exports = {
        controller: MedicalPlanPaymentDialogCtrl,
        template: require("payment/views/medical-plan-payment-dialog.tpl.html"),
        controllerAs: "vm",
        bindToController: true,
        multiple: true,
        parent: $(document.body),
        clickOutsideToClose: true,
    };
})();
