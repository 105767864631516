/**
 * Created by BETALOS on ?. / edited by amine on 24/07/2020
 */
(function () {
    'use strict';

    class PeriodicSetterCtrl {
        constructor(system, moment, $scope, $attrs) {
            this.dateFormat = system['date_format'].js;
            this.moment = moment;
            this.$scope = $scope;
            this.attrs = $attrs;

            this.buttons = {
                YESTERDAY: "yesterday",
                TODAY: "today",
                TOMORROW: "tomorrow",
                LAST_WEEK: "last_week",
                WEEK: "this_week",
                NEXT_WEEK: "next_week",
                LAST_THREE_MONTH: "last_three_month",
                LAST_MONTH: "last_month",
                MONTH: "this_month",
                NEXT_MONTH: "next_month",
                NEXT_THREE_MONTH: "next_three_month"
            }
            this.valueChanged = this.valueChanged || _.noop;
        }

        static get $inject() {
            return ['system', 'moment', '$scope', '$attrs'];
        }

        $onInit() {
            this.config = this.config || {
                YESTERDAY: false,
                TODAY: true,
                TOMORROW: true,
                LAST_WEEK: false,
                WEEK: true,
                NEXT_WEEK: true,
                LAST_THREE_MONTH: false,
                LAST_MONTH: false,
                MONTH: true,
                NEXT_MONTH: true,
                NEXT_THREE_MONTH: true,
                defaultValue: 'TODAY'
            }

            let defaultValue = _.get(this.config, 'defaultValue', 'TODAY')
            if (this.localStorage) defaultValue = localStorage.getItem(this.localStorage) || defaultValue;

            this.setInterval(defaultValue);
        }

        setInterval(interval) {
            this.interval = interval;
            if (_.has(this.attrs, 'buttons')) this.intervalChanged();
        }

        intervalChanged() {
            if (this.localStorage) localStorage.setItem(this.localStorage, this.interval);

            switch (this.interval) {
                case "YESTERDAY":
                    this.yesterday();
                    break;
                case "TODAY":
                    this.today();
                    break;
                case "TOMORROW":
                    this.tomorrow();
                    break;
                case "LAST_WEEK":
                    this.lastWeek();
                    break;
                case "WEEK":
                    this.week();
                    break;
                case "NEXT_WEEK":
                    this.nextWeek();
                    break;
                case "LAST_THREE_MONTH":
                    this.lastThreeMonth();
                    break;
                case "LAST_MONTH":
                    this.lastMonth();
                    break;
                case "MONTH":
                    this.month();
                    break;
                case "NEXT_MONTH":
                    this.nextMonth();
                    break;
                case "NEXT_THREE_MONTH":
                    this.nextThreeMonth();
                    break;
                default:
                    this.clear();
            }

            this.$scope.$applyAsync(() => this.valueChanged({start: this.start, end: this.end}));
        }

        clear() {
            this.start = this.end = null;
        }

        yesterday() {
            this.start = this.end = this.moment().subtract(1, 'day').format(this.dateFormat);
        }

        today() {
            this.start = this.end = this.moment().format(this.dateFormat);
        }

        tomorrow() {
            this.start = this.end = this.moment().add(1, 'day').format(this.dateFormat);
        }

        lastWeek() {
            this.start = this.moment().subtract(1, 'weeks').startOf("week").format(this.dateFormat);
            this.end = this.moment().subtract(1, 'weeks').endOf("week").format(this.dateFormat);
        }

        week() {
            this.start = this.moment().startOf("week").format(this.dateFormat);
            this.end = this.moment().endOf("week").format(this.dateFormat);
        }

        nextWeek() {
            this.start = this.moment().add(1, 'weeks').startOf("week").format(this.dateFormat);
            this.end = this.moment().add(1, 'weeks').endOf("week").format(this.dateFormat);
        }

        lastThreeMonth() {
            this.start = this.moment().subtract(0, 'month').startOf("month").format(this.dateFormat);
            this.end = this.moment().subtract(2, 'month').endOf("month").format(this.dateFormat);
        }

        lastMonth() {
            this.start = this.moment().subtract(1, 'month').startOf("month").format(this.dateFormat);
            this.end = this.moment().subtract(1, 'month').endOf("month").format(this.dateFormat);
        }

        month() {
            this.start = this.moment().startOf("month").format(this.dateFormat);
            this.end = this.moment().endOf("month").format(this.dateFormat);
        }

        nextMonth() {
            this.start = this.moment().add(1, 'month').startOf("month").format(this.dateFormat);
            this.end = this.moment().add(1, 'month').endOf("month").format(this.dateFormat);
        }

        nextThreeMonth() {
            this.start = this.moment().add(1, 'month').startOf("month").format(this.dateFormat);
            this.end = this.moment().add(3, 'month').endOf("month").format(this.dateFormat);
        }
    }

    tpl.$inject = ["$attrs"];

    function tpl($attrs) {
        if (_.has($attrs, 'buttons')) {
            return `<div class="mn-button-group mn-primary flex-nogrow layout-row">
                <md-button ng-repeat="(key, value) in vm.buttons track by key" ng-if="vm.config[key]" 
                        class="md-primary md-raised" ng-click="vm.setInterval(key)" aria-label="filter" 
                        ng-class="{active: vm.interval === key}">
                    <span translate-once="{{value}}"></span>
                </md-button>
                <md-button class="md-raised mn-button-icon-only" ng-click="vm.setInterval(null)" aria-label="clear" 
                        ng-if="vm.interval">
                    <md-icon md-font-icon="mdi-close" md-font-set="mdi"></md-icon>
                </md-button>
        </div>`
        } else {
            return `<div class="layout-column flex mn-radio-group-container">
           <label translate-once="search_interval" for=""></label>
           <md-radio-group ng-model="vm.interval" ng-change="vm.intervalChanged()" class="layout-row">
               <md-radio-button ng-repeat="(key, value) in vm.buttons track by key" 
                    value="{{key}}" aria-label="{{value}}" ng-if="vm.config[key]">
                   <span translate-once="{{value}}"></span>
               </md-radio-button>
           </md-radio-group>
        </div>`
        }


    }

    module.exports = {
        bindings: {
            start: "=",
            end: "=",
            config: "=?",
            valueChanged: "&?",
            localStorage: "@?"
        },
        template: tpl,
        controllerAs: "vm",
        controller: PeriodicSetterCtrl,
    };

})();
