(function () {

    'use strict';

    class TableSideNavCtrl {
        constructor($scope, tableService ) {
            this.tableService = tableService;
        }

        static get $inject() {
            return ["$scope", "tableService"   ];
        }

        $onInit() {
            this.promise = null;
        }


        formatTab(cond) {

        }
    }

    module.exports = {
        bindings: {
            columns: '=?',
            query: '=?',
            mnFilters: '=?',
            mnModel: '=?',
            reloadHandler: '=?',
            styleRules: '=?',
            draftRule: '=?'

        },
        template: require("shared/views/table-sidenav.html"), // or template
        controllerAs: "vm",
        controller: TableSideNavCtrl,
    };

})();