/**
 * Created by amine on 05/09/2019.
 */
(function () {
    'use strict';

    const getObjectId = require('shared/utils/get-object-id');

    class EntryPrePaymentDialogCtrl {
        constructor($mdDialog, $q, $translate, paymentService, visitService, frontDeskService, moment, system) {
            this.dialog = $mdDialog;
            this.$q = $q;
            this.$translate = $translate;
            this.paymentService = paymentService;
            this.frontDeskService = frontDeskService;
            this.visitService = visitService;
            this.moment = moment;
            this.dateFormat = system['date_format'].js;

            this.encasement = null;
        }

        static get $inject() {
            return ["$mdDialog", "$q", "$translate", "paymentService", "visitService", "frontDeskService", "moment", "system"];
        }

        $onInit() {
            this.entry = this.entry || null;
            
            if (this.entry.has_encasement) {
                this.paymentService.getEncasement(this.entry.encasement)
                    .then(encasement => {
                        this.encasement = encasement;
                    });
            } else {
                let payee = {
                    _module: "patient.models",
                    _model: "Patient",
                    id: this.entry.patient.id
                };

                this.encasement = {
                    payer: payee,
                    payer_type: "P",
                    payee: payee,
                    payee_type: "P",
                    encasement_date: this.moment().format(this.dateFormat),
                    total_amount: 0,
                    pre_encasement: true,
                    physician: _.get(this.entry, 'physician'),

                    payment_mode: {
                        due_date: this.moment().format(this.dateFormat)
                    }
                };
            }
        }

        cancel() {
            this.dialog.cancel();
        }

        payerChange() {
            let patient = {
                _module: "patient.models",
                _model: "Patient",
                id: this.entry.patient.id
            };
            switch (this.encasement.payer_type) {
                case "P":
                    this.encasement = _.assign(this.encasement, {payer: patient});
                    break;
                case "O":
                    this.encasement = _.assign(this.encasement, {payer: this.paymentService.getCls("unregistered")});
                    break;
            }
        }

        submit() {
            this.paymentService.saveEncasement(_.assign(this.encasement, {transaction_uid: getObjectId()}))
                .then(data => {
                    this.frontDeskService.partialUpdateEntry({id: this.entry.id, encasement: _.get(data, "id")});
                    this.dialog.hide(data);
                })

        }
    }

    module.exports = {
        controller: EntryPrePaymentDialogCtrl,
        template: require("payment/views/entry-pre-payment.tpl.html"),
        controllerAs: "vm",
        bindToController: true,
        multiple: true,
        parent: $(document.body),
        clickOutsideToClose: true,
    };
})();
