/**
 * Created by amine on 23/06/2017.
 */

(function () {
    'use strict';

    const biologyTree = {
        controller: biologyTreeController,
        controllerAs: "vm",
        bindings: {
            subscriber: "<",
            selectedValues: "<"
        },
        template: require('shared/views/biology-tree.tpl.html')
    };

    biologyTreeController.$inject = ["biologyService", "prescriptionService", "$element"];

    function biologyTreeController(biologyService, prescriptionService, $element) {
        const vm = this;
        let biologyExams = [];

        vm.tree = [];
        vm.key = "";

        vm.$onInit = init;
        vm.selectExam = selectExam;
        vm.selectDetail = selectDetail;
        vm.filterTree = filterTree;

        vm.isSelected = isSelected;

        function init() {
            $element
                .addClass("layout-column")
                .addClass("flex");

            vm.subscriber = vm.subscriber || prescriptionService.useBiologyModel;

            let subscription = biologyService.examSubject.subscribe(handleExamUpdate);

            biologyService.getBiologyExams()
                .then(success)

            function success(data) {
                biologyExams = data;
                vm.tree = _.cloneDeep(biologyExams);
            }

            function handleExamUpdate(exam) {
                biologyExams = _.pushOrUpdate(biologyExams, exam);
                filterTree();
            }

            vm.$onDestroy = destroy;

            function destroy() {
                subscription.unsubscribe();
            }
        }

        function selectExam(exam) {
            vm.subscriber.next({
                exam: exam,
                value: exam['is_unique'] ? null : _.reduce(exam.details, reduceDetail, {})
            })

            function reduceDetail(result, detail) {
                result[detail.uid] = null;

                return result;
            }
        }

        function selectDetail(exam, detail) {
            let value = {};
            value[detail.uid] = null;

            vm.subscriber.next({
                exam: exam,
                value: value
            });
        }

        function isSelected(exam) {
            if (!vm.selectedValues) return false;
            else return !!_.find(vm.selectedValues, ['id', exam]);
        }

        function filterTree() {
            let key = "";

            if (_.size(vm.key) < 3) {
                vm.tree = biologyExams;
            } else {
                key = _.chain(vm.key).deburr().toLower().value()
                vm.tree = _.reduce(_.cloneDeep(biologyExams), searchTree, []);
            }

            function searchTree(result, exam) {
                if (_.chain(exam.title).deburr().toLower().includes(key).value()) return _.concat(result, exam);

                if (!exam['is_unique']) {
                    exam.details = _.filter(exam.details, filterDetail);
                    return _.isEmpty(exam.details) ? result : _.concat(result, exam);
                }

                return result
            }

            function filterDetail(detail) {
                return _.chain(detail.title).deburr().toLower().includes(key).value()
            }
        }

    }


    module.exports = biologyTree;

})();
